import moment from "moment";

const commonLib = {
    getQueryParameters: function (urlQuery, name) {
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    getFormattedTime: function (seminarTime) {
        return moment(seminarTime, "HH:mm:SS").format("h:mm A");
    },
    getFormattedDate: function (seminarDate) {
        return moment(seminarDate).format('MMMM Do YYYY')
    },
    getVenueObj: function (json) {
        let params = {};
        params['venueAddressId'] = json.venueAddressId;
        params['venueAddressLine1'] = json.venueAddressLine1;
        params['venueAddressLine2'] = json.venueAddressLine2;
        params['venueAddressLine3'] = json.venueAddressLine3;
        params['venueCity'] = json.venueCity;
        params['venueCountry'] = json.venueCountry;
        params['venueCounty'] = json.venueCounty;
        params['venueId'] = json.venueId;
        params['venueState'] = json.venueState;
        params['venueText'] = json.venueText;
        params['venueZipCode'] = json.venueZipCode;
        
        return params
    },
    getSeminarObj: function (json) {
        let params = {};
        params['seminarDate'] = json.seminarDate;
        params['seminarTime'] = json.seminarTime;
        params['seminarId'] = json.seminarId;
        params['subtenantId'] = json.subtenantId;
        params['seminarTypeId'] = json.seminarTypeId;
        params['seminarTopic'] = json.seminarTopic;
        params['seminarFee'] = json.seminarFee;
        params['isSeminarPaid'] = json.isSeminarPaid;
        return params;
    },

    setDatatoSession: function(key, value){
        sessionStorage.setItem(key , value)

    },
    GetQueryValues: (query,param)=> {
        var url = "";
        url = query.slice(query.indexOf('?') + 1).split('&');
        // console.log(url);
        for (var i = 0; i < url.length; i++) {
          var urlparam = url[i].split('=');
          if (urlparam[0] == param) {
            return urlparam[1].split("#")[0];
          }
        }
        return "";
      },
       formatPhoneNumber: (str) => {
        //Filter only numbers from the input
        let cleaned = ("" + str).replace(/\D/g, "");
    
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
    
        return null;
      },
      unAuthurizeAccess : () => {
        window.location.replace("https://Unilogin.agingoptions.com/Account/UnauthorizedAccess")
      }
,
      mandatory:(str)=>{
        return `${str}*`
      }
}


export default commonLib;