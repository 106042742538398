import React,{useEffect,useState,useContext} from "react";
import LayoutComponent from "./LayoutComponent";
import commonLib from "./control/commonLib";
import { useHistory } from "react-router-dom";
import Classroomregistration from "./ClassroomComponent/Classroomregistration";
import { CounterCheckIn, CounterWalkIn } from './control/Constant';
import Notification from "./Notification";
// import { CounterCheckIn, CounterWalkIn } from '../control/Constant';
// import Services from '../network/Services';
import Services from './network/Services';
import occurrenceId from "./control/occurrence";
import config from '../config.json';
import { UserContext } from "../App";
import konsole from "./network/konsole";

export default function ConfirmBooking(props) {
  const [clientIpAddress, setClientIpAddress] = useState('');
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const OrderId = props.location.search;
  const paymentSuccesStatus = commonLib.getQueryParameters( OrderId, "TrxnStatus");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const classroomObj = commonLib.getObjFromStorage("classroomStaus");
  const classroomObj1 = commonLib.getObjFromStorage("calssroom");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const classObj = commonLib.getObjFromStorage("GetClassroom");
  const selectedSlot = commonLib.getObjFromStorage("timeSlot");
  const seminarObj1 = commonLib.getObjFromStorage("readcalssroom");
  const classbook = commonLib.getObjFromStorage("readmoreBook");
  const classSimple = commonLib.getObjFromStorage("simpleCalssroom");
  const classBookStatus = commonLib.getObjFromStorage("classBookData");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const bookingId = commonLib.getObjFromStorage("classbookingId");
  const classOrderId = commonLib.getObjFromStorage("classOrderId");
  const subtenantId = commonLib.getObjFromStorage("subtenantId");


  const [showModal , setShowModal] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [seminarAttendeId, setseminarAttendeId] = useState()
  
  const slotIsSelected = props.location.state
  //console.log("sts",OrderId  )


  useEffect(() => {
    getIpAddress()
    
  },[])
  // const GetEmailTemplateUsers = (TempalteId,isActive)=>{
  //   // ////alert("mail")
  //   const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
  //   ////console.log("restR111", TempalteId,isActive);
  //   promises.then(res => {
  //     let data = res.data.data[0]?.templateContent
  //     ////console.log("restR22", data);
      
  //     const replacedStr = data.replace('@@ATTENDEENAME', '').replace('World', 'React');
  //     // const replacedStr = data.replace('@@ATTENDEENAME', 'SANJAY');
  //     ////console.log("newData",replacedStr); 


  //   })
  //   .catch(err => {
  //     ////console.log("err", err.response);
      
  //   }).finally(fin => {
  //     // konsole.log("finish");
  //     // setLoader(false);
  //   })

  // }

  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      ////console.log("samip",res)
      setClientIpAddress(res.IPv4);
    })
    // getUserRoles(stateObj?.userId,seminarObj?.subtenantId)
  }
  // const getUserRoles = (userId,subtenantId)=>{
   
  //   const promises = Services.GetUserRolebByID(userId,subtenantId);
  //   promises.then(res => {
     
      
      

  //      let len = res.data.data.length;
  //      let data = res.data.data;
  //      ////console.log('registered Checkin res11', data);
  //      let newData = []
  //      let secondData = []
  //      for(let i = 0 ; i <=len-1;i++){
        
  //       newData.push(data[i].roleId)
  //      }
      
  //      const found = newData.find(element => element == 1 || element == 9 || element == 10 );
     

  //         if(found === 1 || 9 || 10){

  //          ////console.log("tom$",data[0]?.isActive , data[0]?.auth0Id)
  //         if(data[0]?.isActive === true  && data[0]?.auth0Id != null){
  //           // ////alert("yes")
  //           GetEmailTemplateUsers(13,data[0]?.isActive )

  //         }
  //        }
        
  //        else{
  //         ////alert("no")
  //        }

      

        
        

  //       // newRole.push(ele?.roleId)
         


       
          
  //     //  })
       
     
  //   })
  //     .catch(err => {
  //       ////console.log("err", err.response);
  //       // Toaster.error(err.response.data.messages[0])
  //     }).finally(fin => {
  //       // konsole.log("finish");
  //       // setLoader(false);
  //     })

  // }
  const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
     ////alert("hhh")
      const promises = Services.getTextTemplateFunc(TempalteId,isActive);
  
      console.log("restR11111", TempalteId,isActive);
      promises.then(res => {
          console.log("ihkjgiu",TempalteId)
        let data = res.data.data[0]?.textTemplateContent
        let data2 = res.data.data[0]
        console.log("restapi11eee12",data)
        let createdBy =  stateObj?.userId;
        ////alert("7777")
         const replacedStr1 = data.
        replace('@@SEMINARNAME',seminarObj?.seminarTopic).
        replace('@@ATTENDEENAME',classroomObj?.seminar?.attendeeFullName).
        replace('@@CLASSROOMNAME',classroomObj?.selectedUserObj?.seminarTopic).
        replace('@@CLASSROOMDATE',newDateFormate()).
        replace('@@CLASSROOM TIMINGS',newTimeFormate());
        
        let promise = Services.postSendText(data2?.textTemplateType,classroomObj?.seminar?.attendeeMobileNo
          , replacedStr1, data2?.textTemplateId, 1, "tblSeminarAttendeeList", classroomObj?.seminar?.seminarAttendeeId, createdBy)
            

        konsole.log("sendTextAPIFuncRes", data2?.textTemplateType,classroomObj?.seminar?.attendeeMobileNo
        , replacedStr1, data2?.textTemplateId, 1, "tblSeminarAttendeeList", classroomObj?.seminar?.seminarAttendeeId, createdBy)
          
       
        promise.then((response)=>{
            console.log("props?.textData?.textTemplateContent",response)
    }).catch((error)=>{
        konsole.log("sendTextAPIFuncError", error)
    }).finally(()=>{
        konsole.log("sendTextAPIFuncEnd")
    })
        
  
      
  
      // setpreviewTemplateData(replacedStr1)
      
      
        console.log("newData",replacedStr1);
        
      })
    
      .catch(err => {
        console.log("err", err.response);
        
      }).finally(fin => {
        // konsole.log("finish");
        // setLoader(false);
      })
  
    }

const tryAgain = () => {
    if(classroomObj1 === true ){
      // if(classbook === true || classroomObj === false ){
        history.push({
          pathname: "/classroomvenupayment",
            // state: classBookStatus
            state: classObj
         
        });
// }
    //   else{
    //      history.push({
    //     pathname: "/classroomvenupayment",
    //       state: classObj,
       
    //   });
    // }
  }
      else{
        history.push({
          pathname: "/venuePayment",
          state: attendeeObj,
        });
      
    }
   
  };
  const handelSend =(occurrenceId, isActive, subtenantId,unique)=>{
    setLoader(true
      )
      const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
      console.log("rest11", occurrenceId, isActive, subtenantId)
      promise.then((res) => {
          setLoader(false)
          let textId = res.data.data[0]?.applicableTextTempId;

          console.log("sssssss",res.data.data)
          let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
          if(applicableEmailTempId !== null && applicableEmailTempId !== undefined && applicableEmailTempId !== ""){
              GetEmailTemplateUsers(applicableEmailTempId,true,unique)
          }
          
      if(textId !== null && textId !== undefined && textId !== ""){
        //  ////alert("hy1")
          
              GetTextTemplateUsers1(textId,true,unique)
      }
    
        
       
    
    
    }).catch((err) => {
    console.log(`errorw `, err.response.data.messages[0])
   
 
    if(err.response.status == 404){
      
      let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
      
      handelSend(occurrenceId, isActive, subtenantId1,unique) 
      
  }
  }).finally(error => {
    console.log("finish");
  //   setLoader(false);
  })

  }
    const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
        // setLoader(true)
        // ////alert("2")
       const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
        //console.log("sample21",emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
       promises.then(res => {
        //  setLoader(false)
        console.log("createdBy11",res.data.data)
       //  //console.log("temp1",templeteId)
       
    
         
    
    
       })
       .catch(err => {
         //console.log("err", err.response);
         
       }).finally(fin => {
         //console.log("finish");
        //  setLoader(false);
       })
     }
     const data1 = (seminar11, toDate) => {
      // //console.log("sssssssss",seminar11)
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var d = new Date(seminar11);
      // //console.log("sameee",d)
      var dayName = days[d.getDay()];
      // //console.log("dayss",dayName)
      var d2 = new Date(toDate);
      var dayName2 = days[d2.getDay()];
      return "(" + dayName + "-" + dayName2 + ")"
  }

  const newDateFormate = ()=>{

      const toDate = classroomObj?.selectedUserObj?.remarks.slice(0, 11);
      
      const weekDay = classroomObj?.selectedUserObj?.remarks.slice(20, 40)
      //console.log("weekDay",weekDay)
      return `${commonLib.getFormattedDate(classroomObj?.selectedUserObj?.seminarDate)} To  ${commonLib.getFormattedDate(toDate)} 
 ${data1(classroomObj?.selectedUserObj?.seminarDate, toDate)}
     
      ` 

  }
  const newTimeFormate = ()=>{
    const toTime = classroomObj?.selectedUserObj?.remarks.slice(11, 31);
    return `${commonLib.getFormattedTime(classroomObj?.selectedUserObj?.seminarTime)} To ${commonLib.getFormattedTime(toTime)}`
  }
  const GetEmailTemplateUsers = (TempalteId,isActive,)=>{
    
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);

    //console.log("restR11111", TempalteId,isActive);
    promises.then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
      //console.log("restapi122",newDateFormate(),newTimeFormate())
      
    
      // ////alert("hy")
       const replacedStr1 = data.
      replace('@@ATTENDEENAME',classroomObj?.seminar?.attendeeFullName).
      replace('@@CLASSROOMNAME',classroomObj?.selectedUserObj?.seminarTopic).
      replace('@@CLASSROOMDATE',newDateFormate()).
      replace('@@CLASSROOM TIMINGS',newTimeFormate());
      

      
      
      //console.log("newData",replacedStr1);
     let emailType = data2?.templateType;
     let emailTo = classroomObj?.seminar?.attendeeEmailAddress;
     let emailSubject = data2?.emailSubject;
     let emailTemplateId =  data2?.templateId;
     let emailContent = replacedStr1;
     let emailStatusId =  1;
     let emailMappingTable = "tblSeminarAttendeeList";
     let emailMappingTablePKId = classroomObj?.seminar?.seminarAttendeeId;
     let createdBy =  stateObj?.userId;
     //console.log("sssssss",emailMappingTablePKId)
     SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)


      
      
    })
  
    .catch(err => {
      //console.log("err", err.response);
      
    }).finally(fin => {
      // konsole.log("finish");
      // setLoader(false);
    })

  }
  const classbookingNew = (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)=>{
    // ////alert("book")
    const promise = Services. putClassBooking (bookingId,isActive,updatedBy,remarks,classroomAttendeeId)
    //console.log("rrgisfggg",bookingId,isActive,updatedBy,remarks,classroomAttendeeId)
      promise.then((res) => {
        //console.log("restigff",res)
   
   
  })
      .catch(err => {
        //console.log("errfdddd", err);
        // setShowModal(true)
        // setWarning(err.response.data.messages[0])
        //     setColor1("#FA113D")
        // ////alert(err.response.data.messages[0])
        
        
      }).finally(fin => {
        // konsole.log("finish", fin);
        // setLoader(false);
      })

  }
 const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    // setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    //console.log("dummy11",seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy)
    promises.then(res => {
      // setLoader(false);
      //console.log('putresdfdf', res);
 })
      .catch(err => {
       
        //console.log("errt66", err);
        ////alert(err.response.data.messages[0])
        setWarning(err.response.data.messages[0])
            setColor1("#FA113D")
            setShowModal(true)

        
      }).finally(fin => {
        // setLoader(false);
        // konsole.log("finish");
      })
  }
const userActivateIP = (seminarAttendeeId)=>{
  // ////alert("yes")
  // setLoader(true);
     const promises = Services.putUserActivate1(seminarAttendeeId);
      //  konsole.log("resp111",seminarAttendeeId)
      promises.then(res => {
          // konsole.log("putapi2222",res)
          // konsole.log("saddasdsadasdasd",res)
          // let responseData = res.data.data.attendees[0]
         
 

          }).catch(err => {
          // ////alert(err.response.data.messages[0])
          // konsole.log("errsw111", err.response);
          // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
          // setLoader(false);
          // konsole.log("finish");
      })
}
  const registerNewUser = (subtenantId1, seminarId, userRegistration,selectedUserObj) => {
    ////console.log("rest",subtenantId1, seminarId, userRegistration,selectedUserObj)
    // setLoader(true);
    const promise = Services.registerNewUser(subtenantId1, seminarId, userRegistration)
    // ////console.log("rrgis",subtenantId1, seminarId, userRegistration)
      promise.then((res) => {
        //console.log("resti",res)
        setseminarAttendeId(res.data.data?.seminarAttendeeId)
        classbookingNew(bookingId,true,stateObj?.userId,"classBooking",res.data.data?.seminarAttendeeId)
        // GetEmailTemplateUsers(224,true)
        handelSend(18, true, subtenantId)
        // handelSend(18, true, subtenantId)
        userActivateIP(res.data.data?.seminarAttendeeId)
         
                           handleWalkInCheckInUser(classroomObj.seminar?.seminarid,
                           classroomObj?.seminar?.seminarAttendeeId,
                           classroomObj?.seminar?.seminarAttendeeUserId,
                           11,
                           classroomObj?.seminar?.seminarAttendeeUserId)
   
  })
      .catch(err => {
        //console.log("errfd", err);
        setShowModal(true)
        setWarning(err.response.data.messages[0])
            setColor1("#FA113D")
        // ////alert(err.response.data.messages[0])
        
        
      }).finally(fin => {
        // konsole.log("finish", fin);
        // setLoader(false);
      })
  }
  const paymentStatus =(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)=>{
    // ////alert("new")
    const promise = Services.putPaymentClassBookingStatus(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)
    //console.log("rrgis11",bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)
      promise.then((res) => {
        //console.log("resti11",res)
 

                   
   
  })
      .catch(err => {
        //console.log("errfd11", err);
        // setShowModal(true)
        // setWarning(err.response.data.messages[0])
        //     setColor1("#FA113D")
        // ////alert(err.response.data.messages[0])
        
        
      }).finally(fin => {
        // konsole.log("finish", fin);
        // setLoader(false);
      })
  }
  useEffect(()=>{
    if(paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash" || 
       paymentSuccesStatus === "FAILED" ){
        let bookingId1 = bookingId
        let isActive = paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash" ? true : false;
        let updatedBy = stateObj?.userId;
        let remarks  = "Paymentstatus";
        let paymentTrxnNo = paymentSuccesStatus === "SUCCESS" || paymentSuccesStatus === "FAILED" ? OrderId : classOrderId ;
        let paymentStatusId = paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash" ? 1 : 2 ;
        let bookingStatusId = paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash" ? 1 : 6 ;




      paymentStatus(bookingId1,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)

    }
    if(paymentSuccesStatus === "SUCCESS" || props.location.state === "Cash"){
    
      if(classroomObj1 === true){
        let userRegistration = {};
        userRegistration['roleId'] = 2;
        userRegistration['noOfAttendee'] = 1;
        userRegistration['clientIPAddress'] = clientIpAddress;
        userRegistration['countryCode'] = '+1';
        userRegistration['semUserStatusId'] = CounterWalkIn;
        userRegistration['phoneNumber'] = classroomObj.seminar?.attendeeMobileNo 
        userRegistration['firstName'] = classroomObj.seminar?.firstName 
        userRegistration['emailAddress'] = classroomObj.seminar?.attendeeEmailAddress 
        registerNewUser(745,classroomObj.selectedUserObj?.seminarId, userRegistration);
      }
    }

  },[])


 
  
  
  return (
    <LayoutComponent>
       <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>
      {(slotIsSelected == "selectedSlot") || paymentSuccesStatus == "SUCCESS" || props.location.state === "Cash" ? (
        <div className="bgclr container-fluid">
          <div className="row  py-2">
            <div className="col-sm-6">
              <h4 className="mt-4 fw-700 fs-4">
                {
                (classroomObj1 === true) ? (
                <div>Workshop Registration: <b>Registration Confirmation</b></div>
                ):
                "Custom Planning Booking : Book a Custom Planning" 
              }
                
              </h4>
              <p className="fw-700 fs-4">Topic : {seminarObj?.seminarTopic}</p>
            </div>
            <div className="col-sm-6 text-end">
              <p className="mt-4 me-4">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Date {":"}
                </span>{" "}
                {classroomObj1 === true ? commonLib.getFormattedDate(classroomObj.selectedUserObj?.seminarDate):selectedSlot?.date}
              </p>
              <p className="ms-2">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Time Slot {":"}
                </span>{" "}
                {classroomObj1 === true ?commonLib.getFormattedTime(classroomObj.selectedUserObj?.seminarTime): selectedSlot?.time}{" "}
              </p>
            </div>
          </div>
          <div className="row" style={{ height: "auto" }}>
            <div className="mt-4 m-auto text-center bg-white py-5 col-12 col-sm-8">
              <h1 className="congratulationNew fw-bold fs-1  mt-3">
                Congratulations
              </h1>
              <h3 className="fs-4 congratulationNew fw-bold ">
                {classroomObj1 === true ?
                "You have Successfully Registered for Workshop"
                 :"You have Successfully Booked your Custom Planning"}
                {/* You have Successfully Booked your planner */}
              </h3>
              <p className="pb-5 fs-5 congratulationNew1">
                Details has been sent to your registered Email
              </p>
            </div>
          </div>
        </div>
      ) 
      : 
      (
        
        <div
          className="contenArea container-fluid  d-flex justify-content-center bgclr"
          style={{ height: "auto", flexDirection: "column" }}
        ><div className="col-sm-6">
        <h4 className="mt-4 fw-700 fs-4">
        {
                classroomObj1 === true ? (
                <div></div>
                 ):
                 "Custom Planning Booking : Book a Custom Planning" 
               }
        </h4>
        <p className="fw-700 fs-4">Topic : {seminarObj?.seminarTopic}</p>
      </div>
          <div className=" container bg-white">
            <div className="text-center">
              <img className="mt-5" src="../images/Notification.png"></img>
            </div>
            <h1
              className=" congratulationNew text-center  mt-2 fw-bold fs-1 pt-3 "
              style={{ color: "#F2494B" }}
            >
              Your Payment is Failed
            </h1>

            <div className="btm text-center mt-5 pb-4">
              <button
                type="text"
                className="theme-btn rounded text-center p-2"
                onClick={tryAgain}
              >
                Try Again
              </button>
              {/* <div className="col text-danger mt-2">(or)</div>
              <div className="col text-danger mt-2">Book Later</div> */}
            </div>
          </div>
        </div> 
       )}
    </LayoutComponent>
  );
}
