import React, { useEffect,useContext } from "react";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import konsole from "./network/konsole";
import Services from "./network/Services";
import commonLib from "./control/commonLib";
import '../components/PublishBoth.css';
import { ModalBody } from "react-bootstrap";
import { UserContext } from "../App";



function PreviewMail(props) {
    const seminarObj = commonLib.getObjFromStorage("seminarObj");
    const subtenatName = commonLib.getObjFromStorage("subtenatName");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const { setLoader } = useContext(UserContext);
    console.log("uniqueNum",props?.textOrEmail)

    useEffect(()=>{
        let textTemp = props?.textData?.textTemplateContent == null || props?.textData?.textTemplateContent == undefined ? "" : props?.textData?.textTemplateContent
        console.log("props?.previewTemplateData",props?.previewTemplateData)
        konsole.log("adadasd",props?.previewTemplateData);
      // document.getElementById('templateData').innerHTML = props?.previewTemplateData
      if(props?.textData?.textTemplateContent !== null && props?.textData?.textTemplateContent !== undefined){
        // document.getElementById('templateData1').innerHTML = textTemp
      }
      
      setLoader(false)
      },[props])
    
    const onCancel = () => {
       props?.cancelMOdal(false)
        };
     
        
        const sendText =()=>{
            let data = props?.textData?.textTemplateContent
                let data2 = props?.textData
                console.log("textData1",data2
                )
        
            let i = 0;
        function printJSON() {
            let a = props?.newUser[Object.keys(props?.newUser)[i]]
           
            console.log("aaaaa",a)

     

            console.log("a?.seminarAttendeeId",a,data2)
            let textTo = a?.mobileNo;
            let createdBy = commonLib.getObjFromStorage('stateObj')?.userId;
            let textMappingTable = a?.seminarAttendeeId;
            let templateData = data.
            replace('@@SUBTENANTNAME',subtenatName).
                replace('@@SEMINARNAME',seminarObj?.seminarTopic).
                replace('@@PLANNERLINK',a?.plannerLink).
                replace('@@PLANNERLINK',a?.plannerLink).
                replace('@@WORKSHOPLINK',a?.classroomLink). 
                replace('@@WORKSHOPLINK',a?.classroomLink).
                // .
                replace(/@@UNIQUELINK/g,props?.uniqueNum === 1 ?  a?.classroomLink : a?.plannerLink)
             console.log("templateData",templateData)
            let promise = Services.postSendText(data2?.textTemplateType, textTo, templateData, data2?.textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)
            

                konsole.log("sendTextAPIFuncRes", data2?.textTemplateType, textTo, data2?.textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)
               
                promise.then((response)=>{
                    console.log("props?.textData?.textTemplateContent",response)
            }).catch((error)=>{
                konsole.log("sendTextAPIFuncError", error)
            }).finally(()=>{
                konsole.log("sendTextAPIFuncEnd")
            })
            if (i < Object.keys(props?.newUser).length - 1) {
                i++;
              } else {
                clearInterval(interval);
              }
              
        
            }
        
            let interval = setInterval(printJSON);


        }
        const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
            // setLoader(true)
           const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
            console.log("sample21222",emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
           promises.then(res => {
            setLoader(false)
            props?.cancelMOdal(false)
            konsole.log("createdBy11",res.data.data)
         
       
             
       
       
           })
           .catch(err => {
             konsole.log("err", err.response);
             
           }).finally(fin => {
             konsole.log("finish");
            //  setLoader(false);
           })
         }
    
       
          const submitData = () => {
            setLoader(true)
            if(props?.uniqueNum === 1 || props?.uniqueNum === 2){
              let data = props?.tempData[0]?.templateContent
              let data2 = props?.tempData[0]
              let i = 0;
                console.log("datadata",props?.newUser)
               function printJSON() {
          
            let a = props?.newUser[Object.keys(props?.newUser)[i]]
             console.log("userrrr",a)
             if(props?.activeText === true){
               sendText()
             }
          
             const replacedStr1 = data.
              replace('@@SEMINARNAME',seminarObj?.seminarTopic).
              replace(/@@UNIQUELINK/g,props?.uniqueNum === 1 ?  a?.classroomLink : a?.plannerLink)
              
  
              let emailType = data2?.templateType;
              let emailTo = a?.emailAddress;
              let emailSubject = data2?.emailSubject;
              let emailTemplateId =  data2?.templateId;
              let emailContent = replacedStr1;
              let emailStatusId =  1;
              let emailMappingTable = "tblSeminarAttendeeList";
              let emailMappingTablePKId = a?.seminarAttendeeId;
              let createdBy =  stateObj?.userId;
              SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
     

          console.log("userrrr111",replacedStr1)
          
      
      
            if (i < Object.keys(props?.newUser).length - 1) {
              i++;
            } else {
              clearInterval(interval);
            }
           }
           let interval = setInterval(printJSON);

            }
            
             else if (props?.uniqueNum === 3){
                let data = props?.tempData[0]?.templateContent
                let data2 = props?.tempData[0]
                let i = 0;

                 function printJSON() {
            
              let a = props?.newUser[Object.keys(props?.newUser)[i]]
               console.log("userrrr",a)
               if(props?.activeText === true){
                 sendText()
               }
            
               const replacedStr1 = data.
            
                replace('@@SUBTENANTNAME',subtenatName).
                replace('@@SEMINARNAME',seminarObj?.seminarTopic).
                replace('@@PLANNERLINK',a?.plannerLink).
                replace('@@PLANNERLINK',a?.plannerLink).
                replace('@@WORKSHOPLINK',a?.classroomLink). 
                replace('@@WORKSHOPLINK',a?.classroomLink)
    
                let emailType = data2?.templateType;
                let emailTo = a?.emailAddress;
                let emailSubject = data2?.emailSubject;
                let emailTemplateId =  data2?.templateId;
                let emailContent = replacedStr1;
                let emailStatusId =  1;
                let emailMappingTable = "tblSeminarAttendeeList";
                let emailMappingTablePKId = a?.seminarAttendeeId;
                let createdBy =  stateObj?.userId;
                SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
       

            console.log("userrrr",replacedStr1)
            
        
        
              if (i < Object.keys(props?.newUser).length - 1) {
                i++;
              } else {
                clearInterval(interval);
              }
             }
             let interval = setInterval(printJSON);
             }
          }
    
        return(
            <div>
    
    <Modal  show={props?.openSendEmailModal} className="SampleData1"
        onCancel={onCancel}
        borderRadius={20}
        closable={false}
        footer={false}
        
        maskClosable={false}
        >
            <Modal.Body
              style={{
                // maxHeight: 'calc(100vh - 105px)',
                // overflowY: 'auto',
               
                
               }}
               >
               
            <div
              style={{
                border: "1px solid #720c20",
                width: "auto",
                margin: "-14px",
                backgroundColor: "#720c20",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                marginTop :'-16px'
              }}
              className="rounded-top"
            >
              <div className=""></div>
              <div className="fs-5 ">{props?.textOrEmail === 1 ? "Send Text & Mail":props?.textOrEmail === 2 ? "Send Text": "Send Mail"}</div>
    
              <div className="me-2 mt-1" style={{cursor: "pointer"}}  onClick={onCancel}>X</div>
            </div>
    
            <br />
            {/* <br /> */}
    
            <div >
                <ModalBody
                style={{
                    maxHeight: 'calc(100vh - 222px)',
                    overflowY: 'auto',
                    marginRight : '-0.8rem',
                    marginLeft : '-13px'
                   
                    
                   }}
                   className="vertical-scroll"
                >
                <div id="templateData">

                </div>
                {props?.textData?.textTemplateContent !== undefined && props?.textData?.textTemplateContent !== null ? (
                 
                <div id="templateData1">

                </div>
                ):""}
                
                </ModalBody>
            </div>
    
            <Row className="d-flex flex-column">
            <div className="me-4">
                <Button className="w-100 mt-2"
                  style={{
                    backgroundColor: "#720c20",
                    color: "white",
                    border: "none",
                    width: "150%",
                    borderRadius: "5px",
                    marginBottom : "10px"
                  }}
                  onClick={submitData}
                >
                  {props?.textOrEmail === 1 ? "Send Text & Mail":props?.textOrEmail === 2 ? "Send Text": "Send Mail"}
                  
                </Button>
              </div>
              <div className="md-2">
                <Button className="w-100"
                  style={{
                    backgroundColor: "white",
                    color: "#720C20",
                    border: "2px solid #720c20",
                    width: "150%",
                    borderRadius: "5px",
                    marginBottom : "10px"
                
                  }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
              
            </Row>
            </Modal.Body>
          </Modal>
         
            </div>
        )
    
}

export default PreviewMail