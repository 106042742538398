import React, { useState, useEffect, useRef } from "react";
import konsole from "./network/konsole";


const PhoneNumberMask = (props) => {

  const inputCard = useRef();
  

  const handleChange = (e) => {
   
    
    konsole.log("dss",inputCard.current.value.length)
    const cardValue = inputCard.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    konsole.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, "");
    if(numbers.length === 10){
      props?.setphonemsg("")
    }
    //  ?  :""
    props.onValueChange(numbers)
    props.setvalid(numbers)
    props.setValue("phoneNumber",numbers);
        props.setClearError('phoneNumber');
        // props.setError('phoneNumber');
;
    // inputCard.current.value.length === 0 ? "Enter the number" : ""
  };
  const filledPhoneNo = () => {
    konsole.log("filledPhoneNo",props.value);
    const cardValue = props.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    konsole.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
  //   props.setClearError('phoneNo');
  };

//   if (props.value === "0"){
//  konsole.log("propsvalue",props.value)
   
//   }
  useEffect(() => {
    // handleChange(props.value)

    if(props.value !== undefined && props.value !== "" && props.value !== null){
      filledPhoneNo();
    }else{
      // alert(props.value="1")
      // filledPhoneNo();
    }
    
  }, [props.value]);
  useEffect(() => {
      
  handleChange()
  }, []);

  return (
    <>
      <input
      className="form-control bg-light fs-6"
      
      id="phoneNumber"
        placeholder="Cell Number"
        type="text"
        ref={inputCard}
        onChange={(e)=>handleChange(e)} 
        required 
            
      />
    

      { props.value?.length == 0 ? <p className="ps-3 text-danger">{ props.errors?.phoneNumber   && props.errors.phoneNumber.message}</p>:""}
    </>
  );
};

export default PhoneNumberMask;
// import React, { useState, useEffect, useRef } from 'react';

// const PhoneNumberMask = (props) => {
//     // const [card, setCard] = useState();
//     const inputCard = useRef();

//     const handleChange = () => {
//         const cardValue = inputCard.current.value
//             .replace(/\D/g, '')
//             .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
//         // console.log(cardValue, cardValue[2]);
//         inputCard.current.value = !cardValue[2]
//             ? cardValue[1]
//             : `(${cardValue[1]}) ${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''
//             }`}${`${cardValue[4] ? `-${cardValue[4]}` : ''}`}`;
//         const numbers = inputCard.current.value.replace(/(\D)/g, '');
//         // props.onValueChange(numbers)

//         props.setValue("phoneNumber",numbers);
//     props.setClearError('phoneNo');
//     };

//     useEffect(() => {
//         handleChange();
//     }, []);

//     return (
//         <>
//             <input 
//             type="text"
//             className='form-control bg-light fs-6'
//             placeholder='Enter your Cell number'
//             id="phoneNumber"
//              ref={inputCard} 
//              onChange={handleChange} />
//         </>
//     );
// };

// export default PhoneNumberMask;