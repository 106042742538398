import "./Dashboard.css";
import LayoutComponent from "../LayoutComponent";
import commonLib from "../control/commonLib";
import { useCallback, useContext, useEffect, useState } from "react";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { UserContext } from "../../App";
import moment from "moment";

const   Dashboard = (props) => {
  const { setLoader } = useContext(UserContext);
  const [seminarList, setSeminarList] = useState([]);
  const subtenantId = commonLib.getSessionStoarge("subtenantId");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const [toggleButton, setToggleButton] = useState(true);
  const pastseminarlist = [];
   //console.log("sem11",props)
  useEffect(() => {
    const isActive = true;
    konsole.log("subtenantId", subtenantId)
    // alert("22")
   
      getSeminarPublish(subtenantId, stateObj.userId, isActive);
    
    
  }, []);

  const getSeminarPublish = (subtenantId, coordinaterUserId, isActive) => {
    // alert("hyyy")
    //console.log("Error Catched1111",subtenantId, coordinaterUserId, isActive)
    setLoader(true);
    const promise = Services.getSeminarPublisher(
      subtenantId,
      undefined,
      isActive
    );
    promise
      .then((response) => {
        setLoader(false);
        // konsole.log("Seminar Date", response.data.data[0].seminarDate);
        konsole.log("seminarObj", response.data.data);
        setSeminarList(response.data.data);
      })
      .catch((e) => {
        konsole.log("Error Catched: ", e.response);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getSeminarPublisherFunc = (seminarId) =>{
    setLoader(true)
    let promise = Services.getSeminarPublisher1(seminarId, true)
    promise.then((res)=>{
      konsole.log("getSeminarPublisher1res",res.data.data)
      commonLib.setSessionStoarge("seminarList3", JSON.stringify(res.data.data[0]))
      setLoader(false)
    }).catch((error)=>{
      konsole.log("getSeminarPublisher1error",error)
      setLoader(false)
    }).finally(()=>{
      konsole.log("getSeminarPublisher1end")
      setLoader(false)
    })

  }

  const handleClickLink = (seminar) => {
    konsole.log("set11",seminar)
    getSeminarPublisherFunc(seminar?.seminarId)
    setLoader(true);
    const venueObj = commonLib.getVenueObj(seminar);
    const seminarObj = commonLib.getSeminarObj(seminar);
    commonLib.setSessionStoarge("venueObj", JSON.stringify(venueObj));
    commonLib.setSessionStoarge("seminarObj", JSON.stringify(seminarObj));
    commonLib.setSessionStoarge("seminarList2", JSON.stringify(seminar));
    commonLib.setSessionStoarge("Location", JSON.stringify(seminar));
    konsole.log("semiar", venueObj, seminarObj);
    // setLoader(false);
    // alert("same")
    props.history.push({
      pathname: "/counter",
      state: { detail: toggleButton }
    });
  };

  const pastSeminarfunc = (event) => {

    konsole.log("eve")
    setToggleButton(!toggleButton);

  };


  konsole.log("pastseminarlist", pastseminarlist, seminarList);



  const sortedSeminar = () => {
    const seminarListFiltered = seminarList.filter((e) => {
      // var d1 = moment().format("DD-MM-YYYY");
      // var d2 = moment(e.seminarDate).format("DD-MM-YYYY");
      var d1 = moment().format("YYYY-MM-DD");
      var d2 = moment(e.seminarDate).format("YYYY-MM-DD");
      
      if (toggleButton == true) {
       
        if (d1 <= d2) {
          return true
        }
       
      }
      else {
        if (d1 > d2) {
          return true
        }
       
      }

    })
    //console.log("semw",seminarListFiltered)

    return seminarListFiltered.sort((a, b) => {
      if (toggleButton == true) {
        return moment((a.seminarDate.split(" ")[0] + " " + a.seminarTime), 'MM/DD/YYYY HH:mm:s') - moment((b.seminarDate.split(" ")[0] + ' ' + b.seminarTime), 'MM/DD/YYYY HH:mm:ss')
      }
      else {
        return moment((b.seminarDate.split(" ")[0] + " " + b.seminarTime), 'MM/DD/YYYY HH:mm:s') - moment((a.seminarDate.split(" ")[0] + ' ' + a.seminarTime), 'MM/DD/YYYY HH:mm:ss')
      }
    })
  }


  return (
    <div>
      <LayoutComponent pathName="Dashboard">
        <div
          className="mt-3 px-3 d-flex justify-content-between align-items-center w-100 mobileLocateHome "
          style={{ height: "40px", backgroundColor: "#F6F6F6" }}
        >
          <h3 className="proceedSeminarTagH3">{(toggleButton == true) ? (subtenantId == 745 ? "Choose a Workshop to proceed." :"Choose a seminar to proceed.") :(subtenantId == 745 ? "Past Workshop" : "Past Events") }</h3>

          <div className=" d-flex " >
            <div className="d-flex align-items-center align-self-center">
              {/* <h6 className={(toggleButton == true) ? "upcoming" : "past"}>Past</h6> */}
              <span style={{fontWeight:"bold"}} className={(toggleButton == true) ? "upcoming" : "past"}>Past</span>
            </div>
            <div className="form-check form-switch mx-2 d-flex justify-content-center">
              <input
                className="form-check-input toggle shadow-none"
                
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                placeholder="Past Event"
                checked={toggleButton}
                onChange={(event) => pastSeminarfunc(event)}
                // data-onstyle="secondary"
                // style={{ color: "#720C20" }}

              />
            </div>
            <div className="">
            <span style={{fontWeight:"bold"}} className={(toggleButton == true) ? "past" : "upcoming"}>Upcoming</span>
            </div>
          </div>
        </div>
        {seminarList.length > 0 && sortedSeminar().map((seminar, index) => {
          // //console.log("sample11",seminar)
          return (
            <>
              <div key={index}>
                <div className="container bg-white">
                  <div
                    className="row mb-3 pt-1 mt-1"
                    style={{ border: "1px dotted black" }}
                  >
                    <div className="col ">
                      <div className="row pt-2 ps-1 px-1">
                        <div className="col-6 col-xs-8">
                          <h4 className="retired-Life-h1-tag ">
                            <b
                              className="fs-3 color mobileText1"
                              style={{ color: "#720C20" }}
                            >
                              {seminar.seminarTopic}
                            </b>
                            <span className="fs-5">
                              {" "}
                              ({seminar.seminarType},{" "}
                              {seminar.isSeminarPaid
                                ? `Paid: $${seminar.seminarFee}`
                                : "Free"}
                              )
                            </span>
                          </h4>
                        </div>
                        <div className="col-6 col-xs-4  d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn p-0  btn-outline-dark Proceed-btn"
                            onClick={() => handleClickLink(seminar)}
                          >
                            Proceed to Seminar
                          </button>
                        </div>
                      </div>
                      <div className="row p-0">
                        <div className="col-12">
                          <h5 className="adviser-tag-h4 m-0">
                            Presenter:
                            {seminar.speakers.length > 0 &&
                              seminar.speakers.map((speaker, index) => {
                                const fName =
                                  speaker.fName !== null &&
                                    speaker.fName !== ""
                                    ? speaker.fName
                                    : "";
                                const mName =
                                  speaker.mName !== null &&
                                    speaker.mName !== ""
                                    ? speaker.mName
                                    : " ";
                                const lName =
                                  speaker.lName !== null &&
                                    speaker.fName !== ""
                                    ? speaker.lName
                                    : "";
                                return (
                                  <span key={index}>{` ${fName + mName + lName
                                    } ${seminar.speakers.length - 1 === index
                                      ? ""
                                      : ", "
                                    }`}</span>
                                );
                              })}
                          </h5>
                        </div>
                      </div>
                      <div className="row p-0">
                        <div className="col-12 m-0">
                          <h6 className="m-0">
                            On{" "}
                            {commonLib.getFormattedDate(
                              seminar.seminarDate
                            )}{" "}
                            At{" "}
                            {commonLib.getFormattedTime(
                              seminar.seminarTime
                            )}
                          </h6>
                        </div>
                      </div>
                      {
                        seminar?.seminarTypeId !== 47 &&
                      <div className="row">
                        <div className="col-12">
                          <h6 className="date-Time-tag-h4 m-0">
                            {seminar?.seminarType !== "Direct" ?  "" : "Venue : " + seminar.seminarVenue}
                            {/* Venue : {seminar.seminarVenue} */}
                          </h6>
                          <h6 className="date-Time-tag-h4 m-0">
                            Location : {seminar.venueAddressLine1}
                          </h6>
                        </div>
                      </div>
                      }
                      <div className="row">
                        <div className="col-10">
                          <p className="adviser-tag-h4 mt-2 ">
                            {seminar.seminarTopic}

                            {/* <a type="button" className="text-decoration-underline text-dark ms-1" onClick={() => readMoreFunction("readMore", index)}>
                                                                        Read More
                                                                    </a> */}
                          </p>
                        </div>
                        <div className="col-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </LayoutComponent>
    </div>
  );
};

export default Dashboard;
