import moment from 'moment';
import React, { useContext, useEffect, useState ,useRef} from 'react'
import { useHistory,useParams,useLocation } from 'react-router-dom';
import { UserContext } from '../App';
import commonLib from './control/commonLib';
import { CounterPlanner } from './control/Constant';
import occurrenceId from './control/occurrence';
import LayoutComponent from './LayoutComponent';
import konsole from './network/konsole';
import Services from './network/Services';
import Notification from './Notification';
import '../components/PublishBoth.css'



export default function AfterPaymentScreen(props) {
  const [showModal , setShowModal] = useState(false)
  const { setLoader } = useContext(UserContext);
  const selectedUser = (props.location.state !== undefined) ? props.location.state : {};
  const history = useHistory();
  const location=useLocation()
  const [plannerCalId, setPlannerCalId] = useState("")
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const [plannerBooking, setPlannerBooking] = useState([]);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const classroomObj = commonLib.getObjFromStorage("classroomStaus");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const [clientIpAddress, setClientIpAddress] = useState('');
  let emailMappingTablePKId =attendeeObj?.seminarAttendeeId.toString();
  const [userDetails,setuserDetails] = useState()
  const uniquelink = `https://Unilogin.agingoptions.com/account/Signin?subtenantId=${seminarObj.subtenantId}`
  const OrderId = props.location.search;
  const orderId=commonLib.getQueryParameters(OrderId, 'OrderId')
  const paymentSuccesStatus= commonLib.getQueryParameters(OrderId,'TrxnStatus')
  const subtenatName = commonLib.getObjFromStorage("subtenatName");
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  const [upsertData, setupsertData] = useState()
  const referenceRef = useRef(null);
  const plannerDataRef = useRef();
  const plannerCall = useRef();
  const templeteId = useRef(false);
  const finalData = useRef(false);
  const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
  konsole.log("sun11",attendeeObj?.seminarAttendeeUserId)

 konsole.log("plannerCalId1",props)
 const filterData =(len,data)=>{
  let data2 = []
  for(let i=0; i <= len-1; i++){
    // konsole.log("dst1",[i],data[i])
    if(data[i]?.plannerTime && data[i]?.plannerDate ===  data[i+1]?.plannerTime && data[i+1]?.plannerDate){
      konsole.log("dst122",data[i])
       
    }
    else{
      //  data[i]
    }
   
  }

 

 }
 if(plannerBooking.length < 18){
  let len = plannerBooking.length;
    filterData(len,plannerBooking);
 }
 else{
  let len = 18;
  filterData(len,plannerBooking)
 }
 useEffect(() => {
   if(paymentSuccesStatus === "SUCCESS" || props.location.state?.paymentType === "Cash" ){
    const seminarAttendeeId = attendeeObj.seminarAttendeeId;
    // const billTrackNo = "BillTrackNo" //for now later, change into the transaction key;
    const upsertedBy = commonLib.getObjFromStorage("stateObj").userId
    const paymentStatusId = 1;
    const paymentStatusDate = new Date();
    const isActive = true;
  
    const updatedBy = upsertedBy;
    konsole.log("upsertedBy",updatedBy)
    let orderId1 = props.location?.state?.orderId ?? orderId
    upsertPlannerBooking(0, seminarAttendeeId, orderId1, paymentStatusId, paymentStatusDate, upsertedBy, isActive); 

   }


 },[])
 
 

useEffect(() => {
    plannerCalendr();
    getIpAddress()

    
    // getUserRoles(stateObj?.userId,seminarObj?.subtenantId)
  
  }, [])
  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("samip",res)
      setClientIpAddress(res.IPv4);
    })
    // getUserRoles(stateObj?.userId,seminarObj?.subtenantId)
  }


  const plannerCalendr = () => {
    setLoader(true);
    // let subtenantId = sessionStorage.getItem("subtenantId")
    let promises = Services.getPlannerCalender(true,1,"UPCOMING",seminarObj.subtenantId);
    // konsole.log("slots",true,1,"UPCOMING",seminarObj.subtenantId)
    promises
      .then((res) => {
        setLoader(false);
        konsole.log("Succeed", res.data.data)
        konsole.log("res", res)
        setPlannerBooking(res.data.data)
        // setPlannerBooking("")
        
      })
      .catch((error) => {
        konsole.log("error", error)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const selectedTimeSlotObj=(index)=>{
    // konsole.log("selected time slot index", index)
    setSelectedTimeSlotIndex(index);
    const selectedTimeSlot = plannerBooking[index];
    konsole.log("selected time slot",selectedTimeSlot);
    setSelectedTimeSlot(selectedTimeSlot);
    setPlannerCalId(selectedTimeSlot.plannerCalId)
  }



  const createjsonnameoremail=(coordinatorFullName,coordinatorEmailId)=>{
    return {
      "coordinatorFullName":coordinatorFullName,
      "coordinatorEmailId":coordinatorEmailId
      
    }
  }
   const getSeminarPublish = (seminarId, isActive)=>{
    // console.log("semid",seminarId)
    // alert("hy")
    const promises = Services.getSeminarPublisher1(seminarId, isActive);
    promises.then((res) => {
      // console.log("newSS",res.data?.data?.[0]?.coordinators)
      let data = res.data?.data?.[0]?.coordinators
      let len = res.data?.data?.[0]?.coordinators.length
      let arraydata=[]
      // alert("termp")
      for(let i = 0 ;i<len;i++){
        //  console.log("data111",data[i])
        //  console.log("data321",i == len-1)
         if(i == len-1){
          templeteId.current = false
           history.push({
      pathname: '/MailToCoordinator',
      // state: planuser
    })
          

         }
         
          GetEmailTemplateUsers(16,true,data[i])
         
      
      }
      // console.log("arraydata",arraydata)
// 
     

    })
    .catch((error) => {
      // console.log("errorerror1", error)
    })
    .finally(error => {
      konsole.log("finish");
      setLoader(false);
    })

   }

  const postBookSumbit = () => {

    if (selectedTimeSlotIndex === null) {
      // alert();
      setWarning("Please Choose Booking Slot")
      setColor1("#ffcc00")
      setShowModal(true)
      return;
    }
    else{
      const plannerAppId = upsertData?.plannerAppId;
      const seminarAttendeeId = upsertData?.seminarAttendeeId;
      // console.log("nameww",templeteId)
      const bookingStatusId = templeteId.current == true ? 6 : 1;
      const bookingStatusDate = moment(new Date().toISOString())._i
     const  callId = plannerCall.current === undefined || plannerCall.current === "" ? plannerCalId  : plannerCall.current

      const updatedBy = commonLib.getObjFromStorage("stateObj").userId;
  
     
     updatePlannerBooking(plannerAppId,seminarAttendeeId,callId,bookingStatusId,bookingStatusDate,updatedBy);
      
    // const plannerAppId = selectedTimeSlot.plannerAppId; // 0
    // konsole.log("ssd",plannerAppId)
    // const seminarAttendeeId = attendeeObj.seminarAttendeeId;
    // const billTrackNo = "BillTrackNo" //for now later, change into the transaction key;
    // const upsertedBy = commonLib.getObjFromStorage("stateObj").userId
    // const paymentStatusId = 1;
    // const paymentStatusDate = new Date();
    // const isActive = true;
    // // const bookingStatusId = 1;
    // // const bookingStatusId = templeteId.current === false ? 1 : 6;
    // // const bookingStatusDate = new Date();
    // const updatedBy = upsertedBy;
    // konsole.log("upsertedBy",updatedBy)
    // let orderId1 = props.location?.state?.orderId ?? orderId
    // upsertPlannerBooking(plannerAppId, seminarAttendeeId, orderId1, paymentStatusId, paymentStatusDate, upsertedBy, isActive);  
  
  
  }
    
  
  }
  
  const upsertPlannerBooking = (plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive) => {
    konsole.log("selectedUser12121",selectedUser)
    setLoader(true);
    const promises = Services.upsertPlannerBooking(plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive);
    promises.then((res) => {
      setLoader(false);
      konsole.log("Planner Booked response", res.data)
      setupsertData(res.data?.data[0])
      //  const plannerAppId = res.data.data[0].plannerAppId;
      //  const seminarAttendeeId = res.data.data[0].seminarAttendeeId;
      //  console.log("nameww",templeteId)
      //  const bookingStatusId = templeteId.current == true ? 6 : 1;
      //  const bookingStatusDate = moment(new Date().toISOString())._i
      // const  callId = plannerCall.current === undefined || plannerCall.current === "" ? plannerCalId  : plannerCall.current

      //  const updatedBy = commonLib.getObjFromStorage("stateObj").userId;
   
      
      // updatePlannerBooking(plannerAppId,seminarAttendeeId,callId,bookingStatusId,bookingStatusDate,updatedBy);

      // const timeSlot = {
      //   time: selectedTimeSlot.plannerTime,
      //   date: selectedTimeSlot.plannerDate
      // }
      // alert("2")
      // const timeSlot = templeteId.current === true ? "" : {
      //   time: selectedTimeSlot.plannerTime,
      //   date: selectedTimeSlot.plannerDate
      // }
      const attendeeUserId = attendeeObj?.attendeeUserId;
      const updatedBy = commonLib.getObjFromStorage("stateObj").userId;
     
      handleWalkInCheckInUser(seminarObj?.subtenantId, seminarAttendeeId, attendeeUserId, CounterPlanner, updatedBy);
      // commonLib.setDatatoSession("timeSlot", JSON.stringify(timeSlot))
      // history.push({
      //   pathname: '/venuePayment',
      //   // slottedTime: timeSlot
      //   state : selectedUser
      // })
    })
      .catch((error) => {
        konsole.log("errorerror", error)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const updatePlannerBooking = (plannerAppId,seminarAttendeeId,plannerCalId,bookingStatusId,bookingStatusDate,updatedBy) => {
    setLoader(true);
    const promises = Services.updatePlannerBooking(plannerAppId,seminarAttendeeId,plannerCalId,bookingStatusId,bookingStatusDate,updatedBy);
    // console.log("newqq",plannerAppId,seminarAttendeeId,plannerCalId,bookingStatusId,bookingStatusDate,updatedBy)
    promises.then((res) => {
      setLoader(false);
      konsole.log("Plannesponse2r Booked re", res.data.data)
      plannerDataRef.current = res.data.data
      // const timeSlot = templeteId.current === true ? "" : {
      //   time: selectedTimeSlot.plannerTime,
      //   date: selectedTimeSlot.plannerDate
      // }
      // const attendeeUserId = attendeeObj?.attendeeUserId;
      // handleWalkInCheckInUser(seminarObj?.subtenantId, seminarAttendeeId, attendeeUserId, CounterPlanner, updatedBy, timeSlot);
      getUserRoles(attendeeObj?.seminarAttendeeUserId,userDetails)
      const timeSlot = templeteId.current === true ? "" : {
        time: selectedTimeSlot.plannerTime,
        date: selectedTimeSlot.plannerDate
      }
      commonLib.setDatatoSession("timeSlot", JSON.stringify(timeSlot))
      if(templeteId.current === false){
        history.push({
          pathname: '/confirmBooking',
          state: "selectedSlot",
          // state : selectedUser
        })
      }
    })
      .catch((error) => {
        // console.log("error1", error.response)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  } 

  
  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy, timeSlot) => {

    // alert("hy")
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);
      setuserDetails(res.data?.data)
      konsole.log("userss",res.data.data)
      referenceRef.current = res.data.data?.attendees[0] ;
      // getUserRoles(attendeeObj?.seminarAttendeeUserId,res.data.data)
      konsole.log('registered Checkin res', res.data.data);
      if(templeteId.current === false){
        // history.push({
        //   pathname: '/CounterPlanner/confirmBooking',
        //   state: "selectedSlot",
        //   // state : selectedUser
        // })
      }
      
     
    })
      .catch(err => {
        konsole.log("err", err.response);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
     setLoader(true)
    const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
    //  console.log("sample21",emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
    promises.then(res => {
      setLoader(false)
     konsole.log("createdBy11",res.data.data)
    //  console.log("temp1",templeteId)
     if(templeteId.current == true){
      finalData.current = true;
      getSeminarPublish(attendeeObj?.seminarid,true)
      

      
    // history.push({
    //   pathname: '/MailToCoordinator',
    //   // state: planuser
    // })
     }

      


    })
    .catch(err => {
      konsole.log("err", err.response);
      
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const GetEmailTemplateUsers = (TempalteId,isActive,elsePart,response,newUser)=>{
    let subtenantId = sessionStorage.getItem('subtenantId')
    
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);

    konsole.log("restR11111", TempalteId,isActive,elsePart,response,newUser);
    promises.then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
      konsole.log("restapi",referenceRef.current?.emailAddress)
      if(elsePart === true){
        // let tempmId =  templeteId === true ? 15 : 13
       konsole.log("dummy",data2)
       const replacedStr1 = data.
      replace('@@ATTENDEENAME',referenceRef.current?.fName+" "+referenceRef.current?.lName).
      replace('@@ATENDEENAME',referenceRef.current?.fName+" "+referenceRef.current?.lName).
      replace('@@ATTENDEENAME',newUser === true ?referenceRef.current?.fName+""+referenceRef.current?.lName : "").
      replace('@@PlannerDate',commonLib.getFormattedDate(selectedTimeSlot?.plannerDate)).
      replace('@@PlannerTime',selectedTimeSlot?.plannerTime).
      replace('@@UNIQUELINK',response?.activationURL).
      replace("@@UNIQUELINK",response?.activationURL).
      replace('@@SUBTENANTNAME',finalData.current === true ? subtenatName : "").
      replace('@@COORDINATORNAME',finalData.current === true ? elsePart?.coordinatorFullName: "").
      replace('@@ATTENDEEEMAIL',finalData.current === true ? referenceRef.current?.emailAddress : "").
      replace('@@ATTENDEECELLNO',finalData.current === true ? referenceRef.current?.mobileNo : "");

      
      
      konsole.log("newData",referenceRef.current?.emailAddress);
     let emailType = data2?.templateType;
     let emailTo = finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress;
     let emailSubject = data2?.emailSubject;
     let emailTemplateId =  data2?.templateId;
     let emailContent = replacedStr1;
     let emailStatusId =  1;
     let emailMappingTable = finalData.current === true ? " tblSeminarCoordinator" :"tblSeminarAttendeeList";
     let emailMappingTablePKId = finalData.current === true ? elsePart?.seminarCoordinatorId : attendeeObj?.seminarAttendeeId.toString();
     let createdBy =  stateObj?.userId;

     let allData = {
      ATTENDEENAME: referenceRef.current?.fName+" "+referenceRef.current?.lName,
      emailId: finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress,
      PlannerDate: commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
      PlannerTime: selectedTimeSlot?.plannerTime,
      UNIQUELINK: response?.activationURL,
      plannerEmailId: plannerDataRef.current?.plannerEmailId,
      bookingStatusName: plannerDataRef.current?.bookingStatusName,
      PlannerName: plannerDataRef.current?.plannerUserName,
      ATTENDEEEMAIL: finalData.current === true ? referenceRef.current?.emailAddress : "",
      ATTENDEECELLNO: finalData.current === true ? referenceRef.current?.mobileNo : "",
      // COORDINATORNAME: finalData.current === true ? elsePart?.coordinatorFullName: "",
      SUBTENANTNAME: finalData.current === true ? subtenatName : ""


     }

     if(templeteId.current == true) {
      finalData.current = true;
      // getSeminarPublish(attendeeObj?.seminarid,true)

      let seminarData = commonLib.getObjFromStorage("attendeeObj")

    let promise = Services.getSeminarPublisher1(seminarData?.seminarid, true)
    promise.then((res)=>{
      konsole.log("response?.seminarid",res)

      let coordinatorData = res.data.data[0].coordinators

      for (let i = 0; i< coordinatorData.length; i++){

        if(i == coordinatorData.length - 1){
          templeteId.current = false
          history.push({
     pathname: '/MailToCoordinator',
          })
        }

        let allData1 = {
          ATTENDEENAME: referenceRef.current?.fName+" "+referenceRef.current?.lName,
          emailId: finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress,
          PlannerDate: commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
          PlannerTime: selectedTimeSlot?.plannerTime,
          UNIQUELINK: response?.activationURL,
          plannerEmailId: plannerDataRef.current?.plannerEmailId,
          bookingStatusName: plannerDataRef.current?.bookingStatusName,
          PlannerName: plannerDataRef.current?.plannerUserName,
          ATTENDEEEMAIL: finalData.current === true ? referenceRef.current?.emailAddress : "",
          ATTENDEECELLNO: finalData.current === true ? referenceRef.current?.mobileNo : "",
          COORDINATORNAME: coordinatorData[i].coordinatorFullName,
          coordinatorEmailId: coordinatorData[i].coordinatorEmailId,
          SUBTENANTNAME: finalData.current === true ? subtenatName : ""
          
          
        }
        konsole.log("alldata111111111",allData1)
        
        occurrenceId.callOccurrenceIdFunc(15, subtenantId, allData1)



      }
    




    }).catch((error)=>{
      konsole.log("errorData",error)
    }).finally(()=>{
      konsole.log("end")
    })
      
      occurrenceId.callOccurrenceIdFunc(14, subtenantId, allData)

      
    // history.push({
    //   pathname: '/MailToCoordinator',
    //   // state: planuser
    // })
     }else{

      

       
       
       occurrenceId.callOccurrenceIdFunc(11, subtenantId, allData)
       occurrenceId.callOccurrenceIdFunc(12, subtenantId, allData)
       
       
      }
    //  SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)


      }
      else{
      const replacedStr = data.
      replace('@@ATTENDEENAME',referenceRef.current?.fName+""+referenceRef.current?.lName).
      replace('@@ATENDEENAME',referenceRef.current?.fName+""+referenceRef.current?.lName).
      replace('@@ATTENDEENAME',newUser === true ?referenceRef.current?.fName+""+referenceRef.current?.lName : "").
      replace('@@PlannerDate',newUser === true ? "": commonLib.getFormattedDate(selectedTimeSlot?.plannerDate)).
      replace('@@PlannerTime',newUser === true ? "": selectedTimeSlot?.plannerTime).
      replace('@@UNIQUELINK',newUser === true ? response?.activationURL : uniquelink).
      replace("@@UNIQUELINK",newUser === true ? response?.activationURL : uniquelink).
      replace('@@SUBTENANTNAME',finalData.current === true ? subtenatName : "").
      replace('@@COORDINATORNAME',finalData.current === true ? elsePart?.coordinatorFullName: "").
      replace('@@ATTENDEEEMAIL',finalData.current === true ? referenceRef.current?.emailAddress : "").
      replace('@@ATTENDEECELLNO',finalData.current === true ? referenceRef.current?.mobileNo : "").
      replace("activate",newUser === true ? "activate":"login");
      
      
      konsole.log("newData",replacedStr);
      let emailType = data2?.templateType;
      let emailTo = finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress;
      let emailSubject = data2?.emailSubject;
      let emailTemplateId =  data2?.templateId;
      let emailContent = replacedStr;
      let emailStatusId =  1;
      let emailMappingTable = finalData.current === true ? " tblSeminarCoordinator" :"tblSeminarAttendeeList";
      let emailMappingTablePKId = finalData.current === true ? elsePart?.seminarCoordinatorId : attendeeObj?.seminarAttendeeId.toString();
      let createdBy =  stateObj?.userId;
    //  konsole.log("emailMappingTablePKId",emailMappingTablePKId)

    let allData = {
      ATTENDEENAME: referenceRef.current?.fName+" "+referenceRef.current?.lName,
      emailId: finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress,
      PlannerDate: commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
      PlannerTime: selectedTimeSlot?.plannerTime,
      UNIQUELINK: newUser === true ? response?.activationURL : uniquelink,
      plannerEmailId: plannerDataRef.current?.plannerEmailId,
      bookingStatusName: plannerDataRef.current?.bookingStatusName,
      PlannerName: plannerDataRef.current?.plannerUserName,
      ATTENDEEEMAIL: finalData.current === true ? referenceRef.current?.emailAddress : "",
      ATTENDEECELLNO: finalData.current === true ? referenceRef.current?.mobileNo : "",
      COORDINATORNAME: finalData.current === true ? elsePart?.coordinatorFullName: "",
      SUBTENANTNAME: finalData.current === true ? subtenatName : ""


     }

     if(templeteId.current == true) {
      finalData.current = true;
      // getSeminarPublish(attendeeObj?.seminarid,true)

      let seminarData = commonLib.getObjFromStorage("attendeeObj")

    let promise = Services.getSeminarPublisher1(seminarData?.seminarid, true)
    promise.then((res)=>{
      konsole.log("response?.seminarid",res)

      let coordinatorData = res.data.data[0].coordinators

      for (let i = 0; i< coordinatorData.length; i++){

        if(i == coordinatorData.length - 1){
          templeteId.current = false
          history.push({
     pathname: '/MailToCoordinator',
          })
        }

        let allData1 = {
          ATTENDEENAME: referenceRef.current?.fName+" "+referenceRef.current?.lName,
          emailId: finalData.current === true ? elsePart?.coordinatorEmailId : referenceRef.current?.emailAddress,
          PlannerDate: commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
          PlannerTime: selectedTimeSlot?.plannerTime,
          UNIQUELINK: newUser === true ? response?.activationURL : uniquelink,
          plannerEmailId: plannerDataRef.current?.plannerEmailId,
          bookingStatusName: plannerDataRef.current?.bookingStatusName,
          PlannerName: plannerDataRef.current?.plannerUserName,
          ATTENDEEEMAIL: finalData.current === true ? referenceRef.current?.emailAddress : "",
          ATTENDEECELLNO: finalData.current === true ? referenceRef.current?.mobileNo : "",
          COORDINATORNAME: coordinatorData[i].coordinatorFullName,
          coordinatorEmailId: coordinatorData[i].coordinatorEmailId,
          SUBTENANTNAME: finalData.current === true ? subtenatName : ""
          
          
        }
        konsole.log("alldata111111111",allData1)
        
        occurrenceId.callOccurrenceIdFunc(15, subtenantId, allData1)



      }
    




    }).catch((error)=>{
      konsole.log("errorData",error)
    }).finally(()=>{
      konsole.log("end")
    })
      
      occurrenceId.callOccurrenceIdFunc(14, subtenantId, allData)

      
    // history.push({
    //   pathname: '/MailToCoordinator',
    //   // state: planuser
    // })
     }else{

       
       
       occurrenceId.callOccurrenceIdFunc(11, subtenantId, allData)
       occurrenceId.callOccurrenceIdFunc(12, subtenantId, allData)
       
      }

      // SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
      }
    })
  
    .catch(err => {
      konsole.log("err", err.response);
      
    }).finally(fin => {
      // konsole.log("finish");
      // setLoader(false);
    })

  }
  const getActivateUser =(userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress,newUser)=>{
    const promises = Services.userActivationPost(userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress);
    konsole.log("json1",userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress)
    promises.then(res => {
      // konsole.log("same321",userDetails)
      let response1 = res.data.data
      let elsePart = true
      let tempmId =  templeteId.current === true ? 15 : 13
      
      GetEmailTemplateUsers(tempmId,true,elsePart,response1,newUser)

    })
    .catch(err => {
      konsole.log("errdd", err.response);
      // Toaster.error(err.response.data.messages[0])
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const getUserRoles = (userId,data1)=>{
     konsole.log("getUser",userDetails)
    const promises = Services.GetUserRolebByID(userId);
    promises.then(res => {
       let len = res.data.data.length;
       let data = res.data.data;
       konsole.log('registered Checkin res11', data);
       let newData = []
       for(let i = 0 ; i <len;i++){
        
        newData.push(data[i].roleId)
       }
       konsole.log("newData11",newData)
    
      const found = newData.find(element => element == 1 || element == 9 || element == 10 || element == 2);

     
       konsole.log("roles",found)
    
        konsole.log("rolid",![1,9,10].includes(found))



          if(([1,9,10]).includes(found)){
           
           konsole.log("tom$",data[0]?.isActive , data[0]?.auth0Id)
          if(data[0]?.isActive === true  && data[0]?.auth0Id != null){
            // alert("yes")
            let tempmId =  templeteId.current === true ? 15 : 13
            GetEmailTemplateUsers(tempmId,true ,data1)

          }
          else{
            getActivateUser(data[0]?.loginUserId,attendeeObj?.seminarAttendeeUserId,data[0]?.signUpPlatform,stateObj?.userId,clientIpAddress)
          }
         }

        
         else{
          
          if(newData.length === 1 && found === 2){
            let newUser = true
            // alert("yes")
            getActivateUser(data[0]?.loginUserId,attendeeObj?.seminarAttendeeUserId,data[0]?.signUpPlatform,stateObj?.userId,clientIpAddress,newUser)

          }
       
         }

      

        
        

        // newRole.push(ele?.roleId)
         


       
          
      //  })
       
     
    })
      .catch(err => {
        konsole.log("err", err.response);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        // konsole.log("finish");
        // setLoader(false);
      })

  }

  const contactMe = () => {
    // alert("yes")
    setPlannerCalId(null)
    plannerCall.current = null
    templeteId.current = true
    // setTempleteId(true)
    
    // const plannerAppId = 0;
    //   const seminarAttendeeId = attendeeObj.seminarAttendeeId;
    //   const billTrackNo = "BillTrackNo" //for now later, change into the transaction key;
    //   const upsertedBy = attendeeObj.seminarAttendeeUserId;

    //   const paymentStatusId = 1;
    //   const paymentStatusDate = new Date();
    //   const isActive = true;
    //   let orderId1 = props.location?.state?.orderId ?? orderId
    //   upsertPlannerBooking(plannerAppId, seminarAttendeeId, orderId1, paymentStatusId, paymentStatusDate, upsertedBy, isActive);
    const plannerAppId = upsertData?.plannerAppId;
    const seminarAttendeeId = upsertData?.seminarAttendeeId;
    // console.log("nameww",templeteId)
    const bookingStatusId = templeteId.current == true ? 6 : 1;
    const bookingStatusDate = moment(new Date().toISOString())._i
   const  callId = plannerCall.current === undefined || plannerCall.current === "" ? plannerCalId  : plannerCall.current

    const updatedBy = commonLib.getObjFromStorage("stateObj").userId;

   
   updatePlannerBooking(plannerAppId,seminarAttendeeId,callId,bookingStatusId,bookingStatusDate,updatedBy);

  }
 



  const tryAgain=()=>{
    history.push({
      pathname:"/venuePayment",
      state : attendeeObj
    })
  }
  const dayFormate = (date) => {
    // alert("juu")
    
    // console.log("data11",d)
    // var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // // var d = new Date(seminar);
    // var dayName = days[d.getDay()];
    // // var d2 = new Date(toDate);
    
    // // var dayName2 = days[d2.getDay()];
    // return dayName
    const d = new Date(date);
    let day = d.getDay()

switch (day) {
  case 0:
   return day = "Sun";
    break;
  case 1:
    return day = "Mon";
    break;
  case 2:
    return day = "Tue";
    break;
  case 3:
    return day = "Wed";
    break;
  case 4:
    return day = "Thus";
    break;
  case 5:
    return day = "Fri";
    break;
  case  6:
    return day = "Sat";
}

}


  return (



    <LayoutComponent>
      <div>
<Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>
{(paymentSuccesStatus=="SUCCESS" || props.location.state?.paymentType === "Cash")?

<div className='contenArea container-fluid pb-3  bgclr' style={{height: 'auto'}}>
<div className='row'>
  <div className='col-sm-6  pt-2'>
    <h4 className='mt-2'>Custom Planning Booking : Book a Custom Planning</h4>
    <p className='fs-5'><b>Topic</b>: {seminarObj?.seminarTopic}</p>
  </div>
</div>
<div className='container bg-white text-center vertical-scroll'style={{maxHeight:'23.5rem',overflowY: 'auto'}}>
  <h1 className='congratulationNew mt-3 fw-bold fs-1 pt-3'>Choose Date and time slot to book Custom Planning</h1><br/>
  {/* <h3 className=' congratulationNew text-center mt-1 fw-bold fs-3'>Your Payment is Sucessfull</h3> */}
 
   {plannerBooking == undefined || plannerBooking == null || plannerBooking == "" || plannerBooking.length == 0 
     ? (
      <>
      <h5 className='mt-2 fw-800 fs-4'>Sorry for the inconvenience.</h5>
      <h5 className='mt-2 fw-800 fs-4'>There are no Custom Planning slots available currently, please click on "No preferred time slots available"
                  so that one of our team members will contact you as soon as possible. </h5><br/><br/><br/></>
     ):(
      <>
      <div className='row ms-2 ms-sm-0 gap-3 align-items-center justify-content-center w-100 p-2 '>

      {
        plannerBooking.map((item, index) => {
          return (
            <>
            
              <div key={index} onClick={() => selectedTimeSlotObj(index)}
              
                className={`gray-border rounded ${(selectedTimeSlotIndex === index) ? ' seletectedIndexBackground px-0 p-2 vertical-scroll' : 'border border-dark p-2 vertical-scroll'}p-2 w-100 text-center  cursor-pointer`} style={{ maxWidth: '220px' }}
              >
                 {dayFormate(item.plannerDate)}, {item.plannerDate} {item.plannerTime}
              </div>
            </>
          )
        })
      }
    </div>
    <div className='btm text-center mt-5 pb-4'>
    
    <button type='text' onClick={postBookSumbit} className='theme-btn rounded text-center p-2 '>
      BOOK NOW
    </button>

  </div>
  </>
      )
   
   
   }
  {/* <div className='row ms-2 ms-sm-0 gap-3 align-items-center justify-content-center w-100 p-2'>

    <h5 className='mt-2 fw-800 fs-4'>Choose Date and time slot to book planner</h5>
    {
      plannerBooking.map((item, index) => {
        return (
          <>
          
            <div key={index} onClick={() => selectedTimeSlotObj(index)}
            
              className={`${(selectedTimeSlotIndex === index) ? 'seletectedIndexBackground px-0 p-2' : 'border border-dark p-2'}   w-100 rounded text-center cursor-pointer`} style={{ width: '100%', maxWidth: '180px' }}
            >
              {item.plannerDate} {item.plannerTime}
            </div>
          </>
        )
      })
    }
  </div> */}
  {/* <div className='btm text-center mt-5 pb-4'>
    
    <button type='text' onClick={postBookSumbit} className='theme-btn rounded text-center p-2 '>
      BOOK NOW
    </button>

  </div> */}
  <div className='btm text-center mt-2 pb-4'>
                  <h5>No preferred time slots available? Please &nbsp;
                   <a className='fs-5 text-decoration-underline'style={{cursor: "pointer"}} onClick={contactMe} >
                      click here
                  </a></h5>
  </div>
</div>
</div>  
:

<div className='contenArea container-fluid  d-flex justify-content-center bgclr' style={{height: 'auto',flexDirection:"column"}}>

        <h4 className='mt-2'>Custom Planning Booking : Book a Custom Planning</h4>
<p className='fs-5'><b>Topic</b>: {seminarObj?.seminarTopic}</p>
<div className=' container bg-white' >
  <h1 className='congratulationNew text-center mt-3 fw-bold fs-1 pt-3'>Your Payment is Failed</h1>

  <div className='btm text-center mt-5 pb-4'>
    <button type='text'  className='theme-btn rounded text-center p-2' onClick={tryAgain}>
     Try Again
    </button>
   {/* <div className='col text-danger mt-2'>(or)</div>
  <div className='col text-danger mt-2'>Book Later</div> */}

  </div>

</div>
</div>
}
       
      </div>
    </LayoutComponent>
  )
}
