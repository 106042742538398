export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';


export const EnquiryRegistration = 1;
export const CounterWalkIn = 3; 
export const CounterCheckIn = 4;
export const CounterPlanner = 7;
export const PAID = 8;
export const UNPAID = 9;
export const FollowUp = "FOLLOWUP";






///UserType
export const Registered =  'REGISTERED';
export const checkIn =  'CHECK_IN';
export const PlannerBooking =  'PLANNER_BOOKED';
export const plannerFeePaid =  'PLANNER_FEE_PAID';
export const plannerFeeUnpaid =  'PLANNER_FEE_UNPAID';