// import { Col, Input, Row } from 'antd'
import React, { useState, useEffect, useCallback,useContext} from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import LayoutComponent from '../LayoutComponent'
import "../ClassroomComponent/ClassroomRegistration.css"
import { Link } from 'react-router-dom';
import Commonregcomponent from './commonComponent/Commonregcomponent';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'react-bootstrap/InputGroup';
import commonLib from "../control/commonLib";
import { useParams, useHistory } from "react-router-dom";
import { UserContext } from "../../App"
import Services from '../network/Services'
import moment from 'moment';
import Notification from '../Notification';
import konsole from '../network/konsole';

// import { useHistory } from 'react-router-dom';



const Classroomregistration = (props) => {


    const { setLoader } = useContext(UserContext);
 
    const [seminarList, setSeminarList] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const { subtenantKey } = useParams();
    const [modelShow, setModalShow] = useState(false);
    const seminarObj = commonLib.getObjFromStorage("seminarObj");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const [error, setError] = useState("")
    const history = useHistory();
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
  
    const [showModal , setShowModal] = useState(false)

    const selectedUser = (props.location.state !== undefined) ? props.location.state : {};
    // //console.log("semt",error != "" ? error : "")

    // const { setLoader , setSubtenantLogo} = useContext(UserContext);
    const classRoomRegistration =(userId,seminarAttendeeId,seminarId,classroomId,createdBy)=>{
        let postData = Services.postClassRoomRegister(userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        postData.then((res) => {
    
       
          konsole.log("responseresponse", res.data.data)
          let classbookingId = res.data.data?.bookingId;
          commonLib.setDatatoSession("classbookingId", JSON.stringify(classbookingId))


    
        }).catch((err) => {
          konsole.log("errWW", err)
        })
      }
    

    
    const bookNow = (seminar, index) => {
        //console.log("seminar11",seminar)
        const ClassroomStatus = true
        const selectedUserObj = seminarList[index];
        //console.log("samplee",selectedUser)
        const classData = { seminar: selectedUser, selectedUserObj: selectedUserObj }
        commonLib.setDatatoSession("classroomStaus", JSON.stringify(classData))
        commonLib.setDatatoSession('calssroom', JSON.stringify(ClassroomStatus))

        const sentData = { selectedUser: selectedUser, seminar: seminar, selectedUserObj: selectedUserObj }
        commonLib.setDatatoSession('GetClassroom', JSON.stringify(sentData))
        // //console.log("ssd", sentData)
        classRoomRegistration(selectedUser?.seminarAttendeeUserId,selectedUser?.seminarAttendeeId,selectedUser?.seminarid,seminar?.seminarId,stateObj?.userId)
        if (seminar.noOfSeats != seminar.noOfRegisteredSeats) {
            history.push({
                pathname: '/classroomvenupayment',
                state: sentData
            })
        }
        else{
            setWarning("No Seats Available")
            setColor1("#ffcc00")
            setShowModal(true)
            
        }


    }

    useEffect(() => {
        if (isMounted) {
            // //console.log("subtenantKey", subtenantKey);
            // commonLib.setSessionStoarge("subtenantId", subtenantKey);
            const isActive = true //for active subtenantId
            getSeminarPublish(745, true, "UPCOMING");
            // getSubtenantDetail(subtenantKey, isActive);
        }
        return () => {
            setIsMounted(false);
        }
    }, [])

    // const getSubtenantDetail = (subtenantId, isActive) => {
    //     setLoader(true);
    //     const promises = Services.getSubtenantDetail(subtenantId, isActive);
    //     promises.then(res => {
    //         setLoader(false);
    //         konsole.log("subtenant detail", res);
    //         const eventListingSubtenantResponse = res.data.data[0];
    //         konsole.log("eventListing", eventListingSubtenantResponse);
    //         const seminarEventObj = commonLib.getEventListSubtenantObj(eventListingSubtenantResponse);
    //         //console.log("seminarObj1", seminarEventObj);
    //         setSubtenantLogo(eventListingSubtenantResponse.subtenantLogoUrl);
    //         eventListingSubtenantResponse.logoUrl = eventListingSubtenantResponse.subtenantLogoUrl;
    //         commonLib.setSessionStoarge('seminarObj', JSON.stringify(eventListingSubtenantResponse));
    //     }).catch(err => {
    //         konsole.log("error", err.response);
    //     }).finally(() => {
    //         konsole.log("fin");
    //         setLoader(false);
    //     })
    // }
    const hideModal = () => {
        setModalShow(false);
    }

    const getSeminarPublish = useCallback((subtenantId1, isActive, seminarType) => {

        const promise = Services.getClassroomPublisherDetail(subtenantId1, isActive, seminarType);
        //console.log("ssd", subtenantId1, true, "UPCOMING")
        setLoader(true);
        promise.then(response => {
            setLoader(false)
            //console.log("DATA", response.data.data)
            setSeminarList(response.data.data);
            hideModal();
        }).catch((e) => {
            
            if(e.response.data.message === "No Data Found"){
                setError(e.response.data.message);
                setLoader(false);
            }
            else{
                setLoader(false);
                konsole.log(e)

            }
            
            
            
            // alert(e.response.data.message)
        }).finally(() => {
            // setLoader(false);
        });
    }, [])

    const readMoreFunction = (seminar, index) => {
        const readmoreStaus = false
        commonLib.setDatatoSession('readcalssroom', JSON.stringify(readmoreStaus))
        const ClassroomStatus = true
        const selectedUserObj = seminarList[index];
        const classData = { seminar: selectedUser, selectedUserObj: selectedUserObj }
        const sentData = { selectedUser: selectedUser, seminar: seminar, selectedUserObj: selectedUserObj }


        history.push({

            pathname: 'readmoreclassroom',
            state: sentData


        })

    }

    const data1 = (seminar, toDate) => {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var d = new Date(seminar);
        var dayName = days[d.getDay()];
        var d2 = new Date(toDate);
        var dayName2 = days[d2.getDay()];
        return "(" + dayName + "-" + dayName2 + ")"
    }

    return (
        <>
            { }
            <LayoutComponent>
            <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

                <Row style={{}}>

                    <Col span={24} className='ps-3 pt-2'>
                        <Commonregcomponent text="Select a Workshop" topic={seminarObj?.seminarTopic} />
                        {error === "" ? (
                            <Row span={24} className="ps-2 pb-2 bg-white" >

                            <Col span={24}>
                                <Row className='mt-2' style={{ paddingLeft: "13px" }}>
                                    <Col span={24} className="row d-flex justify-content-start gap-2 flex-row flex-nowrap overflow-auto Card-Col vertical-scroll">

                                        {

                                            seminarList.length > 0 && seminarList.map((seminar, index) => {

                                                const noOfRegisteredSeats = seminar.noOfRegisteredSeats;
                                                const noOfSeats = seminar.noOfSeats;
                                                const toDate = seminar.remarks.slice(0, 11);
                                                const toTime = seminar.remarks.slice(11, 31);
                                                const weekDay = seminar.remarks.slice(20, 40)
                                                const opacity = (noOfRegisteredSeats >= noOfSeats) ? { opacity: '.12' } : {};

                                                return (
                                                    <>

                                                        <Card className='m-0 p-0 Card-Style mb-1' >
                                                            <div className='p-1' >

                                                                <Card.Img variant="top" src="data.jpg" />
                                                            </div>
                                                            <Card.Body className='m-0 pt-1 ps-1 px-1' >

                                                                <div>
                                                                    <h6 className='fw-bold profTopic-Tag-H6' onClick={() => { readMoreFunction(seminar, index) }}>{seminar.seminarTopic}<span className='profTopic-Tag-span' >&nbsp;({seminar.seminarType})</span></h6>
                                                                </div>

                                                                <Card.Text>
                                                                    <div className=''>
                                                                        <h6 className='by-tag-h6' >By {""}

                                                                            <span className='profName-tag-span'  >{seminar?.speakers[0].fName + " " + seminar?.speakers[0].lName}</span>

                                                                        </h6>
                                                                        <h6 className='common-tag-h6' >
                                                                            Date :{commonLib.getFormattedDate(seminar?.seminarDate)} To {commonLib.getFormattedDate(toDate)} {weekDay == 1 ? "(Sat-Sun)" : data1(seminar?.seminarDate, toDate, weekDay)}
                                                                        </h6>
                                                                        <h6 className='common-tag-h6'>
                                                                            Time :  {commonLib.getFormattedTime(seminar?.seminarTime)} To {commonLib.getFormattedTime(toTime)}
                                                                        </h6>
                                                                        <h6 className='common-tag-h6'>
                                                                            Fee : ${seminar.seminarFee}
                                                                        </h6>
                                                                        <div className='d-flex justify-content-start' >
                                                                            <h6 className='common-tag-h6'>
                                                                                Location : {seminar.venueAddressLine1
                                                                                }
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </Card.Text>

                                                            </Card.Body>
                                                            <div className='d-flex justify-content-center mb-2'>

                                                                <button type="button" className="btn outline fw-bold BookNow-Button" onClick={() => { bookNow(seminar, index) }}>Book Now</button>

                                                            </div>
                                                        </Card>



                                                    </>
                                                )
                                            })}
                                    </Col>
                                </Row>

                            </Col>
                        </Row> 

                        ) : (

                            <div className='contenArea container-fluid  d-flex justify-content-center bgclr' style={{height: 'auto',flexDirection:"column"}}>
                                {/* <h1 className='fs-1 text-center CongratulationHeading fw-bold'>Congratulations</h1> */}
                                <h3 className='text-center CongratulationHeading fw-bold'>No Workshop Available </h3>
                            </div>
                        )}

                    </Col>
                </Row>
            </LayoutComponent>

        </>

    )
}

export default Classroomregistration