
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form'
import commonLib from '../control/commonLib';
import { CounterCheckIn, CounterWalkIn } from '../control/Constant';
import konsole from '../network/konsole';
import Services from '../network/Services';
import './Registration.css';
import { useHistory } from 'react-router-dom';
import LayoutComponent from "../LayoutComponent";
import { UserContext } from '../../App';
import BulkRegistration from '../Bulk/BulkRegistration';
import PhoneNumberMask from '../PhoneNumberMask';
import Address from './Address';
import moment from "moment";
import Notification from '../Notification';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import occurrenceId from '../control/occurrence';



const Registration = (props) => {

  const onsubmitreg = useRef('')
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const Location = commonLib.getObjFromStorage("Location");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [clientIpAddress, setClientIpAddress] = useState('');
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const [addressreg, setaddressreg] = useState('')
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const [referenceModeList, setReferenceMode] = useState([]);
  const [contactnum, setcontactnum] = useState();
  const [phonemsg, setphonemsg] = useState("")
  const [updateAttendees, setUpdateAttendees] = useState()
  const [valid, setvalid] = useState("")
  const seminarObj1 = commonLib.getObjFromStorage("seminarList2");
  const selectedUser = (props.location.state !== undefined) ? props.location.state : {};
  const [checkinyesno, setcheckinyesno] = useState('')
  const [showmodal, setshowmodal] = useState(false)
  const [statusReferenceId, setStatusReferenceId] = useState();
  const [attendees, setattendees] = useState()
  const [updateAddress, setUpdateAddress] = useState()
  const [updateAddressStatus, setUpdateAddressStatus] = useState([])
  const [emailDisable, setEmailDisable] = useState(false)
  const [contactDetailsState, setContactDetailsState] = useState([])
  const [contactDetailsUserIdState, setContactDetailsUserIdState] = useState("")
  const referenceRef = useRef(null);
  const subtenantId = props.location.state?.counterData?.subtenantId
  const seminarId = props.location.state?.counterData?.seminarId
  const { register, handleSubmit, setError, watch, setValue, clearErrors, formState: { errors } } = useForm()
  const phoneNo = watch("phoneNumber")
  const [showModal22 , setShowModal22] = useState(false)
  const [showModal1 , setShowModal1] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [userRegister1, setuserRegister1] = useState();
  const [address1, setaaddress] = useState()
  
  //console.log("seets1",attendees)

  const add3Hours1 = (time) => {
  
    let newTime = new Date(time);
    newTime.setHours(newTime.getHours() + 3);
    return newTime;
  }
  // let endingTime = add3Hours1(dateTime);
  
  const add3Hours2 = (time) => {
    //console.log("kl",time)
    let newTime = new Date(time);
    newTime.setHours(newTime.getHours() - 3);
    return newTime;
  }
 
  const onclickcheckifyesno = (data) => {
    //console.log("datadata1", data)
    commonLib.setDatatoSession("selfCheckData", JSON.stringify(data))
    referenceRef.current = data;
    //console.log("semem",userRegister1)
     if(phoneNo.length === 10 && selectedUser?.counterData?.noOfSeats > selectedUser?.counterData?.noOfRegisteredSeats){
    let  totSheats = selectedUser?.counterData?.noOfSeats - selectedUser?.counterData?.noOfRegisteredSeats;
      if (totSheats >= parseInt(userRegister1.noOfAttendee)) {
      registerNewUser(seminarObj.subtenantId, seminarObj.seminarId, userRegister1,data);
     }
     else{
      // let message1 = `Please upload a valid ${difference.map((ele)=> ele)}`
      setWarning(`Only ${totSheats}are left`)
      setColor1("#ffcc00")
      setShowModal1(true)

      // alert("Only"+totSheats+"are lefts")
     }
      
      konsole.log("registerData",data);
    }
    else{
      
      if(userRegister1.phoneNumber < 10){
      setphonemsg("Please Enter The Valid Cell Number")
      }
      else{
        setWarning("NO sheets left")
        setColor1("#ffcc00")
        setShowModal1(true)
        // alert("NO sheets left")
      }
      
    }


    setshowmodal(false)
    
  }

  useEffect(() => {

    getIpAddress();
    getCountryCodeList();
    getReferenceMode();
   
  }, [])

  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("IP address", res);
      setClientIpAddress(res.IPv4);
    })

  }
  useEffect(() => {

    if (props.location.state.UpdateData !== null && props.location.state.UpdateData !== undefined && props.location.state.UpdateData !== "") {
      setUpdateAttendees(props.location.state)
      konsole.log("props.location.state.UpdateData",props.location.state)
      let cellNumber = props.location?.state?.mobileno.split("")
      let onlyCellNumber = cellNumber.splice(cellNumber.length - 10, 10).join("")
      // setcontactnum(props.location?.state?.mobileno)
      setcontactnum(onlyCellNumber)
      setStatusReferenceId(parseInt(props.location?.state?.leadFrom))
      setEmailDisable(true)
      getAddressAPI(props.location?.state?.attendeeUserId)
      getContactDetails(props.location?.state?.attendeeUserId)
      setValue("firstName", props.location?.state?.fName)
      setValue("lastName", props.location?.state?.lName)
      setValue("emailAddress", props.location?.state?.emailId)
      setValue("noOfAttendee", parseInt(props.location?.state?.userGuestsNos))
      setValue("referenceMode", parseInt(props.location.state?.leadFrom))
    }

  }, [props.location.state])

  const getAddressAPI = (userID) => {
    let promise = Services.getaddressbyuserid(userID)
    promise.then((response) => {
      konsole.log("getaddressbyuserid", response.data.data)
      konsole.log("getaddressbyuserid", response.data.data?.addresses[0]?.addressLine1)
      setUpdateAddress(response.data.data?.addresses[0]?.addressLine1)
      setUpdateAddressStatus(response.data.data?.addresses)
    }).catch((error) => {
      konsole.log("getaddressbyuseridError", error)
    }).finally(() => {

    })
  }
  const getContactDetails = (userId) => {
    let promise = Services.getContactDetailsByUserId(userId)
    promise.then((response) => {
      konsole.log("getContactDetailsByUserId", response.data.data?.userId)
      setContactDetailsUserIdState(response.data.data?.userId)
      setContactDetailsState(response.data.data?.contact?.mobiles)
    }).catch((error) => {
      konsole.log("getContactDetailsByUserIdError", error)
    }).finally(() => {

      konsole.log("getContactDetailsByUserIdEnd")
    })
  }
  const getCountryCodeList = () => {
    const promises = Services.getCountryCodeList();
    promises.then(res => {
      konsole.log('countryCodeList', res);
      setCountryCodeList(res.data.data)
    })
      .catch(err => {
        konsole.log("err", err);
      }).finally(fin => {
        konsole.log("finish");
      })
  }
  const getReferenceMode = () => {
    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
    })
  }
  const onSubmit = data => {
    if (props.location.state.UpdateData !== null && props.location.state.UpdateData !== undefined && props.location.state.UpdateData !== "") {
      konsole.log("onSubitDatadtata", data)
      let userIDFromSession = commonLib.getObjFromStorage("stateObj").userId;
      let seminarObj = commonLib.getObjFromStorage("seminarObj")?.seminarId;
      //   konsole.log("onSubitDatadtata", userIDFromSession )
      if ((data.lastName !== props.location?.state?.lName) || props.location?.state?.fName !== data.firstName) {
        setLoader(true)
        let promise = Services.putMemberDetails(2, data.firstName, data.lastName, props.location?.state?.attendeeUserId, props.location?.state?.attendeeUserId)
        promise.then((response) => {
          konsole.log("putMemberDetails", response)
          setLoader(false)
          history.push('/Counter')
        }).catch((error) => {
          konsole.log("putMemberDetailsError", error)
          setLoader(false)
        }).finally(() => {
          konsole.log("putMemberDetailsEnd")
          setLoader(false)
        })
      }

      let addCountryCode = contactnum.split("")
      addCountryCode.unshift('+1')
      let AddedCountryCode = addCountryCode.join("")

      konsole.log("contactnumcontactnumcontactnum", AddedCountryCode, addCountryCode)


      if (props.location.state?.mobileno !== AddedCountryCode) {

        konsole.log("eeeeettttttteeeeeeettttt", props.location.state?.mobileno, AddedCountryCode)



        setLoader(true)
        let promise = Services.putContactDetails(contactDetailsUserIdState, 1, contactDetailsState[0]?.contactTypeId, AddedCountryCode, contactDetailsState[0]?.contactId, userIDFromSession)
        promise.then((res) => {
          konsole.log("putContactDetails", res)
          setLoader(false)
          history.push('/Counter')
        }).catch((err) => {
          konsole.log("putContactDetailserr", err)
          setLoader(false)

        }).finally(() => {
          setLoader(false)

        })

      }

      if (props.location?.state?.userGuestsNos !== data.noOfAttendee || props.location.state?.leadFrom !== data.referenceMode) {


        setLoader(true)
        let promise = Services.upsertSeminarAttendeesDetails(seminarObj, props.location.state?.seminarAttendeeId, props.location.state?.attendeeUserId, props.location.state?.semUserStatusId, parseInt(data.noOfAttendee), parseInt(data.referenceMode), true, userIDFromSession)
        promise.then((res) => {
          konsole.log("upsertSeminarAttendeesDetails", res)
          setLoader(false)
          history.push('/Counter')
        }).catch((err) => {
          setLoader(false)
          konsole.log("upsertSeminarAttendeesDetailsErr", err)

        }).finally(() => {
          setLoader(false)
          konsole.log("upsertSeminarAttendeesDetailsEnd")

        })

      }

      if (addressreg !== null && addressreg !== undefined && addressreg !== "" && updateAddress !== undefined) {
        setLoader(true)
        let promise = Services.putAddressDetails(addressreg?.address, addressreg?.zipcode, addressreg?.city, addressreg?.state, addressreg?.country, updateAddressStatus[0]?.addressTypeId, updateAddressStatus[0]?.addressId, userIDFromSession, true)
          .then((response) => {
            konsole.log("putAddressDetails", response)
            setLoader(false)
            history.push('/Counter')
          }).catch((error) => {
            setLoader(false)
            konsole.log("putAddressDetailsError", error)

          }).finally(() => {
            setLoader(false)
            konsole.log("putAddressDetailsEnd")

          })
      }else{
        const loggeduserId = JSON.parse(commonLib.getSessionStoarge('stateObj'))?.userId
        let jsonforaddress = {
          "userId": props.location.state?.attendeeUserId,
          "address": {
            "lattitude": "",
            "longitude": "",
            "addressLine1": addressreg?.address,
            "addressLine2": "",
            "addressLine3": "",
            "zipcode": addressreg?.zipcode,
            "city": addressreg?.city,
            "state": addressreg?.state,
            "country": addressreg?.country,
            "addressTypeId": 1,
            "createdBy": loggeduserId
          }
        }

       
        postAddressApiFunc(jsonforaddress)
      }
      if(props.location?.state?.emailId !== data?.emailAddress){
        setLoader(true)
        // //console.log("props.location?.state?",props.location?.state)
        let promise = Services.putEmailDetails(props.location?.state?.emailId,data?.emailAddress,userIDFromSession,"EmailUpdate",clientIpAddress)
        .then((response) => {
          konsole.log("putAddressDetails11", response)
          setLoader(false)
          history.push('/Counter')
        }).catch((error) => {
          setLoader(false)
          konsole.log("putAddressDetailsError1111", error)
          if(error?.response?.data?.errorFlag == "AUTH_USER" || error?.response?.data?.errorFlag == "INVALID_USERNAME" ){
          //   setWarning("New User Email details cannot be edited since the user is already associated with us.")
          // setColor1("#ffcc00")
          // setShowModal1(true)
             alert("New User Email details cannot be edited since the user is already associated with us.")
          }
          else if(error?.response?.data?.errorFlag == "ALREADY_USER"){

            alert("New email id which you put is already  exist with us")
          }

        }).finally(() => {
          setLoader(false)
          konsole.log("putAddressDetailsEnd11")

        })
        
      
    // alert("hy")
      }


    } 
    else {
      let userRegistration = data;
      userRegistration['roleId'] = 2;
      userRegistration['noOfAttendee'] = parseInt(userRegistration.noOfAttendee);
      userRegistration['referenceMode'] = parseInt(userRegistration.referenceMode);
      userRegistration['clientIPAddress'] = clientIpAddress;
      userRegistration['countryCode'] = '+1';
      userRegistration['semUserStatusId'] = CounterWalkIn;
      konsole.log("userRegistration", userRegistration)
      if(userRegistration?.phoneNumber.length === 10){
        setshowmodal(true)   
        setuserRegister1(userRegistration)
        setphonemsg("")
  }
        else{
          setphonemsg("Please Enter The Cell Number")
        }

     
    }
  }

  const postAddressApiFunc = (jsonforaddress) =>{
    konsole.log("jsonforaddress",jsonforaddress)
    let promise = Services.postaddressbyuserid(jsonforaddress)
    promise.then((res) => {
      konsole.log("postAddressApiFuncres", res)
    }).catch((error)=>{
      konsole.log("postAddressApiFuncerr",error)
    }).finally(()=>{
      konsole.log("postAddressApiFuncend")
    })

  }
  const registerNewUser = (subtenantId, seminarId, userRegistration,data) => {
    let allData;
    konsole.log("rrgis", subtenantId, seminarId, userRegistration)
    setLoader(true);
    const promise = Services.registerNewUser(subtenantId, seminarId, userRegistration)
    konsole.log("postuserRegistration", subtenantId, "::", seminarId, "::", userRegistration)
    
    promise.then(res => {
      setLoader(false);
      konsole.log('register user1', res);
      let post = res.data.data
      konsole.log('registersadasdasdasdasdad', post);
      
      
      allData = {
        emailId: userRegistration?.emailAddress,
        mobileno: `${userRegistration?.countryCode}${userRegistration?.phoneNumber}`,
        seminarAttendeeId: post?.seminarAttendeeId
      }


      const CounterWalkInRes = { data1: post, data2: seminarObj?.seminarFee, userDetails: subtenantId, seminarId, userRegistration };
      // konsole.log("samehere",post)
      commonLib.setDatatoSession("GetResponse", JSON.stringify(CounterWalkInRes))
      konsole.log("seminarpaid", seminarObj.isSeminarPaid)

      if (addressreg !== '' && addressreg !== undefined && addressreg !== null) {
        const loggeduserId = JSON.parse(commonLib.getSessionStoarge('stateObj'))?.userId
        let jsonforaddress = {
          "userId": res.data.data.userId,
          "address": {
            "lattitude": "",
            "longitude": "",
            "addressLine1": addressreg?.address,
            "addressLine2": "",
            "addressLine3": "",
            "zipcode": addressreg?.zipcode,
            "city": addressreg?.city,
            "state": addressreg?.state,
            "country": addressreg?.country,
            "addressTypeId": 1,
            "createdBy": loggeduserId
          }
        }

        let promise = Services.postaddressbyuserid(jsonforaddress)
        promise.then((res) => {
          konsole.log("resressaddress", res)
          post["address"] = res?.data?.data?.addresses[0]?.addressLine1
          //console.log("possssst",post)
          // setaaddress(res?.data?.data?.addresses[0]?.addressLine1)

          afternewregresponse(seminarObj, CounterWalkInRes, post, CounterCheckIn,data, allData)

        }).catch((err) => {
          konsole.log("errr", err)
        })

      } else {
        afternewregresponse(seminarObj, CounterWalkInRes, post, CounterCheckIn,data, allData)

      }


    })
      .catch(err => {
        konsole.log("errfd", err);
        // konsole.log("sample",err)
        // alert(err.response.data.Messages[0])
        setWarning(err.response.data.Messages[0])
          setColor1("#FA113D")
          setShowModal1(true)
        setLoader(false)


      }).finally(fin => {
        konsole.log("finish", fin);
        setLoader(false);
      })
  }
  const afternewregresponse = (seminarObj, CounterWalkInRes, post, CounterCheckIn,data, allData) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    if (seminarObj.isSeminarPaid == true) {

      konsole.log("INSIDE TRUE seminarpaid", seminarObj.isSeminarPaid)
      // occurrenceId.callOccurrenceIdFunc(subtenantId, allData)
      history.push({
        pathname: '/venuePayment',
        state: CounterWalkInRes

      })


    }
    else {
      //console.log("dataaa",data)
      if (data === "Yes") {
        // alert("yes")
        let date1 = moment(seminarObj?.seminarDate).format('YYYY-MM-DD')
        let time1 = moment(seminarObj?.seminarTime, "HH:mm:SS").format("h:mm A")
        let momentObj = moment(date1 + time1, 'YYYY-MM-DDLT');
        let dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');
        let currentTime = new Date();  
        let endingTime = add3Hours1(dateTime);
        let startTime = add3Hours2(dateTime);
        if( currentTime < startTime ){
      // alert("Check-in for attendees is enabled 3 hours before the seminar time.")
     
          setWarning("Check-in for attendees is enabled 3 hours before the seminar time.")
          setColor1("#ffcc00")
          setShowModal1(true)
          setShowModal22(true)

         
      // alert("Check-in for attendees is enabled 3 hours before the seminar time.")
      setTimeout(() => {
        history.push({
          pathname: '/Counter',
          // state: CounterWalkInRes
  
        })
        }, 1000)
    
      
         }
         else{
          // occurrenceId.callOccurrenceIdFunc(2,subtenantId, allData)
        handleWalkInCheckInUser(post.seminarId, post.seminarAttendeeId, post.userId, CounterCheckIn, post.userId, allData)
         }
      } else {
        // alert("3")
        // occurrenceId.callOccurrenceIdFunc(1,subtenantId, allData)
        history.push({
          pathname: '/Congratulations',

        })

      }

    }

  }
 
  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy, allData) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    konsole.log("dummy", seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy)
    promises.then(res => {
      setLoader(false);
      konsole.log('registered Checkin res', res);
      const selfWalkInCheckInObj = res.data.data.attendees[0];
      const bookedSeats = { data1: selfWalkInCheckInObj.allottedSeats, data2: seminarObj };



      if (seminarObj.isSeminarPaid == true) {
        // konsole.log("dbs1",seminarObj.isSeminarPaid)
        let data1 = { jsonData: res.data.data, json2: seminarObj?.seminarFee }
        // occurrenceId.callOccurrenceIdFunc(subtenantId, allData)
        history.push({
          pathname: '/CounterPlanner/venuePayment',
          state: data1,

        })
      }
      else if (seminarObj.isSeminarPaid == false) {
        // konsole.log("dbs2",seminarObj.isSeminarPaid)
        // occurrenceId.callOccurrenceIdFunc(subtenantId, allData)
        history.push({
          pathname: '/Congratulations',
          state: bookedSeats
        })

      }

    })
      .catch(err => {
        // if(err.response.data.messages[0] === "NOT_ENOUGH_SEATS"){
        // Toaster.error(err.response.data.messages[0])
        // alert(err.response.data.messages[0])
        setWarning(err.response.data.messages[0])
        setColor1("#FA113D")
        setShowModal1(true)
        // }
        konsole.log("errt", err.response.data.messages);

      }).finally(fin => {
        setLoader(false);
        konsole.log("finish");
      })
  }

  const onchangeContact = (value) => {
    setcontactnum(value)
  };
  const registeroptions = {
    firstName: {
      required: "Please enter First name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter valid First name"
      }
    },
    lastName: {
      // required: "Please enter Last name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter valid Last name"
      }
    },
    emailAddress: {
      required: "Please enter Email address",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter valid Email addreess',
      }
    },
    phoneNumber: {
      required: "Please enter Mobile number",
      pattern: {
        value: /(\d{0,3})(\d{0,3})(\d{0,4})/,
        message: "Please enter valid  Mobile number"
      },
      validate: {
        ValueNull: (value) => value !== "",
        NumberExcess: (value) => value.length === 10
      }

    }
    ,

    noOfAttendee: {
    required:  "Please enter No of attendence",

      pattern: {
        // value:/^[0-9+-]+$/,
        value: /^[1-9]/,
        message: "Please enter atleast one attendence."
      },
      // minLength: {
      //   vlaue: 1,
      //   message: "Please enter No of attendence"
      // }

    },


  }
  
  const back = () => {
    history.push({
      pathname: '/Counter',


    })
  }
  const showcheckinmodal = () => {
    setshowmodal(true)

  }
  const referenceStatusId = (e) => {
    konsole.log("referenceStatusIdreferenceStatusId", e.target.value)
    setStatusReferenceId(e.target.value)

  }
  const referenceStatusId1 = (e) => {
    //  handleSubmit()
    //  register('noOfAttendee',registeroptions.noOfAttendee)
    setError("noOfAttendee","")
    konsole.log("referenceStatusIdreferenceStatusId", e.target.value)
    setattendees(e.target.value)

  }




  return (
    <>
      <LayoutComponent>
      <Notification showModal={showModal1} message={warning} color={Color1} setShowModal={setShowModal1}/>
        <div className='container-fluid'>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className=' mt-4 p-0' >
              <h3 >Seminar<b>:{" "}
                {
                  (updateAttendees !== null && updateAttendees !== undefined && updateAttendees !== "") ? "Update Attendee Details" : "New Registration"
                }
              </b></h3>
              <p className='fs-5'>Topic : {seminarObj?.seminarTopic}(


                {seminarObj?.isSeminarPaid == true ? "Paid" : "Free"}
                {seminarObj?.isSeminarPaid == true ? ": $" + seminarObj?.seminarFee : ""}

                )</p>
            </div>
            {/* <div className='col-2'></div>  */}

            <div className='p-0'>
            <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>
              {/* <h5>Venue: {Location?.seminarVenue}</h5> */}
              {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
              <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                  {/* Location: {venueObj?.venueAddressLine1} */}
                  </p>

              <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
                {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
            </div>
          </div>

          <form className='container-fluid  bg-white' onSubmit={onSubmit}>

            <div className='row mb-3 pt-3'>
              <div className='col-md-6  form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">{commonLib.mandatory("First Name")}</label>
                <input type="text" className="form-control bg-light fs-6" defaultValue={updateAttendees?.fName} id="firstName" style={{ textTransform: "capitalize" }}
                  placeholder="Enter First Name" {...register("firstName", registeroptions.firstName)}></input>
                {/* {errfirstName ? <span className="text-dark">Only Character Enter</span> : null}  */}
                {/* {errors.firstName && <span className="RegistrationValidationErrPrint">First Name is Required</span>}  */}

                {<p className="ps-3 text-danger">{errors?.firstName && errors.firstName.message}</p>}
              </div>

              <div className='col-md-6 form-group'>

                <label for="formGroupExampleInput" className="form-label label-name-color  fs-5">Last Name</label>
                <input type="text" className="form-control bg-light fs-6" id="lastName" defaultValue={updateAttendees?.lName} style={{ textTransform: "capitalize" }} placeholder=" Enter Last Name" {...register('lastName', registeroptions.lastName)}></input>

                {<p className="ps-3 text-danger">{errors?.lastName && errors.lastName.message}</p>}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6'>
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">{commonLib.mandatory("Cell Number")}</label>
                <div className='row gap-4 gap-md-0'>
                  {/* <div className='col-md-3 m-0'>
                    <select className=" form-select p-1 bg-light fs-7 selectpicker bg-light dropdown-item  border  fs-6" {...register('countryCode', { required: true })} placeholder="Code">
                      {
                        countryCodeList.map((code, index) => {
                          return <option value={code.value}>{code.label}</option>
                        })
                      }
                    </select>
                  </div> */}
                  <div className='col-md-12 m-0'>
                    <PhoneNumberMask id="phoneNumber" value={contactnum} onValueChange={onchangeContact} setvalid={setvalid}
                      setError={setError} setClearError={clearErrors} setValue={setValue} setphonemsg = {setphonemsg}

                    />
                    {errors.phoneNo && errors.phoneNo?.type == "error" && (<p className='ps-3 text-danger'>{errors.phoneNo.message}</p>)}
                    <p className="ps-3 text-danger" >{phonemsg}</p>
                    {/* <input type="tel" className="form-control bg-light fs-6" id="phoneNumber"  maxlength="10" placeholder="Enter Phone Number" pattern="[0-9]*"  {...register('phoneNumber', registeroptions.phoneNumber)} 
                    
                    ></input> */}
                  </div>

                </div>
                {props.value?.length == 0 ? <p className=" text-danger">{props.errors?.phoneNumber && props.errors.phoneNumber.message}</p> : ""}
                {<p className="ps-3 text-danger">{contactnum?.length == 0 ? "" : contactnum?.length < 10 ? "Please enter the valid Cell Number" : ""}</p>}

                {/* <p className="ps-3 text-danger">{errors?.phoneNumber && errors.phoneNumber.message}</p> */}
              </div>
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">{commonLib.mandatory("Email")}  </label>
                <input type="text" className="form-control bg-light fs-6"  defaultValue={updateAttendees?.emailId} id="emailId" placeholder="Enter Email "
                  {...register('emailAddress', registeroptions.emailAddress)} ></input>
                {<p className="ps-3 text-danger">{errors?.emailAddress && errors.emailAddress.message}</p>}
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">{commonLib.mandatory("No. of Attendees")}</label>
                {/* <input type="number" pattern="^[1-9]*$" className="form-control bg-light fs-6" defaultValue={updateAttendees?.userGuestsNos} id="noOfAttendee" placeholder="Enter No. of Attendees" min={0} {...register('noOfAttendee', registeroptions.noOfAttendee)}></input> */}
                <select className=" form-select p-1 bg-light fs-7 selectpicker  bg-light dropdown-item  border  fs-6" {...register('noOfAttendee',registeroptions.noOfAttendee)} defaultValue={updateAttendees?.userGuestsNos} value={attendees} onChange={referenceStatusId1} placeholder="Select No. of Attendees">
                  <option value="">Select No. of Attendees</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  {/* {
                    referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                      return <option value={reference.value}>{reference.label}</option>
                    })
                  } */}
                </select>
                {<p className="ps-3 text-danger">{errors?.noOfAttendee && errors.noOfAttendee.message}</p>}
              </div>

              {/* <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">Lead From</label>
                <input type="text" className="form-control bg-light fs-6" id="leadFrom" placeholder=" Enter Lead From" {...register('leadFrom', registeroptions.leadFrom)}></input>

                {<p className="ps-3 text-danger">{errors?.leadFrom && errors.leadFrom.message}</p>}
              </div> */}

              {/* <div className='row mb-2'>  */}
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">How did you hear about us?</label>
                <select className=" form-select p-1 bg-light fs-7 selectpicker  bg-light dropdown-item  border  fs-6" {...register('referenceMode',)} defaultValue={parseInt(updateAttendees?.leadFrom)} value={statusReferenceId} onChange={referenceStatusId} placeholder="How did you hear about us?">
                  <option value="">How did you hear about us?</option>
                  {
                    referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                      return <option value={reference.value}>{reference.label}</option>
                    })
                  }
                </select>
                {errors.title && <span className="ps-3 label-name-color">Required*</span>}
              </div>

              {/* </div>  */}


            </div>
            {/* <div className='row mb-2'>
              <div className='col-md-12 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">How did you hear about us?</label>
                <select className=" form-select p-1 bg-light fs-7 selectpicker height-20px bg-light dropdown-item  border  fs-6" {...register('referenceMode', { required: true })} placeholder="How did you hear about us?">
                  <option value="">How did you hear about us?</option>
                  {
                    referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                      return <option value={reference.value}>{reference.label}</option>
                    })
                  }
                </select>
                {errors.title && <span className="ps-3 label-name-color">Required*</span>}
              </div>

            </div> */}
            <Address setaddressreg={setaddressreg} updateAddress={updateAddress} />
            <div className='row pb-4 justify-content-between'>
              <div className='col-6 d-flex' >
                <button className='btn  p-2 mt-2 float-end rounded roundBtn' style={{ width: "130px", border: '2px solid #720c20', background: "white", color: "#720c20" }} type='button' id='btnBack' onClick={back} >Back </button>








              </div>

              {/* <BulkRegistration subtenantId={subtenantId} seminarId={seminarId} /> */}

              <div className='col-3' >
                {

                  updateAttendees !== null && updateAttendees !== undefined && updateAttendees !== "" ?
                    <>
                      <button className='btn   text-white  w-100 p-2 mt-2 float-end rounded' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' ref={onsubmitreg} onClick={handleSubmit(onSubmit)} >Update Details</button>
                    </> :
                    <>
                     <button className='btn  text-white  w-100 p-2 mt-2 float-end rounded' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' onClick={handleSubmit(onSubmit)} >Register Now</button>

                    </>
                }


              </div>


            </div>

          </form>
        </div>
      </LayoutComponent>




      <Modal show={showmodal} >
        <Modal.Header className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >Do you want to auto check-in the user</Modal.Body>
        <Modal.Footer className="border-0" >
          <Button className='w-25 ' style={{ backgroundColor: '#d3d3d3', color: "#720C20" }} onClick={() => onclickcheckifyesno('No')}>
            No
          </Button>
          <Button className="w-25" style={{ backgroundColor: '#720C20' }} onClick={() => onclickcheckifyesno('Yes')} >
            Yes
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Registration;