import React, { useContext, useEffect, useState } from 'react';
import commonLib from '../control/commonLib';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { UserContext } from '../../App';
import { Route,Redirect } from 'react-router-dom';


export default function RequireAuth(props) {
    const AuthToken = commonLib.getSessionStoarge("AuthToken");
    const authed = (AuthToken !== '' && AuthToken !== null)? true : false;
    const { setLoader } = useContext(UserContext)
    const [ validUser, setValidUser ] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const stateObj = commonLib.getObjFromStorage("stateObj");
            const promises = Services.getLoggedInUser(stateObj?.userId, stateObj?.appState, stateObj?.loggenInId, stateObj?.roleId)
            // setLoader(true);
            promises.then(response => {
                if (response) {

                    //console.log("abababababba",response)
                    // setLoader(false);
                    setValidUser(true);
                }
            }).catch((error)=> {
                setLoader(false);
                commonLib.unAuthurizeAccess();
            })
            .finally(()=>{
                setLoader(false);
            })
        }
        return () => {
            isMounted = false;
        }
    }, [])




    konsole.log("authed", authed, AuthToken) ;
    return (

<Route
      {...props}
      render={({ location }) => {

        //console.log("loca", location);
        return authed === true ? (
          props.children
        ) : <></>
      }}
    />

    )
            
     
}