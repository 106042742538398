import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import commonLib from '../control/commonLib';
import konsole from '../network/konsole';
import LayoutComponent from '../LayoutComponent';
import './VenuePayment.css';
import Services from '../network/Services';
import Payment from './Payment';
import Notification from '../Notification';


export default function VenuePayment(props) {
  const GetResponse = commonLib.getObjFromStorage('GetResponse');
  const selectedUser  = (props.location.state !== undefined) ? props.location.state : GetResponse;
  // console.log("num",selectedUser)
 
  const history = useHistory();
  const clickbuttonref = useRef()
  const [status, setStatus] = useState(0) // 0: no show, 1: show yes, 2: show no.
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('')
  const [orderId, setorderId] = useState('')
  const [showPayment, setshowPayment] = useState(false)
  const [showModal , setShowModal] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [showmodal1,setshowmodal1]=useState(false)
  
  const selectedSlot = commonLib.getObjFromStorage("timeSlot");
  // const [selectedUser , setSelectedUser ] = useState((props.location.state !== undefined) ? props.location.state : {})
  
  // konsole.log("attendeeObj",attendeeObj?.attendees ?"yes":"no") 
  // konsole.log("attendeeObj",attendeeObj?.attendees ? "yes":"no") 
konsole.log("selectedUsersemUserStatusId",showPayment)






  const handlePaymentMethod = (selectedPaymentMethod, method) => {
    konsole.log("selectedPaymentmethod", selectedPaymentMethod, method);
    setSelectedPaymentMethod(selectedPaymentMethod);
    setPaymentMethod(method)

  }

  // const handlePostPayment = () => {
  // if (selectedPaymentMethod == null) {
  // Toaster.warning("Please select payment method");
  // return;
  // }
  // history.push({
  // pathname: "/counterPlanner/AfterPaymentScreen",
  // state: selectedUser
  // });
  // }

  const [popsStatus, setPopsStatus] = useState()
  useEffect(()=>{
    if(popsStatus == false){
      setshowPayment(false)
    }else{
      setPopsStatus(true)

    }

  },[popsStatus])

  const handlepayment = () => {

    if (paymentMethod == '' || paymentMethod == null) {
      setWarning('Please select  payment method')
      setColor1("#ffcc00")
      setShowModal(true)
      // alert()
      
      return;
    } else {
    
      setshowPayment(true)
      setPopsStatus(true)
      // setTimeout(() => {
      //   setshowPayment(false)
      // }, 9000)
    }
  }



  return (
    <LayoutComponent>
    <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

      <div className=' container-fluid bgclr' >
        <div className='row'>
          <div className='col-sm-6 mt-5 '>
            {
              (selectedUser.data1?.semUserStatusId === 3 && 4)?
                <h4>Seminar Registration: New Registration<b> </b></h4>
                :
                <h4 >Custom Planning Booking :<b> Book a Custom Planning</b></h4>

            }
            <p className='fs-5'>Topic : {seminarObj?.seminarTopic}</p>
          </div>
          {/* {(selectedUser.data1?.semUserStatusId === 3 && 4) ?  "" : 
        <div className="col-sm-6 text-end pe-3">
              <p className="mt-4 me-4">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Date {":"}
                </span>{" "}
                {selectedSlot?.date}
              </p>
              <p className="ms-2">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Time Slot {":"}
                </span>{" "}
                {selectedSlot?.time}{" "}
              </p>
            </div>} */}
        </div>
        <div className='container-fluid d-flex justify-content-center align-items-center ' >
          <div className='col-sm-12 bg-white d-flex flex-column flex-md-row p-0 p-md-5' >
            <div className='col-xs-4 border-end'>
              <div className='ms-4 m-ms-md-0'>
                <dl className="user-Details-Div">
                  <h3><b>User Details</b></h3>
                  < div className="dt fs-5">First name : <span className="text-secondary">{ 
                  
                  selectedUser.data1?.semUserStatusId === 3 && 4 ? selectedUser.userRegistration?.firstName                  :
                  selectedUser?.attendeeFullName}</span> </div>
                  <dd id="lblName"></dd>
                  <div className="dt fs-5">Email : <span className='text-secondary'>{
                  selectedUser.data1?.semUserStatusId === 3 && 4 ? selectedUser.userRegistration?.emailAddress                  :
                  selectedUser?.attendeeEmailAddress}</span></div>
                  <dd id="lblId"></dd>
                  <div className="dt fs-5">Cell No : <span className='text-secondary'>{
                  selectedUser.data1?.semUserStatusId === 3 && 4 ? selectedUser.userRegistration?.phoneNumber :
                  selectedUser?.attendeeMobileNo}</span></div>
                  <dd id="lblId"></dd>
                  < div className="dt fs-5" >Price: <span className='text-secondary'>{selectedUser.data1?.semUserStatusId === 3 && 4 ? `$${selectedUser.data2}`:"$1"}</span></div>
                  <dd id="lblAmenities"></dd>
                </dl>
              </div>
            </div>
            <div className='col-12 col-md-6 ps-4' >
              <h4> Choose a Payment Method</h4>
              <div className='row'>
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(1, 'Card')}>
                  <div class="card">
                    <div class={`${(selectedPaymentMethod === 1) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <h5 class="card-title "><img src={`${(selectedPaymentMethod === 1) ? '/icons/creditActiveIcon.svg' : '/icons/creditcardPayment.svg'}`} /></h5>
                      <p className='fw-bold'> Credit/Debit Card</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(2, "Paypal")}>
                  <div class="card">
                    <div class={`${(selectedPaymentMethod === 2) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <h5 class="card-title"><img src={`${(selectedPaymentMethod === 2) ? '/icons/paypalActiveIcon.svg' : '/icons/paypal.svg'}`} /></h5>
                      <p className=' fw-bold'>Paypal</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(3, "Cash")}>
                  <div class="card ">
                    <div class={`${(selectedPaymentMethod === 3) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <div class="">
                        <h5 className='card-title'><img src={`${(selectedPaymentMethod === 3) ? '/icons/cashActiveIcon.svg' : '/icons/cashPayment.svg'}`} /></h5>
                        <span className='fw-bold'>Cash</span>
                        <p className='vanu-counter'> </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center col-6 m-auto mt-5 pb-4'>
                {/* <button type='text' className='px-4 py-1 theme-btn rounded text-center ' onClick={handlePostPayment}>Make Payment</button> */}
                <button type='text' className='px-4 py-1 theme-btn rounded text-center ' onClick={handlepayment}> Payment</button>

              </div>
            </div>
          </div>
        </div>
      </div >
      <>



        {
          showPayment ? <Payment selectedUser={selectedUser} orderId={orderId} paymentMethod={paymentMethod} setPopsStatus={setPopsStatus} /> : ''
        }
        {/* <Modal show={showmodal1} >
        <Modal.Header  className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >Did you receive the cash ?</Modal.Body>
        <Modal.Footer className="border-0" >
        <Button  className='w-25 '  style={{ backgroundColor: '#d3d3d3' ,color:"#720C20"}}onClick={()=>onclickcheckifyesno('No')} >
           No
          </Button>
          <Button  className="w-25" style={{ backgroundColor: '#720C20' }} onClick={()=>onclickcheckifyesno('Yes')}  >
            Yes
          </Button>
          
          </Modal.Footer>
      </Modal> */}

      </>



    </LayoutComponent>

  );
};








