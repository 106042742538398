import React, { useEffect, useContext, } from "react";
import commonLib from "./components/control/commonLib";
import konsole from "./components/network/konsole";
import Services from "./components/network/Services";
import { UserContext } from "./App";


const LoginPage = (props) => {

  const { setSubtenantLogo, setLoader, setSubtenantFileId } = useContext(UserContext);
  const qrlQuery = props.location.search;
  const uId = commonLib.getQueryParameters(qrlQuery, 'UID');
  const subtenantId = commonLib.GetQueryValues(qrlQuery, 'SubtenantId');
  konsole.log("props at dashboard", props, uId);
  konsole.log("props", props);
  const tokenKey = props.location.search;
  
  useEffect(() => {
    const tokenMapKey = commonLib.GetQueryValues(tokenKey,"token");
    konsole.log("tokenMapKey", tokenMapKey);
    const decodedKey = window.atob(tokenMapKey);
    konsole.log("dece", decodedKey);
    const loggenInId = commonLib.GetQueryValues(decodedKey,"loggenInId") || "";
    const userId = commonLib.GetQueryValues(decodedKey, "userId") || "";
    const roleId = commonLib.GetQueryValues(decodedKey, "roleId") || "";
    const appState = commonLib.GetQueryValues(decodedKey, "appState") || "";

    const stateObj = {
      loggenInId: loggenInId,
      userId: userId,
      roleId: roleId,
      appState: appState
    }
    commonLib.setSessionStoarge("stateObj", JSON.stringify(stateObj));

    if (loggenInId !== '' && userId !== '' && roleId !== '' && appState !== '') {
      getLoggedInUser(userId, appState, loggenInId, roleId);
    }
    else{
      window.location.replace("https://Unilogin.agingoptions.com/Account/UnauthorizedAccess");
    }
  }, [])

  const getLoggedInUser = (userId, appState, loggedInUserId, roleId) => {
    setLoader(true);
    const promises = Services.getLoggedInUser(userId, appState, loggedInUserId, roleId)
    promises.then(response => {
      if (response) {
        setLoader(false);
        let roleUserId = 0;
        let loginDetail = {};
        let loggedUserId = "";
        konsole.log("responsesadasas", response.data.data);
        if (response.data !== null || response.data !== "") {
          sessionStorage.setItem("AuthToken", response.data.data.accessToken);
          loginDetail = {
            memberName: response.data && response.data.data.memberName,
            primaryEmailId: response.data && response.data.data.primaryEmailId,
            userName: response.data && response.data.data.userName,
            cellNo : response.data && response.data.data.primaryPhoneNumber
          }
          if (response.data) {
            loggedUserId = response.data.data.memberUserId;
            roleUserId = response.data.data.roleId;
            sessionStorage.setItem("roleUserId", response.data.data.roleId);
            sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginDetail));
          }
          const subtenantId = response.data.data.subtenant_Id;
          konsole.log("semuser",subtenantId)
          sessionStorage.setItem("subtenantId",subtenantId);
          if ((subtenantId !== undefined && subtenantId !== null)) {
            getSubtenantDetail(subtenantId, true, appState, roleUserId);
          }
        }
      }
    }).catch(err => {
      konsole.log("err", err.response);
      window.location.replace("https://Unilogin.agingoptions.com/Account/UnauthorizedAccess")
    })
      .finally(() => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const getSubtenantDetail = (subtenantId, isActive, appState, roleUserId) => {
    setLoader(true);
    const promises = Services.getSubtenantDetail(subtenantId, isActive);
    promises.then(res => {
      setLoader(false);
      konsole.log('subtenantResObj', res.data.data[0]?.subtenantName);

      let subtenantName = res.data.data[0]?.subtenantName
      // commonLib.setSessionStoarge("subtenatName",subtenantName);
      commonLib.setDatatoSession("subtenatName", JSON.stringify(subtenantName))
      const subtenantRes = res.data.data[0];
      konsole.log(res.data.data, 'Resp');
      setSubtenantLogo(subtenantRes.subtenantLogoUrl);
      setSubtenantFileId(subtenantRes.subtenantLogoUrlId);
      commonLib.setSessionStoarge("subtenatLogoUrl", subtenantRes.subtenantLogoUrl);
      commonLib.setSessionStoarge("subtenantFileId", subtenantRes.subtenantLogoUrlId);

      if (appState != null && roleUserId == 6) {
        props.history.push({
          pathname: '/Dashboard',
        })
      }
    }).catch(err => {
      konsole.log("error", err);
      setLoader(false);
    })
  }

  return (
    <div>
      {
        <>
        </>
      }
    </div>
  )
};


export default LoginPage;
