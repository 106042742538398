import "../CounterChecking/Counter.css"
import konsole from "../network/konsole";
import Services from "../network/Services";
import { useCallback, useContext, useEffect, useState } from "react";
import { CounterCheckIn, Registered } from "../control/Constant";
import commonLib from '../control/commonLib';
import occurrence from '../control/occurrence';
import LayoutComponent from "../LayoutComponent";
import { UserContext } from "../../App";
import ConvertToExcel from "../ConvertToExcel";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DymoPrinter from "../DymoPrinter/DymoPrinter";
import occurrenceId from "../control/occurrence";
import Notification from "../Notification";
import BulkRegistration from "../Bulk/BulkRegistration";
import SearchBar from "../SearchBar";
// import '../c/PublishBoth.css'
import '../PublishBoth.css'

const Counter = (props) => {
  const history = useHistory();
  const [excle, setExcle] = useState([])
  const { setLoader } = useContext(UserContext);
  const [counterData, setCounterData] = useState([]);
  const [query, setquery] = useState("");
  const Location = commonLib.getObjFromStorage("Location");
  const upsertedBy = commonLib.getObjFromStorage('stateObj').userId;
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const seminarObj1 = commonLib.getObjFromStorage("seminarList2");
  const [UserNo, setUserNo] = useState()
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const [disabled, setdisabled] = useState(false)
  const [showModal , setShowModal] = useState(false)
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
    const [updateScreen, setUpdateScreen] = useState(true);
  const [imageURL, setImageURL] = useState("")
  const subtenantFileId = commonLib.getSessionStoarge('subtenantFileId');
  const headings = ["S. No", "First name", "Last name", "Email", "Phone", "Attendees", "Lead From", "Seat No", "Check-In Status", "Address"]
  const heading = ["S. No", "First name", "Last name", "Email", "Phone", "Attendees", "Lead From", "Seat No", "Check-In Status"]
  const bookedSeats = (props.location.state !== undefined) ? props.location.state : [];
  konsole.log("counter111",bookedSeats)



  // let date1 = moment(seminarObj?.seminarDate).format('YYYY-MM-DD')
  // let time1 = moment(seminarObj?.seminarTime, "HH:mm:SS").format("h:mm A")
  // let momentObj = moment(date1 + time1, 'YYYY-MM-DDLT');
  // let dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');
  // let currentTime = new Date();

  // let startTime = add3Hours2(dateTime);
  // if( currentTime < startTime || currentTime > endingTime ){
  //     alert("no")
  //   }
  // else{
  //   alert("yes")
  // }

  // console.log("counterDatacounterData",counterData)

  const [leadFormData, setleadFormData] = useState()
  const [referenceMode, setReferenceMode] = useState([])
  useEffect(() => {

    let promise = Services.getReferenceMode();
    promise.then(res => {
      
      setReferenceMode(res.data.data)
      setleadFormData(res.data.data)
      counterCheckInList();

    })
    let date1 = moment(seminarObj?.seminarDate).format('YYYY-MM-DD')
    let time1 = moment(seminarObj?.seminarTime, "HH:mm:SS").format("h:mm A")
    let momentObj = moment(date1 + time1, 'YYYY-MM-DDLT');
    let dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');
    let currentTime = new Date();
    let endingTime = add3Hours1(dateTime);
    if (currentTime > endingTime) {
      setdisabled(true)
    }

  }, [])

  const add3Hours1 = (time) => {

    let newTime = new Date(time);
    newTime.setHours(newTime.getHours() + 3);
    return newTime;
  }
 

  const add3Hours2 = (time) => {
    // konsole.log("kl", time)
    let newTime = new Date(time);
    newTime.setHours(newTime.getHours() - 3);
    return newTime;
  }

  konsole.log("ExcleLocation", Location)

  const counterattendeeData = (attendeeData) =>{

    let subtenantId = sessionStorage.getItem('subtenantId')
    const promise = Services.getSeminarUserList(Registered, seminarObj.subtenantId, seminarObj.seminarId, attendeeData?.seminarAttendeeId);
    promise.then((response)=>{
      konsole.log("counterattendeeDataRes",response)
      let responseData = response.data.data[0].attendees[0]

       let allData = {
 emailId: responseData?.emailId,
 mobileno: responseData?.mobileno,
 seminarAttendeeId: responseData?.seminarAttendeeId,
 seatNo: responseData?.seatNos,
}



// occurrenceId.callOccurrenceIdFunc(2,subtenantId, allData)
    }).catch((error)=>{
      konsole.log("counterattendeeDataerr",error)
    }).finally(()=>{
      konsole.log("counterattendeeDataEnd")
    })

  }

  const counterCheckInList = (leadFormData) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(Registered, seminarObj.subtenantId, seminarObj.seminarId);
    // console.log("rest11", Registered, seminarObj.subtenantId, seminarObj.seminarId)
    promise.then((res) => {
      setLoader(false);
      konsole.log("konsoleGetApiData", res)
      konsole.log("konsoleGetApiData1", counterData)


      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      
        setCounterData(counterResFilter[0]?.attendees)
     
      
      konsole.log("semniarFilter11", counterResFilter[0]?.attendees);
      let sum = 0;
      for (let i = 0; i < counterResFilter[0]?.attendees.length; i++) {

        let num = parseInt(counterResFilter[0]?.attendees[i].userGuestsNos);
        sum += num


      }
      // konsole.log("sum", sum)
      setUserNo(sum)



      // const Array = counterResFilter[0].attendees.map((res, index) => {
      //   konsole.log("resresres1", res)
      //   return {
      //     S_No: index + 1,
      //     firstName: res.fName,
      //     lastName: res.lName,
      //     EmailId: res.emailId,
      //     Mobile_Number: res.mobileno,
      //     Attendees: res.userGuestsNos,
      //     Lead_From: callReferenceValueApi1(leadFormData, res?.leadFrom)[0]?.label,
      //     Seat_Number: res.seatNos == null ? "Not Assigned" : res.seatNos,
      //     Check_In: res.semUserStatusId == 4 || res.semUserStatusId == 5 && res.seatNos !== null ? true : false


      //   }
      // })


      // for (let i = 0; i < counterResFilter[0].attendees.length; i++) {
      //   if (counterResFilter[0].attendees[i]?.attendeeUserId
      //     !== null && counterResFilter[0].attendees[i]?.attendeeUserId
      //     !== undefined && counterResFilter[0].attendees[i]?.attendeeUserId !== "") {
      //     const address = Services.getaddressbyuserid(counterResFilter[0].attendees[i]?.attendeeUserId
      //       !== undefined && counterResFilter[0].attendees[i]?.attendeeUserId);
      //     address
      //       .then((res) => {
      //         Array[i].addressValue = res.data.data.addresses[0]?.addressLine1
      //         konsole.log("addressREs", res.data.data.addresses[0]?.addressLine1);

      //       })
      //       .catch((err) => {
      //         konsole.log("Error", [i], err);

      //       });


      //   }

      // }
      // setExcle(Array)
      }).catch((err) => {
      konsole.log(`errorw `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  useEffect(() => {
    setExcel(leadFormData)
  }, [counterData,updateScreen])
  
  const setExcel = (leadFormData)=>{
    const Array = counterData.map((res, index) => {
      // konsole.log("resresres1", res?.semUserStatusId === 4 || res?.semUserStatusId === 5 || res?.semUserStatusId === 7 || res?.semUserStatusId === 11 && res?.seatNos !== null)
      return {
        S_No: index + 1,
        firstName: res.fName,
        lastName: res.lName,
        EmailId: res.emailId,
        Mobile_Number: commonLib.formatPhoneNumber(res?.mobileno?.slice(-10)),
        // res.mobileno,
        Attendees: res.userGuestsNos,
        Lead_From: callReferenceValueApi1(leadFormData, res?.leadFrom)[0]?.label,  
        Seat_Number: res.seatNos == null ? "Not Assigned" : res.seatNos,
        Check_In: res?.semUserStatusId === 4 || res?.semUserStatusId === 5 || res?.semUserStatusId === 7 || res?.semUserStatusId === 11 && res?.seatNos !== null ? "Yes" : "No"


      }
    })
    for (let i = 0; i < counterData.length; i++) {
      if (counterData[i]?.attendeeUserId
        !== null && counterData[i]?.attendeeUserId
        !== undefined && counterData[i]?.attendeeUserId !== "") {
        const address = Services.getaddressbyuserid(counterData[i]?.attendeeUserId
          !== undefined && counterData[i]?.attendeeUserId);
        address
          .then((res) => {
            Array[i].addressValue = res.data.data.addresses[0]?.addressLine1
            konsole.log("addressREs", res.data.data.addresses[0]?.addressLine1);

          })
          .catch((err) => {
            konsole.log("Error", [i], err);

          });


      }

    }
    setExcle(Array)
  }
  const counterCheckInList1 = (seminarAttendeeId) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(Registered, seminarObj.subtenantId, seminarObj.seminarId,seminarAttendeeId,counterData);
    konsole.log("rest11", Registered, seminarObj.subtenantId, seminarObj.seminarId,seminarAttendeeId)
    promise.then((res) => {
      setLoader(false);
      konsole.log("konsoleGetApiData", res)
      // konsole.log("konsoleGetApiData11", counterData)


      let counterResFilter1 = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      
      konsole.log("semniarFilter11", counterResFilter1[0]?.attendees[0]);
      let newuser = counterResFilter1[0]?.attendees[0];

     let kk  =  counterData?.findIndex((a) => a.seminarAttendeeId == newuser?.seminarAttendeeId)
     let  new22 = counterData?.splice(kk,1,newuser)
     counterData?.splice(kk,1,newuser)
     setCounterData(counterData)
     setUpdateScreen(!updateScreen)
    //  konsole.log("aabbcc",new22)
     }).catch((err) => {
      konsole.log(`errorw `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }
 
  // konsole.log("aabbccd",counterData)

  const changeCheckinStatus = (item, event) => {
    konsole.log("changeCheckinStatus",item)
    let date1 = moment(seminarObj?.seminarDate).format('YYYY-MM-DD')
    let time1 = moment(seminarObj?.seminarTime, "HH:mm:SS").format("h:mm A")
    let momentObj = moment(date1 + time1, 'YYYY-MM-DDLT');
    let dateTime = momentObj.format('YYYY-MM-DD HH:mm:ss');
    let currentTime = new Date();
    let endingTime = add3Hours1(dateTime);
    let startTime = add3Hours2(dateTime);
    if (currentTime < startTime || currentTime > endingTime) {
      // alert("Check-in for attendees is enabled 3 hours before the seminar time.")
      setWarning("Check-in for attendees is enabled 3 hours before the seminar time.")
      setColor1("#ffcc00")
      setShowModal(true)
    }
    else {
      if (event.target.checked === false) {
        handleWalkInCheckInUser(seminarObj.seminarId, item.seminarAttendeeId, item.attendeeUserId, 3, upsertedBy);


      }
      else {
        konsole.log("attendeeqw", event.target.checked);
        handleWalkInCheckInUser(seminarObj.seminarId, item.seminarAttendeeId, item.attendeeUserId, CounterCheckIn, upsertedBy, item);
      }
    }
  }

  const updateSheet = (seminarId, seminarAttendeeId, SeatStatus, attendeeUserId) => {
    const promises = Services.updateSeminarSheetStaus(seminarId, seminarAttendeeId, SeatStatus);
    // konsole.log("hyy", seminarId, seminarAttendeeId, SeatStatus)
    promises.then(res => {
      setLoader(false);
      konsole.log('registered Checkin res11', res);

      counterCheckInList1(seminarAttendeeId)
    })
      .catch(err => {
        konsole.log("err22", err.response);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })

  }


  // const getImageBase64 = async (fileId) => {
  //   setLoader(true);
  //   const getImageBase64Obj = Services.getImageBase64(fileId);
  //   getImageBase64Obj.then((res) => {
  //     setLoader(false)
  //     konsole.log("imageBASE64", res.data.data);
  //     const imageBASE64Obj = res.data.data.fileInfo;
  //     konsole.log("getImage", imageBASE64Obj.fileDataToByteArray);
  //     setImageURL(imageBASE64Obj.fileDataToByteArray)
  //   }).catch(error => {
  //     setLoader(false)
  //     konsole.log("error", error);
  //   })
  //     .finally(() =>
  //       setLoader(false)
  //     )

  // }


  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy, allData) => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    konsole.log("dummy1", seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy)
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);

      // konsole.log("counterDatacounterData",seminarAttendeeId
      // )
      // let seminarAttendeeId1 = res.data?.data?.attendees[0]?.seminarAttendeeId;
      // konsole.log('registered Checkin res', seminarAttendeeId1
      // );
      if (semUserStatusId === 3) {
        // alert("yes")
        updateSheet(seminarId, seminarAttendeeId, "CANCELLED",)
      }
      else {
        // alert("no")
        // occurrenceId.callOccurrenceIdFunc(1, subtenantId, allData)
        counterCheckInList()
        counterattendeeData(allData)
        counterCheckInList1(seminarAttendeeId)
      }

    })
      .catch(err => {
        konsole.log("err22", err.response);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }


  const updateDetails = (data) => {
    data["UpdateData"] = "Updating"
    konsole.log("updateDetailsupdateDetails", data)

    if (data?.seatNos !== null && data?.seatNos !== undefined && data?.seatNos !== "") {
      // alert("User details cannot be updated when checked in.")
      setWarning("User details cannot be updated when checked in.")
      setColor1("#ffcc00")
      setShowModal(true)
    } else {



      history.push({
        pathname: "/Registration",
        state: data

      })
    }





  }

  const callReferenceValueApi1 = (leadFormData1, IdData) => {
    konsole.log("IdDataIdDataIdData", referenceMode)
    konsole.log("IdDataIdDataIdData", IdData)
    return (leadFormData1 !== undefined && leadFormData1 !== null && leadFormData1 !== "") ? leadFormData1.filter((x) => x?.value == IdData) : "noData"
  }
  const callReferenceValueApi = (IdData) => {

    konsole.log("IdDataIdDataIdData", referenceMode)
    return referenceMode.filter((x) => x.value == IdData)
  }



  const mapCheckedIn = useCallback(()=>{

     return counterData !== undefined && counterData?.length > 0 && counterData?.filter(item => item.fName?.toLowerCase().includes(query) ||
                    item.lName?.toLowerCase()?.includes(query) ||
                    item.emailId?.toLowerCase()?.includes(query) ||
                    item.mobileno?.toLowerCase()?.includes(query)).map((item, index) => {

                      konsole.log("stateitem11",item)
                      return (
                        <tr style={{ cursor: "pointer" }} key={index}>
                          <td onClick={() => updateDetails(item)} className="">{index + 1}</td>
                          <td onClick={() => updateDetails(item)} className="">{item.fName}</td>
                          <td onClick={() => updateDetails(item)} className="">{item.lName}</td>
                          <td onClick={() => updateDetails(item)} className="">{item.emailId}</td>
                          <td onClick={() => updateDetails(item)} className="">
                            {item.mobileno.slice(0, -10)}
                            {" "}
                            {commonLib.formatPhoneNumber(item.mobileno.slice(-10))}

                          </td>

                          <td onClick={() => updateDetails(item)} className="text-center">{item.userGuestsNos}</td>
                          <td onClick={() => updateDetails(item)} className="text-center">{callReferenceValueApi(item.leadFrom)[0]?.label}</td>
                          <td onClick={() => updateDetails(item)} className="text-center">{(item.seatNos == null) ? 'Not Assigned' : item.seatNos}</td>
                          <td className="text-center">
                            <div className="form-check form-switch d-flex justify-content-center w-100">
                              <input
                                className="form-check-input text-end "
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={(event) => changeCheckinStatus(item, event)}
                                defaultChecked={((item.semUserStatusId > 3) && item.seatNos !== null && item.seatNos !== undefined && item.seatNos !== "") ? true : false}
                                checked={((item.semUserStatusId > 3) && item.seatNos !== null && item.seatNos !== undefined && item.seatNos !== "") ? true : false}
                                disabled={(item.semUserStatusId > 5) ? true : false}

                              />
                            </div>
                          </td>
                          <td>
                            {
                              Object.keys(item).length > 0 &&
                              <DymoPrinter userInfo={item} disabled={((item.semUserStatusId > 3) && item.seatNos !== null && item.seatNos !== undefined && item.seatNos !== "") ? false : true}/>
                            }
                          </td>
                        </tr>
                      )
                    })
  },[counterData, updateScreen,query])


  return (
    <LayoutComponent>
         <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>
      <div className="container-fluid bgclr">
        <div className="pt-4 d-flex justify-content-between w-100 mobileLocate">
          <h4>
            <b>Check - In Entry - Counter</b>
           
          </h4>
          <div className='p-0'>
            {
              seminarObj?.seminarTypeId !== 47 && 
              <> 
                {/* <h5>Venue: {Location?.seminarVenue}</h5> */}
                <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>
                {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
                {
                  venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                  {/* Location: {venueObj?.venueAddressLine1} */}
                  </p>
                }
                </>
             }
            <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
              {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
          </div>
        </div>

        <div className="Main-container-counter bg-white">
          <div className="row px-3 pb-3">
            <h6 className="counter-text mt-3 mobileText1">{seminarObj?.seminarTopic} (Attendees  : {UserNo}/{seminarObj1?.noOfSeats})</h6>
            <div className="col-sm-12  col-md-5 col-lg-5 me-auto   counter-text mt-auto mb-auto">
               <SearchBar setquery={setquery}/>
              {/* <div className="input-group w-75">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                />
                <span className="input-group-text" id="basic-addon1">
                  <img src="./images/vector.png" />
                </span>
              </div> */}
            </div>
           
            {/* <BulkRegistration
               subtenantId={seminarObj1?.subtenantId} seminarId={seminarObj1?.seminarId}
                /> */}
                
              {excle.length > 0 ? <ConvertToExcel data={excle} headings={headings} pName={"Check-In.xlsx"} /> : ""}
              <BulkRegistration
               subtenantId={seminarObj1?.subtenantId} seminarId={seminarObj1?.seminarId}
                />
            <div className="col-sm-2 d-flex  mt-auto mb-auto ">
            <button style={props?.location?.state?.detail == false ? { opacity: "65%" } : { opacity: "100%" }} className='reg d-flex justify-content-center align-items-center rounded text-white text-center mobile' type='button' id='btnRegister'
                onClick={() => props.history.push("/Registration", { counterData: seminarObj1 })}
                disabled={(props?.location?.state?.detail == false ? true : false) || (disabled)}
              >
                New Registration
              </button>
            </div>
          </div>

          {/* table */}
          <div className="table-contaier mt-4  ">
            <table className="table bg-white  gap-9 newT ">
              <thead  className="newT1" style={{position : "sticky",top:-1,zIndex:'5'}}>
                <tr className="bgclr gap-3 text-danger fs-6 table-responsive vertical-scroll">
                  {heading.map((ele) => {
                    return (
                      
                      <th className = {ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>
                      
                    )
                  })}
                  <th className="text-center">Print</th>
                </tr>
              </thead>
              <tbody>
                {
                  mapCheckedIn()}
              </tbody>
            </table>
          </div>
          {/* table End */}
        </div>
      </div>
    </LayoutComponent>
  );
};

export default Counter;