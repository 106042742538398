

import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../../App';
import commonLib from '../control/commonLib';
import { EnquiryRegistration } from '../control/Constant';
import konsole from '../network/konsole';
import Services from '../network/Services';
import { useHistory } from 'react-router-dom';
import './bulkRegister.css'


import { read, utils, writeFile } from 'xlsx';


import FileUpload from './FileUpload';
import Notification from '../Notification';
import occurrenceId from '../control/occurrence';



function BulkRegistration(props) {

  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const [show, setShow] = useState(false);
  const [file, setFile] = useState([{ name: "myFile.pdf" }])
  const [fileList, setFileList] = useState([])
  const [FileText, setFileText] = useState("")
  const [button, setbutton] = useState(true)
  const [same, setSame] = useState([])
  const [newData, setNewData] = useState([])
  const [showmodal1, setshowmodal1] = useState(false)
  const [showModal , setShowModal] = useState(false)
  const [filterd, setfilterd] = useState()
 
  const [numberAlert, setnumberAlert] = useState()

  ////console.log("ds1same", same)
  useEffect(() => {
  if(fileList.length !== 0){
    if(same.length !== 0){
        if(fileList.length === same.length){
         let mail=same.map((res, index) => {
            ////console.log("rest",res)

                return {
                  S_No: index + 1,
                  firstName: res.firstName,
                  lastName: res.lastName,
                  EmailId: res.email,
                  Mobile_Number: res.phone,
               
                  // ,
                  Attendees: res.attendees1,
                  // Lead_From: res.leadFrom
                  // ,
                //   Status : res.status
                  Status : newText(res)
                }
              })
              handleExport1(mail)
              setShowModal(true)
              // setTimeout(() => {
              //   setShowModal(false)
              // }, 1500)
              
              // alert("success")
  }
 }
  }


  }, [file,same])
 ////console.log("state1",showModal)
 
    const newText =(item,index)=>{
      let add = [];
      // console.log("addd",add)
    let mobilenumber = item.phone?.toString().replace(/[^0-9]/g, "")
    //console.log("mobilenumber.lengt",mobilenumber)
    // //console.log("www",mobilenumber.length > 0 && mobilenumber.length < 10)
    if(item?.phone !== "" && item?.phone !== undefined && item?.phone !== null ){
      if(mobilenumber.length > 0 && mobilenumber.length < 10 & mobilenumber !== "" || mobilenumber.length  >= 12){
       add.push("Not Registered , Invalid Cell Number")
      }
    
    }
      if(item?.email !== "" && item?.email !== undefined && item?.email !== null){
      if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item?.email)){
        add.push("Not Registered , Invalid Email")
      }}
    if(!item?.email){
        add.push("Not Registered, Email Unavailable")
        // return "Not Registered, Email Unavailable";
      }
      if(!item?.firstName){
        add.push("Not Registered, First Name Unavailable.")
        // return "Not Registered, First Name Unavailable."
      }
      if(!item?.phone){
        add.push("Not Registered, Cell Number Unavailable.")
        // return "Not Registered, Cell Number Unavailable."
      }
      // if(!item?.attendees){
      //   // alert("hy")
      //   add.push("Not Registered, Attendees Unavailable.")
      //   // return "Not Registered, Attendees Unavailable."
      // }
      if(item?.status){
        add.push(item.status)
      }
      if(item.status == "Please check your Country Code"){
        add.push(item.status)
      }
      if(add.length > 2){
        //console.log("addd",add)
       return "Invalid Data"
       }
     

    // }
    
   

      return  add[0]
      
    
    
 

    
  }



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleExport1 = (movies) => {


    ////console.log("excel",movies)
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [['S.No','First Name','Last Name','Email','Cell No','Attendees','Status']]);
    utils.sheet_add_json(ws, movies, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, "Registration_Report.xlsx");

  }
   const cellNumber =(number)=>{
    // ////console.log("numbers",typeof(number))
    
    if(number == undefined || number == null || number == ""){
      return ""
    }
    else{
      if(number.length == 11){
    if(number[0] == 1){
      
      return number.substr(number.length-10)

    }
    else{
     return null
      }
        }
      else if(number.length === 0 ){
      return ""
     }
    else if(number.length === 10 ){
    return number

    }
    else if(number.length  >=12){
      return ""
      // if(number[0] == 9 && number[1] == 1){
      //   return number.substr(number.length-10)
      // }
      // else{
      //   return null
      // }

    }
    else if(number.length > 0 && number.length < 10 
      // || number.length > 12
      ){
      return ""
    }
  //   else{
  //    return null
  // }
}
  }

  const handleBulkUpload = () => {
    setshowmodal1(true)
     
    // let filteredData = fileList.map((file, index) => {
    //   let mobilenumber = file.phone?.toString().replace(/[^0-9]/g, "") 
    //   ////console.log("mob",file)
    //   const semId = file?.referenceMode ? file.referenceMode : ""

    //     return {
    //     semUserStatusId: EnquiryRegistration,
    //     firstName: file["first name"] ?? "",
    //     lastName: file["last name"] ??  "",
    //     countryCode: "+1",
    //     phoneNumber: 
    //     cellNumber(mobilenumber),
    //     // mobilenumber?.length == 10  ? mobilenumber :  "",
    //     emailAddress: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(file["email"]) ? file["email"] : "",
    //     // emailAddress: file["Email"] ?? "",
    //     noOfAttendee: file["attendees"] ?? 0,
    //     roleId: 2,
    //     subtenantId: seminarObj?.subtenantId,
    //     seminarId: seminarObj?.seminarId,
    //     clientIPAddress: "string",
    //     referenceMode: semId


    //   }
    // })
    
    // const filteredData1 = filteredData.filter((ele)=>{
      
    //   if(ele.emailAddress !== "" && ele.firstName !== "" && ele.noOfAttendee !== 0 && ele.phoneNumber !== ""){
    //     return ele
    //   }
    // })

    // ////console.log("eleeee",filteredData1,filteredData)
   
    let i = 0;
    function printJSON() {
      // let a = filteredData[Object.keys(filteredData)[i]]
      let a = fileList[Object.keys(fileList)[i]]
       
      apiCall([a],a,fileList)
       handleClose();


      if (i < Object.keys(fileList).length - 1) {
        i++;
      } else {
        clearInterval(interval);
      }
      

    }

    let interval = setInterval(printJSON);





  }
  
  const apiCall = (data,a,b) => {
    setLoader(true);
   
    let dataarr=[]
     let filteredData = data.map((file, index) => {
      let mobilenumber = file.phone?.toString().replace(/[^0-9]/g, "") 
      //console.log("mob111",file)
      const semId = file?.referenceMode ? file.referenceMode : ""

        return {
        semUserStatusId: EnquiryRegistration,
        firstName: file["first name"] ?? "",
        lastName: file["last name"] ??  "",
        // referenceMode : file["lead from"] ??  "",
        countryCode: "+1",
        // phoneNumber: cellNumber(mobilenumber),
        phoneNumber:   cellNumber(mobilenumber) ,
        // mobilenumber?.length == 10  ? mobilenumber :  "",
        emailAddress: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(file["email"]) ? file["email"] : "",
        // emailAddress: file["Email"] ?? "",
        // noOfAttendee: file["attendees"] ?? 1,
        noOfAttendee: file?.attendees == 0 ||
                      file?.attendees == "" ||
                      file?.attendees == undefined  ||
                      file?.attendees == null ?  1 : (file?.attendees > 2) ? 2 : file?.attendees,
        roleId: 2,
        subtenantId: seminarObj?.subtenantId,
        seminarId: seminarObj?.seminarId,
        clientIPAddress: "string",
        referenceMode: semId


      }
    })

   
    // console.log("aabb111", filteredData)
 
    const promises = Services.upsertBulkRegistration(filteredData);
    let subtenantId = sessionStorage.getItem('subtenantId')

    promises.then((res) => {
      dataarr.push(data)
      konsole.log("message113", res.data.data[0])
      konsole.log("message111", data[0])
      
     let firstName = data[0]["first name"] ?? ""
     let lastName = data[0]["last name"] 
     let attendees1 = data[0].attendees == 0 || 
                      data[0].attendees == undefined || 
                      data[0].attendees == null ||
                      data[0].attendees == "" ? 1 : (data[0].attendees >2)? 2 : data[0].attendees
    //  let leadFrom = data[0]["lead from"]
   
      data[0]['status'] = res.data.data[0].status;
      data[0]['firstName'] = firstName 
      data[0]['lastName'] = lastName 
      data[0]['attendees1'] = attendees1 
      // data[0]['leadFrom'] = leadFrom

      konsole.log("aawwsseeddd",data)
 
      setSame(oldarray=>[...oldarray,a])

      if(data[0]?.status == "Registered"){

        
        let allData = {
          emailId: data[0]?.email,
          mobileno: `+1${data[0]?.phone}`,
          seminarAttendeeId: res.data.data[0]?.seminarAttendeeId,
          firstName: data[0]?.firstName
        }
        
        occurrenceId.callOccurrenceIdFunc(1, subtenantId, allData)
        let coordinatorEmailIData = commonLib.getObjFromStorage('seminarList3')?.coordinators
                konsole.log("coordinatorEmailIData",coordinatorEmailIData)
                for(let i=0; i <coordinatorEmailIData.length; i++){
                    
                    occurrenceId.callOccurrenceIdFunc(10,subtenantId, allData, i)
                }
        // occurrenceId.callOccurrenceIdFunc(10, subtenantId, allData)
        
      }
      // for(let i = 0; i< data.length; i++ ){

      // }

      // let scrollToBottom = document.getElementById("scrollToBottom");
      // scrollToBottom.scrollIntoView(false);
     
      setLoader(false);
     
    })
      .catch(error => {
        // konsole.log("error111",error.response.data.errors?.["[0].PhoneNumber"][0])
          konsole.log("message2121", error)
         if(error.response?.data?.errors?.["[0].PhoneNumber"][0] == 'The PhoneNumber field is required.'){
          
          let firstName = data[0]["first name"] 
          let lastName = data[0]["last name"]
          let attendees1 = data[0].attendees == 0 ||
                           data[0].attendees == undefined ||
                           
                           data[0].attendees == null || 
                           data[0].attendees == "" ? 1 :  (data[0].attendees > 2) ? 2 : data[0].attendees
          
           data[0]['status'] = "Please check your Country Code"
           data[0]['firstName'] = firstName 
           data[0]['lastName'] = lastName
           data[0]['attendees1'] = attendees1 
           setSame(oldarray=>[...oldarray,a])
          ////console.log("data0",data[0])
          
         }
        
      })

  }

 
  const closeButton1 =()=>{
    setshowmodal1(false)
      history.push({
        pathname: '/Counter'
      })

    
  }
 
  return (
    <>

      {/* <div className='col-sm-2 d-flex justify-content-center mb-auto mt-auto'style={{width:"15%"}} >
        <button className=' reg d-flex justify-content-center align-items-center rounded  text-center' style={{ border: '2px solid #720c20', background: "white", color: "#720c20",height:'46px' }} type='button' id='btnRegister' onClick={handleShow}  >
          Bulk Registration
        </button>
      </div> */}
       <div className="col-sm-2 d-flex justify-content-center mb-auto mt-auto">
    <button className=' reg d-flex justify-content-center align-items-center rounded  text-center mobile'style={{ border: '2px solid #720c20',background:"white",color:"#720c20",height:"46px" }} type='button' id='btnRegister'
                onClick={handleShow}
              >
                Bulk Registration
              </button>
    </div>

      <Modal show={show} onHide={handleClose} >

        <Modal.Header closeButton style={{ border: "none" }}>
   
        </Modal.Header>
        <Modal.Body>

          <div className='body'>
            <div className="box">
              <h2 className="header">
                Drag and Drop your Files
              </h2>
              <FileUpload setFileText={setFileText} setFileList={setFileList} fileList={fileList} setbutton={setbutton} />
              <span className='spot'>Supported file formats: .xlsx, .csv</span>
            </div>
          </div>

        </Modal.Body>
       

        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>

          <Button style={{ backgroundColor: '#720c20' }} onClick={handleBulkUpload} disabled={button}>
            Upload File
          </Button>

        </Modal.Footer>
      </Modal>

      <Modal show={showmodal1} onHide={closeButton1} size="xl" overflowY={"scroll"} backdrop={"static"}>
       <Modal.Header closeButton  style={{ border: "none" }}>
          
        </Modal.Header>
        <Modal.Body className="border-0 p-0" style={{height: "100%", maxHeight: "80vh", overflowY: "scroll"}}>
          <table class="table position-relative">
            <thead className='position-sticky top-0 zIndex-10 bg-white'>
              <tr >
                <th scope="col"className='text-center'>S.No</th>
                <th scope="col"className='text-center'>First Name</th>
                <th scope="col"className='text-center'>Last Name</th>
                <th scope="col"className='text-center'>Email</th>
                <th scope="col"className='text-center'>Cell No</th>
                <th scope="col"className='text-center'>Attendees</th>
                {/* <th scope="col"className='text-center'>Lead From</th> */}
                <th scope="col"className='text-center'>Status</th>
              </tr>
            </thead>
            <tbody class="table-group-divider" id='scrollToBottom'>

              {
                same.map((item, index) => {
                  console.log("same2122",item)
                  
                  return (
                    <>
                    <tr>
                      <th scope="row" className='text-center'>{index+1}</th>
                     <td className='text-center'>{item.firstName}</td>
                     <td className='text-center'>{item.lastName}</td>
                     <td className='text-center'>{item.email}</td>
                     <td className='text-center'>{item.phone}
                      {/* === null || item.phoneNumber === undefined || item.phoneNumber === "" ? "" : item.countryCode} */}
                     {/* {(item.phoneNumber !== null && item.phoneNumber !== ' ' && item.phoneNumber !== undefined && item.phoneNumber.length > 0) && item.phoneNumber.slice(0,-10) } */}
                              {/* { " "} */}
                               {/* {item.phoneNumber !== null && commonLib.formatPhoneNumber(item.phoneNumber.slice(-10))} */}
                     </td>
                     <td className='text-center'>{item.attendees1}</td>
                     {/* <td className='text-center'>{item.leadFrom}</td> */}
                     <td className={item?.status === "Registered" ?'text-center text-success':"text-center text-danger" }>{newText(item,index)}</td>
                        
                      </tr>
                    </>
                  )
                })
              }

            </tbody>
          </table>
          
        </Modal.Body>
        <Notification showModal={showModal} message={"Upload Successfully"} color={'#0baa3c'} setShowModal={setShowModal}/>
        
      

      </Modal>
    
    </>
  );


}

export default BulkRegistration;