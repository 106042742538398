import LayoutComponent from "../LayoutComponent";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { useContext, useEffect, useState, useRef } from "react";
import { CounterCheckIn, Registered } from "../control/Constant";
import commonLib from '../control/commonLib';
import { useHistory } from "react-router-dom";
import { checkIn, FollowUp } from "../control/Constant";
import { UserContext } from "../../App";
import ConvertToExcel from "../ConvertToExcel";
import SearchBar from "../SearchBar";
import './FollowUp.css';


const FollowUpList = (props) => {
  const history = useHistory();
  const Location = commonLib.getObjFromStorage("Location");
  const [excle2, setExcle2] = useState([])
  const { setLoader } = useContext(UserContext);
  const [counterData, setCounterData] = useState([]);
  const [counterPlannerList, setCounterPlannerList] = useState([]);
  const [counterPlannerList1, setCounterPlannerList1] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState();
  const [query, setquery] = useState("");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  // const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const subtenantId = commonLib.getSessionStoarge('subtenantId');

  const [first2, setfirst2] = useState();
  const [newData, setnewData] = useState()
  const [referenceMode, setReferenceMode] = useState([])
  const [optionsArray, setoptionsArray] = useState({ checkInStatus: ["Yes", "No"], plannerStatus: ["Yes", "No"],workshopStatus:["Yes", "No"] });
  const [checkInStatus, setcheckInStatus] = useState([]);
  const [plannerStatus, setplannerStatus] = useState([])
  const [workshopStatus, setworkshopStatus] = useState([])
  const [plannerStatus1, setplannerStatus1] = useState([])
  //console.log("sameto", newData)
  const headings1 = ["S. No", "First name", "Last name", "Email", "Phone", "Attendees", "Lead From", "Seat No", "Check In Status", "Custom Planning Booked","workshopStatus"]
  const upDateCBs = (checked, value, arr, name) => {

    if (checked == true) arr?.push(value)
    else {
      let index = arr?.findIndex((ele) => ele === value);
      if (index >= 0) arr?.splice(index, 1);
    }
    if (name == "checkInStatus") {
      setcheckInStatus(arr)
      setplannerStatus1(prev => prev + 1);
    }
    else if (name == "plannerStatus") {
      setplannerStatus(arr)
      setplannerStatus1(prev => prev + 1);
    }
    else if(name == 'workshopStatus'){
      setplannerStatus1(prev => prev + 1);

    }
  }

  const handelChange = (e) => {
    const { currentTarget: input } = e;
    konsole.log("changeinput", input.value);
    const value = (input.value == "Yes") ? "true" : "false";
    input.name === "checkInStatus"
      ? upDateCBs(input.checked, value, checkInStatus, input.name)
      : (input.name === "workshopStatus") 
      ? upDateCBs(input.checked, value, workshopStatus, input.name)
      : upDateCBs(input.checked, value, plannerStatus, input.name)
  }
  const showCheckBoxes = (label, arr, name, selArr) => {
    return (
      <>
        {arr.map((ele, index) => (
          <div className="form-check ms-2 ">
            <input
              className='form-check-input '
              value={ele}
              type="checkbox"
              name={name}
              id="flexCheckDisabled"
              //  checked = {selArr?.findIndex((st)=> st == ele) >0 }
              onChange={handelChange}
            />
            <label className='form-check-label'>{ele}</label>
          </div>
        ))}
      </>
    )
  }

  const drop3 = () => {
    return (
      <>
        <div className="conatiner">
          <div class="dropdown">
            <button style={{ border: "none", color: "#720c20", fontWeight: "bold" }}>Workshop</button>
            <div class="dropdown-content pb-2">
              {showCheckBoxes("workshopStatus", optionsArray?.workshopStatus, "workshopStatus", workshopStatus)}
            </div>
          </div>
        </div>
      </>
    )
  }
  const drop1 = () => {
    return (
      <>
        <div className="conatiner">
          <div class="dropdown">
            <button style={{ border: "none", color: "#720c20", fontWeight: "bold" }}>Check-In Status</button>
            <div class="dropdown-content pb-2">
              {showCheckBoxes("checkInStatus", optionsArray?.checkInStatus, "checkInStatus", checkInStatus)}
            </div>
          </div>
        </div>
      </>
    )
  }
  const drop2 = () => {
    return (
      <>
        <div className="conatiner">
          <div class="dropdown">
            <button style={{ border: "none", color: "#720c20", fontWeight: "bold" }}>Custom Planning </button>
            <div class="dropdown-content pb-2">
              {/* <input class="form-check-input me-2 md-3" type="checkbox" name="true" value="" checked={check} id="flexCheckDisabled" onChange={(e) => onchangebtn1(e)} />Yes<br />
              <input class="form-check-input me-2 " type="checkbox" value="" name="false" checked={check1} id="flexCheckDisabled" onChange={(e) => onchangebtn1(e)} />No
             */}
              {showCheckBoxes("plannerStatus", optionsArray?.plannerStatus, "plannerStatus", plannerStatus)}
            </div>
          </div>
        </div>
      </>
    )
  }
  const Check_In_Status = drop1();
  const CustomBooking_Booked = drop2();
  const WorkShop_Booked = drop3();
  const headings = ["S. No", "First name", "Last name", "Email", "Phone", "Attendees", Check_In_Status, CustomBooking_Booked,WorkShop_Booked]

  useEffect(() => {
    filteredData()
  }, [plannerStatus, checkInStatus, counterPlannerList, plannerStatus1,query,workshopStatus])

  const filteredData = () => {

    let data1 =
      checkInStatus?.length > 0
        ? counterPlannerList !== undefined && counterPlannerList?.length > 0
        && counterPlannerList.filter(
          (ele) =>
            checkInStatus.findIndex((lt) => eval(lt) == eval(ele.checkInStatus)) >= 0)
        : counterPlannerList;

    let data2 =
      plannerStatus?.length > 0
        ? data1 !== undefined && data1?.length > 0
        && data1.filter(
          (ele) =>
            plannerStatus.findIndex((lt) => eval(lt) == eval(ele.plannerStatus)) >= 0)
        : data1;
        let data3 = workshopStatus?.length > 0 
        ? data2 !== undefined && data2?.length > 0
        &&   data2.filter(
          (ele) =>
          workshopStatus.findIndex((lt)=>eval(lt) ==eval(ele.workshopStatus)) >= 0)
          : data2;
          
    // alert("j")
    console.log("newwwww", data2)

    setnewData(data3)
    const Array = data3.map((res, index) => {
      return {
        S_No: index + 1,
        firstName: res.fName,
        lastName: res.lName,
        EmailId: res.emailId,
        Mobile_Number: commonLib.formatPhoneNumber(res?.mobileno?.slice(-10)),
        Attendees: res.userGuestsNos,
        Lead_From: (callReferenceValueApi(res.leadFrom)[0]?.label),
        Seat_Number: res.seatNos,
        Check_In: res.checkInStatus == true ? 'Yes' : 'No',
        Planner_Booked: res.plannerStatus == true ? 'Yes' : 'No',
        WorkShop_Booked: res.workshopStatus == true ? 'Yes' : 'No',
      }
    })

    setExcle2(Array)


  }

  useEffect(() => {
    getCounterPlannerList();

    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
    })
  }, [])

  const counterCheckInList = () => {
    setLoader(true);
    const promise = Services.getSeminarUserList(Registered, subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      setLoader(false);
      konsole.log("konsole", res)
      konsole.log("konsole", res.data.data);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      konsole.log("semniarFilter", counterResFilter);
      setCounterData(counterResFilter[0])

    }).catch((err) => {
      konsole.log(`error `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }



  const handleAttendeeSelect = (index) => {
    const selectedUserObj = counterPlannerList[index];
    setLoader(true);
    konsole.log("selectedUserList", selectedUserObj);
    const selectedUser = {
      attendeeFullName: selectedUserObj.fName + " " + selectedUserObj.lName,
      seminarAttendeeUserId: selectedUserObj.attendeeUserId,
      attendeeEmailAddress: selectedUserObj.emailId,
      attendeeMobileNo: selectedUserObj.mobileno,
    }
    setLoader(false);
    history.push({
      pathname: '/venuePayment',
      state: selectedUser
    })
  }





  const callReferenceValueApi = (IdData) => {

    konsole.log("IdDataIdDataIdData", referenceMode)
    return referenceMode.filter((x) => x.value == IdData)
  }


  const getCounterPlannerList = () => {
    setLoader(true);
    const promise = Services.getSeminarUserList(FollowUp, seminarObj.subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      setLoader(false);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      konsole.log("semniarFilter", counterResFilter);
      setCounterPlannerList(counterResFilter[0].attendees)
      setCounterPlannerList1(counterResFilter)
    }).catch((err) => {
      konsole.log(`error %% ${err}`)
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }


  return (
    <LayoutComponent>
      <div>
        <div className="container-fluid bgclr">
          <div className="pt-4 d-flex justify-content-between w-100 mobileLocate">
            <h4>
              <b>Follow-up-List</b>
            </h4>

            <div className=' p-0'>
              {/* <h5>Venue: {Location?.seminarVenue}</h5> */}
              <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>
              {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
              {
                venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
              <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                  {/* Location: {venueObj?.venueAddressLine1} */}
                  </p>
              }
              <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
                {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
            </div>
          </div>

          <div className="Main-container-counter bg-white">
            <div className="row sticky-top-0">
              <h6 className="counter-text mt-3">{seminarObj?.seminarTopic} (Check Ins : {counterPlannerList?.length}/{counterPlannerList1[0]?.seatingCapacity})</h6>
              {/* <div className="col">
                <nav className="navbar ">
                  <form className="container-fluid">
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                      />
                      <span className="input-group-text" id="basic-addon1">
                        <i className="bi bi-search"></i>
                        <img src="./images/vector.png" />
                      </span>
                    </div>
                  </form>
                </nav>
              </div> */}
              <div className="col-sm-12  col-md-6 col-lg-6  me-auto counter-text mt-auto mb-auto">
               <SearchBar setquery={setquery}/>
              {/* <div className="input-group w-75">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                />
                <span className="input-group-text" id="basic-addon1">
                  <img src="./images/vector.png" />
                </span>
              </div> */}
            </div>
              
                {excle2.length > 0 ? <ConvertToExcel data={excle2} headings={headings1} pName={"Follow-up-list.xlsx"} /> : ""}

              <div>
              </div>
            </div>


            <div className="table-contaier mt-4 ">
              <table className="table bg-white  gap-3 ;">
                <thead style={{position : "sticky",top:-1,zIndex:'5'}}>
                  <tr className="bgclr gap-3 text-danger fs-6">
                    {headings.map((ele) => {
                      return (
                        // <th className="text-center  ">{ele}
                        <th className = {ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>


                        // </th>

                      )
                    })}

                  </tr>
                </thead>
                <tbody>
                  {

                    newData !== undefined && newData?.length > 0 && 
                      newData?.filter(item => item.fName?.toLowerCase().includes(query) ||
                    item.lName?.toLowerCase()?.includes(query) ||
                    item.emailId?.toLowerCase()?.includes(query) ||
                    item.mobileno?.toLowerCase()?.includes(query)).map((item, index) => {

                      return (
                        <tr key={index} className="cursor-pointer">
                          <td className="">{index + 1}</td>
                          <td className="">{item.fName}</td>
                          <td className="">{item.lName}</td>
                          <td className="">{item.emailId.split(',')[0]}</td>
                          <td className="">
                          {item.mobileno.slice(0, -10)}
                            {" "}
                            {commonLib.formatPhoneNumber(item.mobileno.slice(-10))}
                            {/* {item.mobileno.split(',')[0]} */}
                            </td>
                          <td className="text-center">{item.userGuestsNos}</td>
                          {/* <td className="text-center">{callReferenceValueApi(item.leadFrom)[0]?.label}</td> */}
                          {/* <td className="text-center">{item.seatNos}</td> */}
                          <td className="text-center">{(item.checkInStatus == true) ? 'Yes' : 'No'}</td>
                          <td className="text-center">{(item.plannerStatus == true) ? 'Yes' : 'No'}</td>
                          <td className="text-center">{(item.workshopStatus == true) ? 'Yes' : 'No'}</td>

                        </tr>



                      )
                    })}

                </tbody>

              </table>


            </div>

          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default FollowUpList;
