// import { Card, Col, Row } from 'ant
import React from 'react'
// import { Link } from 'react-router-dom'
import LayoutComponent from '../../LayoutComponent'
import Commonregcomponent from '../commonComponent/Commonregcomponent'
import "../Readmoreclassroom/readMoreclassroom.css"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import commonLib from "../../control/commonLib";
import {useParams, useHistory } from "react-router-dom";
import Services from '../../network/Services';




const Readmoreclassroom = (props) => {


    
    const selectedUser  = (props.location.state !== undefined) ? props.location.state : {};
    const stateObj = commonLib.getObjFromStorage("stateObj");
    // //console.log("same",selectedUser)
    const history = useHistory();
    const classRoomRegistration =(userId,seminarAttendeeId,seminarId,classroomId,createdBy)=>{
        let postData = Services.postClassRoomRegister(userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        postData.then((res) => {
    
       
          //console.log("responseresponse", res.data.data)
          let classbookingId = res.data.data?.bookingId;
          commonLib.setDatatoSession("classbookingId", JSON.stringify(classbookingId))


    
        }).catch((err) => {
          //console.log("errWW", err)
        })
      }
    const bookStatus = ()=>{
    const simpleClass = false
    const readmoreStaus = true
    const readmoreBook = true
    commonLib.setDatatoSession('simpleCalssroom',JSON.stringify(simpleClass))
    commonLib.setDatatoSession('readcalssroom',JSON.stringify(readmoreStaus))
    commonLib.setDatatoSession('readmoreBook',JSON.stringify(readmoreBook))
    const classData = { seminar: selectedUser?.selectedUser, selectedUserObj: selectedUser?.selectedUserObj }
    commonLib.setDatatoSession("classroomStaus", JSON.stringify(classData))
    
    commonLib.setDatatoSession('classBookData',JSON.stringify(selectedUser))
   //console.log("swww",selectedUser)
    classRoomRegistration(selectedUser?.selectedUser?.seminarAttendeeUserId,selectedUser?.selectedUser?.seminarAttendeeId,selectedUser?.selectedUser?.seminarid,selectedUser?.seminar?.seminarId,stateObj?.userId)

     
        history.push({
            pathname: '/classroomvenupayment',
            state : selectedUser
             })

    }
    const read =()=>{

      
        history.push({
            pathname: '/classroomregistration',
            state : selectedUser.selectedUser
             })

    }
   

    return (
        <div>
            <LayoutComponent>
                <Row className='reee'>
                    <Col span={24} className='p-3'>
                        <Commonregcomponent text="Book a Workshop" topic={selectedUser.seminar.seminarTopic}/>
                       
                        <Row span={24} className="ps-2 pb-2 bg-white" >
                            <Col span={24} >
                                <Row className='mt-2 mb-1'>
                                    <Col span={24} onClick={()=>read(selectedUser)}>
                                        
                                         All Workshop
                                         
                                    </Col>
                                </Row>
                                <Row className='main-row-readMore'  >
                                    <Col span={24} className="p-2" >
                                        <Row>
                                            <Col >
                                                <div className=' class-img-div-show'>

                                                    <img variant="top" src="cardImage.png" className='img-fluid' />
                                                </div>

                                                <h3 className='Topic-tag-h3 mt-1'>{selectedUser.seminar.seminarTopic} {""} <span className='Topic-tag-span'>({selectedUser.seminar.seminarTopic})</span></h3>

                                            </Col>
                                            <Col  >
                                                <div className='d-flex justify-content-end mt-1 Book-Now-Button-Div-show'>
                                                  
                                                        <button type="button" class="btn outline fw-bold Book-Now-Button"onClick={()=>bookStatus()} >
                                                            Book Now</button>

                                                   
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='mt-1'>
                                            <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16} className="dev">
                                                <div>
                                                    <h6 style={{ color: "#707070" }}>By {""}
                                                        {/* <Link to="readmoreprofessclassroom"> */}
                                                            <span style={{ color: "#720C20" }} >{selectedUser.seminar?.speakers[0].fName+" "+selectedUser.seminar?.speakers[0].lName}</span>
                                                        {/* </Link> */}
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                        Date: {commonLib.getFormattedDate(selectedUser.seminar?.seminarDate)}
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                        Time: {commonLib.getFormattedTime(selectedUser.seminar?.seminarTime)}
                                                    </h6>
                                                    <h6 className='h6-color-tag' >
                                                        Fare: ${selectedUser.seminar.seminarFee}
                                                    </h6>
                                                    <div className='d-flex justify-content-start ' >
                                                        <h6 className='h6-color-tag'>
                                                            Location: {selectedUser.seminar.venueAddressLine1}
                                                        </h6>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8} className="d-flex justify-content-end">
                                                <div className='p-1 Class-Img-Div' style={{width:"240px"}}>

                                                    <img variant="top" src="data.jpg" className='img-fluid' />
                                                </div>

                                                <div className='d-flex justify-content-end mt-1 Book-Now-Button-Div '>
                                                  
                                                        <button type="button" class="btn outline fw-bold Book-Now-Button"onClick={()=>bookStatus()}  >Book Now</button>
                                                   
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='mt-3'>
                                            <Col span={3} xs={24} sm={24} md={3} lg={3} xl={3} className="dev">
                                                <div className='Class-Img-prof-Div' >
                                                   <img variant="top" src={selectedUser.seminar?.speakers[0].speakerProfileUrl} className='Class-Img-prof-Div img-fluid' />
                                                </div>
                                            </Col>
                                            <Col span={21} xs={24} sm={24} md={21} lg={21} xl={21}>
                                                <div>
                                                    <h6 className='desc-Tag-h6' >
                                                        <span className='desc-Tag-span'>{selectedUser.seminar?.speakers[0].fName}</span> {selectedUser.seminar?.speakers[0].speakerProfile}
                                                    </h6>
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row className='mt-2'>
                                            <Col span={24}>
                                                <h6 className='about-classroom-tag-h6' >About Workshop</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} >
                                                <h6 className='desc-classroom-tag-h6' >{selectedUser.seminar?.description}
                                                </h6>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </LayoutComponent>
        </div>
    )
}

export default Readmoreclassroom