
import React, { useState, useRef, useEffect,} from 'react';
import commonLib from '../control/commonLib';
import Services from '../network/Services';
import { useHistory } from 'react-router-dom';
import config from "../../../src/config.json"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import occurrenceId from '../control/occurrence';
import konsole from '../network/konsole';
// konsole.log("cotact",config)

const Payment=(props)=>{
    const clickbuttonref=useRef()
let appstate = commonLib.getObjFromStorage('stateObj')?.appState
const history = useHistory();
const [orderId, setorderId] = useState('')
const [showmodal1,setshowmodal1]=useState(false)
const stateObj = commonLib.getObjFromStorage("seminarObj");
const stateObj1 = commonLib.getObjFromStorage("stateObj");
const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
const subtenatName = commonLib.getObjFromStorage("subtenatName");
const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
const classroomObj1 = commonLib.getObjFromStorage("calssroom");
// konsole.log("props",props?.selectedUser.seminarAttendeeUserId)
konsole.log("state1",classroomObj1)
let date1 = new Date()
let dateTime = moment(date1).format('MMMM Do YYYY h:mm A')
konsole.log("name11",props.selectedUser?.plannersState)



konsole.log("mobiless",props?.selectedUser?.data1?.semUserStatusId

)

  // const RedirectUrl = (config.DEMO) ? 'http://localhost:3000/counterPlanner/' :'https://countermanager.azurewebsites.net/counterPlanner' 


useEffect(() => {
  
    if(props?.paymentMethod === "Cash"){
    //  alert("hy")
    setshowmodal1(true)
      }
      else{
        adduserApiCall()
      }

   
  },[])

useEffect(() => {
  // konsole.log("orderId",orderId)
    if (orderId !== '') {
      clickbuttonref.current.submit()
    }
  }, [orderId])
  const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
    const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
     konsole.log("sample21",emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
    promises.then(res => {
     konsole.log("createdBy11",res.data.data)

      


    })
    .catch(err => {
      konsole.log("err", err.response);
      
    }).finally(fin => {
      konsole.log("finish");
      // setLoader(false);
    })
  }

  // const classRoomRegistration =()=>{
  //   let postData = Services.postClassRoomRegister()
  //   postadduser.then((res) => {

   
  //     konsole.log("responseresponse", res.data.data)

  //   }).catch((err) => {
  //     // konsole.log("err", err)
  //   })
  // }

const GetEmailTemplateUsers = (TempalteId,isActive,response,newUser)=>{
  let subtenantId = sessionStorage.getItem("subtenantId")
   
  const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
  const cash ="Cash" 
  // konsole.log("restR11111", userDetails);
  promises.then(res => {
    let data = res.data.data[0]?.templateContent
    let data2 = res.data.data[0]
    konsole.log("restapi1",data2)

    let allData = {
      ATTENDEENAME: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ? props?.selectedUser?.userRegistration?.firstName
      : (props?.selectedUser1)
      ? props?.selectedUser1?.selectedUser?.attendeeFullName
      : props?.selectedUser.attendeeFullName,

      // EVENTNAME: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      // ? 'Seminar'
      // : (props?.selectedUser1)
      // ? 'Classroom'
      // : 'LifePlanner',
      EVENTNAME: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ?stateObj?.seminarTopic +" "+"Seminar"
      : (props?.selectedUser1)
      ? stateObj?.seminarTopic +" "+"Classroom"
      : "LifePlanner",

      PAYMENTMODE: "Cash",

      PAIDAMOUNT: props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
      ? props?.selectedUser?.data2
      :(props?.selectedUser1)
      ? props?.selectedUser1?.selectedUserObj?.seminarFee
      :350,

      PAYMENTDATE: dateTime,
      COORDINATORNAME: userLoggedInDetail?.memberName,
      COORDINATOREMAIL: userLoggedInDetail?.primaryEmailId,
      COORDINATORCELLNO: userLoggedInDetail?.cellNo,

      ATTENDEEEMAIL: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ? props?.selectedUser?.userRegistration?.emailAddress
      : (props?.selectedUser1)
      ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
      : props?.selectedUser.attendeeEmailAddress,

      ATTENDEECELLNO: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ? props?.selectedUser?.userRegistration?.phoneNumber
      : (props?.selectedUser1)
      ? props?.selectedUser1?.selectedUser?.attendeeMobileNo
      : props?.selectedUser.attendeeMobileNo,

      BOOKTYPE: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ? "registration to"
      : (props?.selectedUser1)
      ? "registration to"
      : "booking a",

      SUBTENANTNAME: subtenatName,

      emailId: props?.selectedUser?.data1?.semUserStatusId === 3 && 4
      ? props?.selectedUser?.userRegistration?.emailAddress
      : (props?.selectedUser1)
      ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
      : props?.selectedUser.attendeeEmailAddress,

      seminarAttendeeId: attendeeObj?.seminarAttendeeId.toString()









    }
   
    
  //   const replacedStr = data.replace('@@ATTENDEENAME',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //   ? props?.selectedUser?.userRegistration?.firstName
  //   : (props?.selectedUser1)
  //   ? props?.selectedUser1?.selectedUser?.attendeeFullName
  //   : props?.selectedUser.attendeeFullName
  //   ).replace('@@ATTENDEENAME',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //   ? props?.selectedUser?.userRegistration?.firstName
  //   : (props?.selectedUser1)
  //   ? props?.selectedUser1?.selectedUser?.attendeeFullName
  //   : props?.selectedUser.attendeeFullName).
  //   replace('@@EVENTNAME',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //   ? 'Seminar'
  //   : (props?.selectedUser1)
  //   ? 'Classroom'
  //   : 'LifePlanner').
  //   replace('@@PAYMENTMODE',"Cash").
  //   replace('@@PAIDAMOUNT', props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
  //   ? props?.selectedUser?.data2
  //   :(props?.selectedUser1)
  //   ? props?.selectedUser1?.selectedUserObj?.seminarFee
  //   :350).
  //   replace('@@PAYMENTDATE',dateTime).
  //   replace('@@COORDINATORNAME',userLoggedInDetail?.memberName).
  //   replace('@@COORDINATOREMAIL',userLoggedInDetail?.primaryEmailId).
  //   replace('@@COORDINATORCELLNO',userLoggedInDetail?.cellNo).
  //   replace('@@ATTENDEEEMAIL',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //   ? props?.selectedUser?.userRegistration?.emailAddress
  //   : (props?.selectedUser1)
  //   ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
  //   : props?.selectedUser.attendeeEmailAddress)
  //   .replace('@@ATTENDEECELLNO', props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //   ? props?.selectedUser?.userRegistration?.phoneNumber
  //   : (props?.selectedUser1)
  //   ? props?.selectedUser1?.selectedUser?.attendeeMobileNo
  //   : props?.selectedUser.attendeeMobileNo)
      
  //     .replace('@@BOOKTYPE',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //     ? "registration to"
  //     : (props?.selectedUser1)
  //     ? "registration to"
  //     : "booking a")
  //     .replace('@@EVENTNAME',props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //     ? stateObj?.seminarTopic +" "+"Seminar"
  //     : (props?.selectedUser1)
  //     ? stateObj?.seminarTopic +" "+"Classroom"
  //     : "LifePlanner")
  //     .replace('@@SUBTENANTNAME',subtenatName);
    
    
  //   konsole.log("newData",replacedStr);
  //  let emailType = data2?.templateType;
  //  let emailTo =   props?.selectedUser?.data1?.semUserStatusId === 3 && 4
  //  ? props?.selectedUser?.userRegistration?.emailAddress
  //  : (props?.selectedUser1)
  //  ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
  //  : props?.selectedUser.attendeeEmailAddress;
  //  let emailSubject = data2?.emailSubject;
  //  let emailTemplateId = data2?.templateId;
  //  let emailContent = replacedStr;
  //  let emailStatusId = 1;
  //  let emailMappingTable = "tblSeminarAttendeeList";
  //  let emailMappingTablePKId = attendeeObj?.seminarAttendeeId.toString();
  //  let createdBy = stateObj1?.userId;
  //  konsole.log("emailMappingTablePKId",emailMappingTablePKId)

occurrenceId.callOccurrenceIdFunc(13, subtenantId, allData)
    // SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
    
  })

  .catch(err => {
    konsole.log("err", err.response);
    
  }).finally(fin => {
    // konsole.log("finish");
    // setLoader(false);
  })

}
  const cashPayment =(data)=>{
    // alert(2)
     //console.log("sampleee",data)
    let adduserobjCash = {
      "userId": data?.userId,
      "memberId": data?.order.memberId,
      "orderId": data?.order?.orderId,
      "transactionId": "NA",
      "paymentType": 2,
    }
    let classOrderId = data?.order?.orderId
    commonLib.setDatatoSession('classOrderId', JSON.stringify(classOrderId))
    // konsole.log("waste",adduserobjCash)

   let cashUser =Services.postuserorderAddUserOrderCash(adduserobjCash)
   cashUser.then((response)=>{
    GetEmailTemplateUsers(14,true)
    konsole.log("dwt",response.data.data?.order?.paymentType)
    const data = {paymentType : response.data.data?.order?.paymentType, orderId : response.data.data.order.orderId} 

    if(props?.selectedUser?.data1?.semUserStatusId === 3 || props?.selectedUser?.data1?.semUserStatusId === 4  || props?.selectedUser1 ){
       if(classroomObj1 === true){
        history.push({
          pathname: '/confirmBooking',
          state : data?.paymentType
        })

       }
       else{
      history.push({
        pathname: '/Congratulations',
        state : data?.paymentType
      })
    }
      
    }
    
    else{
      history.push({
        pathname: '/AfterPaymentScreen',
        state : data
        
      })
    }
    konsole.log("waste",response.data.data)
   }).catch((error)=>{
    konsole.log("errt",error)
    
   })
  }
  const adduserApiCall = () => {
    konsole.log("Sample")
    // alert("hyy")
    let adduserobj = {
      "userId": props?.selectedUser?.data1?.semUserStatusId === 3 && 4
        ? props?.selectedUser.data1?.userId
        : (props?.selectedUser1)
        ? props?.selectedUser1?.selectedUser?.seminarAttendeeUserId
        : props?.selectedUser.seminarAttendeeUserId
        ,
      "shippingCost": 0,
      "taxCost": 0,
      "totalCost":props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
        ? props?.selectedUser.data2
        :(props?.selectedUser1)
        ? props?.selectedUser1?.selectedUserObj?.seminarFee
        :1
 
      
      ,
      "productList": [
        {
          "productType":props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
          ? "SEMINAR"
          : (props?.selectedUser1)
          ? "CLASSROOM"
          :"PLANNER"
          
          
          
          
          ,
          "productId": props?.selectedUser?.data1?.semUserStatusId === 3 && 4
          ?props?.selectedUser.data1?.seminarId

          :(props?.selectedUser1)
          ? props?.selectedUser1?.selectedUserObj?.seminarId
          :props?.selectedUser?.seminarid,
          "productName":stateObj.seminarTopic,



          
          "quantity": props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
          ? props?.selectedUser.userRegistration?.noOfAttendee
          :(props?.selectedUser1)
          ? 1
          : 1,
          "productPrice": props?.selectedUser?.data1?.semUserStatusId === 3 && 4 
           ? props?.selectedUser?.data2
           :(props?.selectedUser1)
           ? props?.selectedUser1?.selectedUserObj?.seminarFee
           :1
        }
      ]
    }
    

    let postadduser = Services.postuserorderAddUserOrder(adduserobj)
    postadduser.then((res) => {
      
        
      // setorderId(res.data.data.order.orderId)
      if(props?.paymentMethod === "Cash"){
        // alert("hy")
        cashPayment(res.data.data)
      }
      else{
        // alert("22")
        setorderId(res.data.data.order.orderId)
      }
      
      //console.log("responseresponse1", res.data.data)

    }).catch((err) => {
      // konsole.log("err", err)
    })
  }


  const onclickcheckifyesno =(data)=>{
    konsole.log("sem1",data)
    if(data === "Yes"){
      adduserApiCall()
    }
    else{
      props.setPopsStatus(false)
      setshowmodal1(false)
    }

  }
 return(

    <>
     {/* <form action="https://aopaymentdev.azurewebsites.net/Catalog/PaymentRequest" */}

     <form 
     action='https://aopayment.azurewebsites.net/Catalog/PaymentRequest '

          method="post" ref={clickbuttonref} >

          <div>
            <input type="hidden" name="AppState" id="AppState" value={appstate} />
            <input type="hidden" name="ReturnUrl" id="ReturnUrl"
            
            value= {
              props?.selectedUser?.data1?.semUserStatusId === 3 && 4 ?
             ` ${config.COUNTERBASEURL}`
              //  "https://countermanager.azurewebsites.net/Congratulations"
            
            : (props?.selectedUser1) ? `${config.COUNTERBASEURL2}` :
            ` ${config.COUNTERBASEURL1}`
            // "https://countermanager.azurewebsites.net/CounterPlanner/AfterPaymentScreen"
            }    /> 
            
            <input type="hidden" name="UserId" value={
              props?.selectedUser?.data1?.semUserStatusId === 3 && 4
              ? props?.selectedUser.data1?.userId
              : (props?.selectedUser1)
              ? props?.selectedUser1?.selectedUser?.seminarAttendeeUserId
              : props?.selectedUser?.seminarAttendeeUserId} />
            <input type="hidden" name="OrderId" value={orderId} />
            <input type="hidden" name="PaymentType" value={props?.paymentMethod} />
          </div>
          <div>
        
          </div>
        </form>
        <Modal show={showmodal1} >
        <Modal.Header  className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >Did you receive the cash ?</Modal.Body>
        <Modal.Footer className="border-0" >
        <Button  className='w-25 '  style={{ backgroundColor: '#d3d3d3' ,color:"#720C20"}}onClick={()=>onclickcheckifyesno('No')} >
           No
          </Button>
          <Button  className="w-25" style={{ backgroundColor: '#720C20' }} onClick={()=>onclickcheckifyesno('Yes')}  >
            Yes
          </Button>
          
          </Modal.Footer>
      </Modal>

    </>
 )   
}
export default Payment