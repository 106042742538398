import { useState } from "react";
import { useContext, useEffect } from "react";
import { BrowserRouter, Link, NavLink, Route, Switch } from "react-router-dom";
import { UserContext } from "../../App";
import commonLib from "../control/commonLib";
import '../Headers/header.css';
import konsole from "../network/konsole";
const Header = (props) => {

  let { subtenantLogo } = useContext(UserContext);
  const [loggeduser,setloggeduser]=useState('')
  // console.log("sample",loggeduser)
  const subtenantId = commonLib.getSessionStoarge('subtenantId')
  // console.log("sample",subtenantId)
  const handleLogout = (e) => {
    const stateObj = commonLib.getObjFromStorage('stateObj');
    let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&success=${true}`;
   
    // const params = `Account/Signout?appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggedInUserId}`;
    konsole.log("params", params);
    sessionStorage.clear()
    window.location.replace('https://Unilogin.agingoptions.com/Account/Signout?' + params);
    
  }

  
  useEffect(() => {
    const loggeduser = JSON.parse(commonLib.getSessionStoarge('userLoggedInDetail'))?.memberName
    konsole.log("loggeduser",loggeduser)
       setloggeduser(loggeduser)
    
  })
  
  // useEffect(()=>{
  //   let loggeduser = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
  //   konsole.log("loggeduser",loggeduser)
  //   setloggeduser(loggeduser)
  // })
  return (
    <>

      <nav className="head navbar navbar-expand-lg bg-white sticky-top">
        <div className="container-fluid">
          <div className=" MainHeaderLogo m-0 p-0 d-flex justify-content-between">
            {
              subtenantLogo !== '' ? <img src={subtenantLogo} className='subtenantLogo ' alt="Login user image" /> : <h2><b>SUBTENANT LOGO</b></h2>
            }
          </div>
          {/* <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
          <span className="close-icon  ">✖</span>
          <span className="navbar-toggler-icon "></span>
           
          </button> */}

          <button className="navbar-toggler Navbarnav collapsed d-flex d-lg-none flex-column justify-content-around border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            {/* <span className="close-icon  ">✖</span> */}
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse w-25 text-end bg-white mt-2" id="navbarSupportedContent">
            <ul className="navbar-nav plannertext ms-auto" >
              {
                props.pathName !== "Dashboard" ?
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link mt-2 " aria-current="page" to="/Dashboard">Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link mt-2 " aria-current="page" to="/Counter">Check-In</NavLink>
                    </li>
                   

                    <li className="nav-item">
                      <NavLink className="nav-link mt-2 " aria-current="page" to="/PublishBoth">Publish Link</NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink className="nav-link mt-2 me-2" to="/CounterPlanner">Custom Planning</NavLink>
                    </li> */}
                    
                    {/* <li className="nav-item">
                    {subtenantId == 745 ? "" :
                    <NavLink className="nav-link mt-2 me-2" to="/classDashBord">Workshop</NavLink>
              }
                    </li> */}
                    
                    
                    {/* <li className="nav-item dropdown">
                      <NavLink className="nav-link mt-2" to="/ConfirmationList" role="button" >
                        Confirmation List
                      </NavLink>
                    </li> */}
                    {/* <li className="nav-item">
                      <NavLink className="nav-link mt-2 me-2 " to="/FollowUpList" tabIndex="-1" aria-disabled="true">Follow-up-List
                      </NavLink>
                    </li> */}
                 
                    <li className="Vertical d-none d-lg-block">
                    </li>
                  </>
                  :
                  <>
                  <li className="nav-item dropdown">
                      <NavLink className="nav-link mt-2 " to="/Publishseminar" role="button" >
                        Publish Seminar
                      </NavLink>
                    </li>
                    <li className="nav-item dropdown nav-link">
                    <NavLink  className="nav-link "  to='/DesktopView'>
                      Desktop View
                      </NavLink>
            </li>
                  </>
              }
              <li className="nav-item">
 
                <a className="nav-link mt-2" href="#" tabIndex="-1" aria-disabled="true">Hi, {loggeduser}</a>
              </li>
              <li className="nav-item logoutCircle">
                <a className="nav-link  mt-2 d-none d-lg-block" onClick={handleLogout} tabIndex="-1" aria-disabled="true"><img src='/icons/logoutcircle.svg' className="cursor-pointer"></img></a>
                <a className="nav-link  mt-2 d-lg-none" onClick={handleLogout} tabIndex="-1" aria-disabled="true">Log Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
}

export default Header;