import React, { useEffect,useState, useContext } from "react";
import LayoutComponent from './LayoutComponent'
import commonLib from './control/commonLib';
import '../components/PublishBoth.css';
import Services from './network/Services';
import PreviewMail from './PreviewMail';
// import { CounterCheckIn, Registered } from "../control/Constant";
import { Registered } from "./control/Constant";
import konsole from "./network/konsole";
import config from '../../src/config.json'
import { UserContext } from "../App";
import Notification from "./Notification";

function PublishBothSeminar() {
    const seminarObj = commonLib.getObjFromStorage("seminarObj");
    const venueObj = commonLib.getObjFromStorage("venueObj");
    const seminarObj1 = commonLib.getObjFromStorage("seminarList2");
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    const subtenatName = commonLib.getObjFromStorage("subtenatName");
    const Location = commonLib.getObjFromStorage("Location");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const [openSendEmailModal, setopenSendEmailModal] = useState(false)
    const [previewTemplateData, setpreviewTemplateData] = useState()
    const [uniqueNum, setuniqueNum] = useState([])
    const [UserNo, setUserNo] = useState(0)
    const [CounterData, setCounterData] = useState()
    const [newUser, setnewUser] = useState()
    const [tempData, setTempData] = useState()
    const [activeText, setactiveText] = useState(false)
    const [textTtpeeId, setTextTtpeeId] = useState()
    const [textData, setTextData] = useState()
    const { setLoader } = useContext(UserContext);
    const [textOrEmail, setTextOrEmail] = useState()
    const [showModal1 , setShowModal1] = useState(false)
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
    console.log("previewTemplateData",textData)

    const handelClose =(data)=>{
   console.log("datatta",data)
   setopenSendEmailModal(data)
  
   
    }
    useEffect(() => {
        counterCheckInList()
       }, [])
       
    const PlannerClassRoomLinks = (SeminarId,LinkType,unique)=>{
      
        setLoader(true)
        const promise = Services.getPlannerClassRoomLinks(SeminarId,LinkType);
        console.log("rest11433", SeminarId,LinkType)
        promise.then((res) => {
            // setLoader(false)
          let user = res?.data?.data
          setnewUser(user)
          konsole.log("konsoleGetApiData345", user)
        if(unique !== 1 && unique !== 2){
          
          handelSend(17, true, subtenantId,3)
        }
        else{
    //  alert("hyww")
          setuniqueNum(unique)
          setopenSendEmailModal(true)
        }
          
       
     }).catch((err) => {
          konsole.log(`errorw11 `, err.response.data.messages[0])
          if(err.response.data.messages[0] === "No CheckIn Attendees Available"){
            setWarning("There are no checked-in users available at this moment. Please check-in the user to send the link.")
            setColor1("#ffcc00")
            setShowModal1(true)
            // alert("There are no checked-in users available at this moment. Please check-in the user to send the link.")
           }
          setLoader(false)
        }).finally(error => {
          konsole.log("finish");
        //   setLoader(false);
        })

    }   
   
    const counterCheckInList = () => {
        // setLoader(true);
        const promise = Services.getSeminarUserList(Registered, seminarObj.subtenantId, seminarObj.seminarId);
        // console.log("rest11", Registered, seminarObj.subtenantId, seminarObj.seminarId)
        promise.then((res) => {
        //   setLoader(false);
          konsole.log("konsoleGetApiData", res)
        //   konsole.log("konsoleGetApiData1", counterData)
        
    
    
          let counterResFilter = res.data.data.filter((res) => {
            return (res.seminarId == seminarObj.seminarId)
          })
          setCounterData(counterCheckInList[0]?.attendees)
            // setCounterData(counterResFilter[0]?.attendees)
            console.log("counterResFilter",counterResFilter[0].attendees)

            // let newData = counterResFilter[0].attendees.filter((ele)=>{
            //     return  
            // })
         
          
          konsole.log("semniarFilter11", counterResFilter[0]?.attendees);
          let sum = 0;
          for (let i = 0; i < counterResFilter[0]?.attendees.length; i++) {
    
            let num = parseInt(counterResFilter[0]?.attendees[i].userGuestsNos);
            sum += num
    
    
          }
          // konsole.log("sum", sum)
          setUserNo(sum)
    
          }).catch((err) => {
          konsole.log(`errorw `, err.response)
        }).finally(error => {
          konsole.log("finish");
        //   setLoader(false);
        })
      }
      
    const GetEmailTemplateUsers = (TempalteId,isActive,unique,user)=>{
    
        const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
          promises.then(res => {
          let data = res.data.data[0]?.templateContent
          let data2 = res.data.data[0]
          console.log("restapi11111dd",data)

          setTempData(res.data?.data)
           console.log("dummy",data2)
           if(unique == 3){
           setuniqueNum(unique)
            setopenSendEmailModal(true)
            const replacedStr = data.
            replace('@@SEMINARNAME',seminarObj?.seminarTopic)
            setpreviewTemplateData(data)
           }
           else{
            const replacedStr2 = data.
            replace('@@SEMINARNAME',seminarObj?.seminarTopic)
            setpreviewTemplateData(replacedStr2)
            // setuniqueNum(unique)
            // setopenSendEmailModal(true)
           }
          
        })
      
        .catch(err => {
          console.log("err", err.response);
          
        }).finally(fin => {
          // konsole.log("finish");
          // setLoader(false);
        })
    
      }
    const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
      //  alert("hhh")
        const promises = Services.getTextTemplateFunc(TempalteId,isActive);
    
        console.log("restR11111", TempalteId,isActive);
        promises.then(res => {
            console.log("ihkjgiu",TempalteId)
          let data = res.data.data[0]?.templateContent
          let data2 = res.data.data[0]
          console.log("restapi1112",data2)
          setTextData(data2)
           console.log("dummy",data2)
           const replacedStr1 = data.
          replace('@@SEMINARNAME',seminarObj?.seminarTopic)
        
    
        // setpreviewTemplateData(replacedStr1)
        setuniqueNum(unique)
        setopenSendEmailModal(true)
        
          console.log("newData",replacedStr1);
          
        })
      
        .catch(err => {
          console.log("err", err.response);
          
        }).finally(fin => {
          // konsole.log("finish");
          // setLoader(false);
        })
    
      }
    const handelSend =(occurrenceId, isActive, subtenantId,unique)=>{
      setLoader(true
        )
        const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
        console.log("rest11", occurrenceId, isActive, subtenantId)
        promise.then((res) => {
            setLoader(false)
            let textId = res.data.data[0]?.applicableTextTempId;

            console.log("sssssss",res.data.data)
            let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
            if(applicableEmailTempId !== null && applicableEmailTempId !== undefined && applicableEmailTempId !== ""){
                GetEmailTemplateUsers(applicableEmailTempId,true,unique)
            }
            
        if(textId !== null && textId !== undefined && textId !== ""){
          //  alert("hy")
            setactiveText(true)
            setTextTtpeeId(textId)
                GetTextTemplateUsers1(textId,true,unique)
        }
        if(unique == 1 ){
          PlannerClassRoomLinks(seminarObj?.seminarId,"CLASSROOM",unique)
         }
          if(unique == 2){
            PlannerClassRoomLinks(seminarObj?.seminarId,"PLANNER",unique)
          }
          if(textId !== null && textId !== undefined && textId !== "" && 
          applicableEmailTempId !== null && applicableEmailTempId 
          !== undefined && applicableEmailTempId !== "" ){
            setTextOrEmail(1)
            // alert("yes")
          }
          if(textId !== null && textId !== undefined && textId !== "" && 
          applicableEmailTempId === null || applicableEmailTempId 
          === undefined || applicableEmailTempId === "" 
          ){
            setTextOrEmail(2)
            // alert("2")
          }
          if(textId === null || textId === undefined || textId === "" &&
          applicableEmailTempId !== null && applicableEmailTempId 
          !== undefined && applicableEmailTempId !== ""
          ){
            setTextOrEmail(3)
            // alert("5")
          }
         
      
      
      }).catch((err) => {
      console.log(`errorw `, err.response.data.messages[0])
     
   
      if(err.response.status == 404){
        
        let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
        
        handelSend(occurrenceId, isActive, subtenantId1,unique) 
        
    }
    }).finally(error => {
      console.log("finish");
    //   setLoader(false);
    })

    }

  return (
    <LayoutComponent>
      <Notification showModal={showModal1} message={warning} color={Color1} setShowModal={setShowModal1}/>
        <div className="container-fluid bgclr">
        <div className="pt-4 d-flex justify-content-between w-100 mobileLocate ">
          <h4>
            <b>Check - In Entry - Counter</b>
            
          </h4>
          <div className='p-0'>
            {
              seminarObj?.seminarTypeId !== 47 && 
              <> 
               
                <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>
                {
                  venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                 
                  </p>
                }
                </>
             }
            <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
              {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
          </div>
        </div>

        <div className="Main-container-counter bg-white">
          <div className="row px-3 pb-3">
            <h6 className="counter-text mt-3 mobileText1">{seminarObj?.seminarTopic} (Attendees  : {UserNo}/{seminarObj1?.noOfSeats})</h6>
             
             <div className='d-flex flex-column name'>
             <button className='btn  text-white  p-2 mt-2 float-end rounded new' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister'onClick={() =>handelSend(8, true, subtenantId,1)}  >Publish Workshop Link</button>
             <button className='btn  text-white  p-2 mt-2 float-end rounded new' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' onClick={() =>handelSend(3, true, subtenantId,2)} >Publish Custom Planning Link</button>
             <button className='btn  text-white   p-2 mt-2 float-end rounded  new ' style={{ backgroundColor: '#720c20'}} type='button' id='btnRegister' onClick={() =>PlannerClassRoomLinks(seminarObj?.seminarId,"PLANNER&CLASSROOM")}  >Publish Both Links</button>

             </div>
            
           
          
          </div>
          </div>
      </div>
      {
        openSendEmailModal == true ? <PreviewMail cancelMOdal={handelClose} openSendEmailModal={openSendEmailModal}
         titleText="Send Email" previewTemplateData={previewTemplateData} 
         uniqueNum={uniqueNum} newUser={newUser}tempData={tempData}activeText={activeText}textTtpeeId={textTtpeeId} 
         textData={textData} textOrEmail={textOrEmail}/>:"" }
    </LayoutComponent>
  )
}

export default PublishBothSeminar