// import Vanue from "../SelfPlanner/Vanu/Vanue";
// import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";
import '../Headers/header.css';
import { useHistory } from "react-router-dom";
import { checkIn } from "../control/Constant";
import LayoutComponent from "../LayoutComponent";
import konsole from "../network/konsole";
import Services from "../network/Services";
import commonLib from "../control/commonLib";
import { UserContext } from "../../App";
import { CounterCheckIn, Registered } from "../control/Constant";
import ConvertToExcel from "../ConvertToExcel";
import Notification from "../Notification";
import SearchBar from "../SearchBar";
import PreviewMail from "../PreviewMail";
import config from '../config.json';



const CounterPlanner = (props) => {


  const history = useHistory();
  const [exclee, setExclee] = useState([])
  const Location = commonLib.getObjFromStorage("Location");
  const { setLoader } = useContext(UserContext);
  const [counterData, setCounterData] = useState([]);
  const [counterPlannerList, setCounterPlannerList] = useState([]);
  const [selectedAttendee, setSelectedAttendee] = useState();
  const [query, setquery] = useState("");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const [UserNo, setUserNo] = useState()
  const [referenceMode, setReferenceMode] = useState([])
  const [showModal , setShowModal] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [openSendEmailModal, setopenSendEmailModal] = useState(false)
  const [previewTemplateData, setpreviewTemplateData] = useState()
  const [uniqueNum, setuniqueNum] = useState([])
  const [newUser, setnewUser] = useState()
  const [tempData, setTempData] = useState()
  const [activeText, setactiveText] = useState(false)
  const [textTtpeeId, setTextTtpeeId] = useState()
  const [textData, setTextData] = useState()
  
  const [textOrEmail, setTextOrEmail] = useState()

  
  
  const headings = ["S. No","First name","Last name","Email","Phone","Attendees","Lead From","Seat No","Check-In Status"]
// //console.log("count2",counterPlannerList)
  useEffect(() => {
    getCounterPlannerList(checkIn);
    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
      counterCheckInList(res.data.data);
      
    })
  }, []);

  const handelClose =(data)=>{
    console.log("datatta",data)
    setopenSendEmailModal(data)
   
    
     }
     const PlannerClassRoomLinks = (SeminarId,LinkType,unique)=>{
      
      setLoader(true)
      const promise = Services.getPlannerClassRoomLinks(SeminarId,LinkType);
      console.log("rest11433", SeminarId,LinkType)
      promise.then((res) => {
          // setLoader(false)
        let user = res?.data?.data
        setnewUser(user)
        konsole.log("konsoleGetApiData345", user)
      if(unique !== 1 && unique !== 2){
        
        // handelSend1(17, true, subtenantId,3)
      }
      else{
  //  alert("hyww")
        setuniqueNum(unique)
        setopenSendEmailModal(true)
      }
        
     
   }).catch((err) => {
        konsole.log(`errorw `, err.response)
        setLoader(false)
      }).finally(error => {
        konsole.log("finish");
      //   setLoader(false);
      })

  } 
  
  //Sumit numberOfSeatsBooks/Seating Capacity

  const counterCheckInList = (leadFormData) => {
    setLoader(true);
    konsole.log("seminarUserData",checkIn, subtenantId, seminarObj.seminarId)

    const promise = Services.getSeminarUserList(checkIn, seminarObj.subtenantId, seminarObj.seminarId);

    promise.then((res) => {
      setLoader(false);
      konsole.log("konsolekonsole", res)
      // //console.log("//console", res.data.data);
      // //console.log("BookedSeats", res.data.noOfSeatsBooked);
      konsole.log("konsole", res)
      // konsole.log("konsole", res.data.data);
      // konsole.log("BookedSeats", res.data.noOfSeatsBooked);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      konsole.log("semniarFilter123", counterResFilter);
      let sum = 0;
      // for(let i=0;i < counterResFilter[0]?.attendees.length;i++){
      
       
      //   let num = counterResFilter[0]?.attendees[i].seatNos.split(",");
       
      //   //console.log("sum",num?.[1])
      //   setUserNo(num?.[1])
      // }
      setUserNo(res.data.data[0]?.noOfSeatsBooked)

      
     

      
      const Array = counterResFilter[0]?.attendees.map((res,index) => {
        return {
          S_No :index+1,
          firstName: res.fName,
          lastName :res.lName,
          EmailId :res.emailId,
          Mobile_Number :commonLib.formatPhoneNumber(res?.mobileno?.slice(-10)),
          Attendees :res.userGuestsNos,
          Lead_From: callReferenceValueApi(leadFormData ,res?.leadFrom)[0]?.label,
          Seat_Number :res.seatNos,
          Check_In :res.semUserStatusId == 4 || res.semUserStatusId == 5 ? 'Yes' : 'No'
        }
      })
      konsole.log("sfd",Array)
       setExclee(Array)
    
      setCounterPlannerList(counterResFilter[0])

    }).catch((err) => {
      konsole.log(`error `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }

  //Sumit

  const getUserRoles = (userId,selectedUserObj)=>{
    const plannersState = true
    const ClassroomStatus = false
    commonLib.setDatatoSession('calssroom',JSON.stringify(ClassroomStatus))
    setLoader(true);
    konsole.log("selectedUserList", selectedUserObj);
    const seminarid=counterPlannerList.seminarId
    konsole.log('seminarId',seminarid)
    const selectedUser = {
      attendeeFullName: selectedUserObj.fName + " " + selectedUserObj.lName,
      seminarAttendeeUserId: selectedUserObj.attendeeUserId,
      attendeeEmailAddress: selectedUserObj.emailId,
      attendeeMobileNo: selectedUserObj.mobileno,
      seminarAttendeeId: selectedUserObj.seminarAttendeeId,
      seminarid:seminarid,
      plannersState :plannersState
    }
    konsole.log("selectedUserselectedUser",selectedUser)
    commonLib.setSessionStoarge("attendeeObj",JSON.stringify(selectedUser));
    konsole.log("fame",selectedUser)
    setLoader(false);
   
  
   const promises = Services.GetUserRolebByID(userId);
   promises.then(res => {
      let len = res.data.data.length;
      let data = res.data.data;
      let newData = []

      for(let i = 0 ; i <len;i++){
       
       newData.push(data[i].roleId)
      }
      //console.log("newData1", typeof(newData[0]))
      //console.log('registered Checkin res11', newData.every((x)=> x !==1 && x !== 9 && x !== 10 ));
       if(data[0]?.subtenantId === seminarObj?.subtenantId){
         if(newData.every((x)=> { return x !==1 && x !== 9 && x !== 10} )){
          if(newData.every((x)=> x === 2) && (newData.length > 1 )){
            setWarning("This user is already a staff member, please let them know to contact the front office.")
            setColor1("#ffcc00")
            setShowModal(true)
            // alert("This user is already a staff member, please let them know to contact the front office")
         }
         else{
            
            history.push({
              pathname: '/venuePayment',
              state: selectedUser
            })
         }

        }
        else{
          history.push({
            pathname: '/venuePayment',
            state: selectedUser
          })
        }
       }
       else{
        setWarning("This user is already registered with the other firm.")
            setColor1("#ffcc00")
            setShowModal(true)
        // alert("This user is already registered with the other firm")
       }
       
     
      //console.log("newData11",newData)
     const found = newData.find(element => element);
    
      
      
    
   })
     .catch(err => {
       //console.log("err", err.response);
       // Toaster.error(err.response.data.messages[0])
     }).finally(fin => {
       // konsole.log("finish");
       // setLoader(false);
     })

 }

  const handleAttendeeSelect = (index) => {

    const selectedUserObj = counterPlannerList.attendees[index];
    getUserRoles(selectedUserObj?.attendeeUserId,selectedUserObj)

    // const ClassroomStatus = false
    // commonLib.setDatatoSession('calssroom',JSON.stringify(ClassroomStatus))
    
    // setLoader(true);
    // konsole.log("selectedUserList", selectedUserObj);
    // const seminarid=counterPlannerList.seminarId
    // konsole.log('seminarId',seminarid)
    // const selectedUser = {
    //   attendeeFullName: selectedUserObj.fName + " " + selectedUserObj.lName,
    //   seminarAttendeeUserId: selectedUserObj.attendeeUserId,
    //   attendeeEmailAddress: selectedUserObj.emailId,
    //   attendeeMobileNo: selectedUserObj.mobileno,
    //   seminarAttendeeId: selectedUserObj.seminarAttendeeId,
    //   seminarid:seminarid
    // }
    // konsole.log("selectedUserselectedUser",selectedUser)
    // commonLib.setSessionStoarge("attendeeObj",JSON.stringify(selectedUser));
    // konsole.log("fame",selectedUser)
    // setLoader(false);
    // history.push({
    //   pathname: '/venuePayment',
    //   state: selectedUser
    // })

  }

  const callReferenceValueApi1 = (IdData) =>{
      
    konsole.log("IdDataIdDataIdData",referenceMode)
    return referenceMode.filter((x)=>x.value == IdData)
  }
  const GetEmailTemplateUsers = (TempalteId,isActive,unique,user)=>{
    
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
      promises.then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
      console.log("restapi11111dd",data)

      setTempData(res.data?.data)
       console.log("dummy",data2)
       if(unique == 3){
       setuniqueNum(unique)
        setopenSendEmailModal(true)
        const replacedStr = data.
        replace('@@SEMINARNAME',seminarObj?.seminarTopic)
        setpreviewTemplateData(data)
       }
       else{
        const replacedStr2 = data.
        replace('@@SEMINARNAME',seminarObj?.seminarTopic)
        setpreviewTemplateData(replacedStr2)
        // setuniqueNum(unique)
        // setopenSendEmailModal(true)
       }
      
    })
  
    .catch(err => {
      console.log("err", err.response);
      
    }).finally(fin => {
      // konsole.log("finish");
      // setLoader(false);
    })

  }
const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
  //  alert("hhh")
    const promises = Services.getTextTemplateFunc(TempalteId,isActive);

    console.log("restR11111", TempalteId,isActive);
    promises.then(res => {
        console.log("ihkjgiu",TempalteId)
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
      console.log("restapi1112",data2)
      setTextData(data2)
       console.log("dummy",data2)
       const replacedStr1 = data.
      replace('@@SEMINARNAME',seminarObj?.seminarTopic)
    

    // setpreviewTemplateData(replacedStr1)
    setuniqueNum(unique)
    setopenSendEmailModal(true)
    
      console.log("newData",replacedStr1);
      
    })
  
    .catch(err => {
      console.log("err", err.response);
      
    }).finally(fin => {
      // konsole.log("finish");
      // setLoader(false);
    })

  }


  const getCounterPlannerList = (checkIn) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(checkIn, subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      konsole.log("planners",res)
      setLoader(false);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      konsole.log("semniarFilter", counterResFilter);
      setCounterPlannerList(counterResFilter[0])
    }).catch((err) => {
      konsole.log(`error %% ${err}`)
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const handelSend1 =(occurrenceId, isActive, subtenantId,unique)=>{
       
    const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
    // alert("hyyjyfhfhgfhg")
    console.log("rest11", occurrenceId, isActive, subtenantId)
    promise.then((res) => {
        setLoader(false)
        let textId = res.data.data[0]?.applicableTextTempId;

        console.log("sssssss",res.data.data)
        let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
        if(applicableEmailTempId !== null && applicableEmailTempId !== undefined && applicableEmailTempId !== ""){
            GetEmailTemplateUsers(applicableEmailTempId,true,unique)
        }
        
    if(textId !== null && textId !== undefined && textId !== ""){
      //  alert("hy")
        setactiveText(true)
        setTextTtpeeId(textId)
            GetTextTemplateUsers1(textId,true,unique)
    }
    if(unique == 1 ){
      PlannerClassRoomLinks(seminarObj?.seminarId,"CLASSROOM",unique)
     }
      if(unique == 2){
        PlannerClassRoomLinks(seminarObj?.seminarId,"PLANNER",unique)
      }
      if(textId !== null && textId !== undefined && textId !== "" && 
      applicableEmailTempId !== null && applicableEmailTempId 
      !== undefined && applicableEmailTempId !== "" ){
        setTextOrEmail(1)
        // alert("yes")
      }
      if(textId !== null && textId !== undefined && textId !== "" && 
      applicableEmailTempId === null || applicableEmailTempId 
      === undefined || applicableEmailTempId === "" 
      ){
        setTextOrEmail(2)
        // alert("2")
      }
      if(textId === null || textId === undefined || textId === "" &&
      applicableEmailTempId !== null && applicableEmailTempId 
      !== undefined && applicableEmailTempId !== ""
      ){
        setTextOrEmail(3)
        // alert("5")
      }
     
  
  
  }).catch((err) => {
  console.log(`errorw `, err.response)

  if(err.response.status == 404){
    
    let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
    
    handelSend1(occurrenceId, isActive, subtenantId1,unique) 
    
}
}).finally(error => {
  console.log("finish");
//   setLoader(false);
})

}
  const callReferenceValueApi = (leadFormData,IdData) =>{
    konsole.log("IdDataIdDataIdData",referenceMode )
    konsole.log("IdDataIdDataIdData", IdData)
    return (leadFormData !== undefined && leadFormData !== null && leadFormData !== "") ?leadFormData.filter((x)=>x?.value == IdData) : "noData"
  }

  return (
    <LayoutComponent>
            <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>
      <div className="container-fluid bgclr">
        <div className="pt-4 d-flex justify-content-between w-100 mobileLocate">
          <h4>
            <b>Custom Planning Booking - Counter</b>
          </h4>
          {/* <div className="text-start text-md-end">
            <b>
              {" "}
              <span className="fs-5">
                Venue:
              </span>
            </b>{" "}
            {venueObj.venueAddressLine1}{" "}
            <b>
            </b>{" "}
            | {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}
          </div> */}
           <div className='p-0'>
              {/* <h5>Venue: {Location?.seminarVenue}</h5>
               */}
               <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>

              {/* <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >Location: {venueObj?.venueAddressLine1}</p> */}
              {
                venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
              <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                  {/* Location: {venueObj?.venueAddressLine1} */}
                  </p>
              }
              <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
               {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
            </div>
        </div>

        <div className="Main-container-counter bg-white">

          <div className="row px-3 pb-3">
           
            <h6 className="counter-text mt-3 mobileText1">{seminarObj?.seminarTopic} (Check Ins : {UserNo}/{counterPlannerList?.seatingCapacity})</h6>
            
            {/* <div className="col">
              <nav className="navbar">
                <form className="container-fluid">
                  <div className="input-group">

                    <input
                      type="search"
                      className="form-control "
                      placeholder="Search"
                      onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                    />

                    <span className="input-group-text" id="basic-addon1">
                      <i className="bi bi-search"></i>
                      <img src="./images/vector.png" />

                    </span>
                  </div>
               
                </form>
              </nav>
            </div> */}
            <div className="col-sm-12  col-md-5 col-lg-5   counter-text mt-auto mb-auto">
               <SearchBar setquery={setquery}/>
              {/* <div className="input-group w-75">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setquery((e.target.value)?.toLowerCase())}
                />
                <span className="input-group-text" id="basic-addon1">
                  <img src="./images/vector.png" />
                </span>
              </div> */}
            </div>
            <div className="col">


            </div>
            <div className="col-sm-4 d-flex justify-content-end mt-auto mb-auto">
            <button className='reg d-flex justify-content-center align-items-center rounded text-white text-center mobile' type='button' id='btnRegister'
                style={{height:"46px",border:"none",fontSize:"14px"}}
                onClick={()=>handelSend1(3, true, subtenantId,2)}
               
              >
                Send Custom Planning Mail
              </button>
            
                
              </div>
              {exclee.length>0?<ConvertToExcel data={exclee} headings={ headings}pName="Custom Planning.xlsx" />:""}
          </div>
         
          {/* table */}
          <div className="table-contaier mt-4 ">
            <table className="table bg-white  gap-3 ;">
              
              <thead style={{position : "sticky",top:-1,zIndex:'5'}}>
                <tr className="bgclr gap-3 text-danger fs-6">
                {headings.map((ele)=>{
                      return (
                        // <th className="text-center  ">{ele}</th>
                        <th className = {ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>


                      )
                    })}
                  {/* <th className="text-center">S. No</th>
                  <th className="text-center">First name</th>
                  <th className="text-center">Last name</th>
                  <th className="text-center">Email ID</th>
                  <th className="text-center">Phone</th>
                  <th className="text-center">Attendees</th>
                  <th className="text-center">Lead From</th>
                  <th className="text-center">Seat No.</th>
                  <th className="text-center">Check-In Status</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  counterPlannerList.attendees !== undefined && counterPlannerList?.attendees.length > 0 && counterPlannerList?.attendees?.filter(item => item.fName?.toLowerCase().includes(query) ||
                    item.lName?.toLowerCase()?.includes(query) ||
                    item.emailId?.toLowerCase()?.includes(query) ||
                    item.mobileno?.toLowerCase()?.includes(query)).map((item, index) => {

                      return (
                        <tr key={index} className="cursor-pointer" onClick={() => handleAttendeeSelect(index)}>
                          <td className="">{index + 1}</td>
                          <td className="">{item.fName}</td>
                          <td className="">{item.lName}</td>
                          <td className="">{item.emailId.split(',')[0]}</td>
                          <td className="">
                          {item.mobileno.slice(0,-10) }
                              { " "}
                               {commonLib.formatPhoneNumber(item.mobileno.slice(-10))}
                          </td>
                          <td className="text-center">{item.userGuestsNos}</td>
                          <td className="text-center">{callReferenceValueApi1(item.leadFrom)[0]?.label}</td>
                          <td className="text-center">{item.seatNos}</td>
                          <td className="text-center">{(item.semUserStatusId == 4 || item.semUserStatusId == 5) ? 'Yes' : 'No'}</td>
                        </tr>)
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        openSendEmailModal == true ? <PreviewMail cancelMOdal={handelClose} openSendEmailModal={openSendEmailModal}
         titleText="Send Email" previewTemplateData={previewTemplateData} 
         uniqueNum={uniqueNum} newUser={newUser}tempData={tempData}activeText={activeText}textTtpeeId={textTtpeeId} 
         textData={textData} textOrEmail={textOrEmail}/>:"" }
    </LayoutComponent>
  );
};

export default CounterPlanner;
